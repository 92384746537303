/**
 * Displays a data or a group item in a masonry/fitting style corresponding to the width.
 */

import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';

import GroupItem from './GroupItem';
import DataItem from './DataItem';

const EXPAND_ICON_WIDTH = 24;

const DataGroupItem = (props) => {
  const {
    dataGroup,
    depth = 0,
    elements,
    expandedGroups,
    filterAttributeIds,
    getId,
    hiddenAttributeTypeIds,
    isInitiallyExpandend = false,
    setExpandedGroups,
    width = 0,
  } = props;
  const { values: attributeIds = [], isGroup = false } = dataGroup;
  const filteredAttributeIds = filterAttributeIds ? filterAttributeIds(attributeIds) : attributeIds;

  const Children = filteredAttributeIds.map((attributeId) => {
    const field = elements.find((element) => getId(element) === attributeId);
    const isHidden = hiddenAttributeTypeIds?.includes(Number(attributeId));

    if (isHidden) return null;

    return field ? (
      <DataItem attributeId={attributeId} field={field} isHidden={isHidden} key={attributeId} width={width} />
    ) : (
      <DataGroupItem
        dataGroup={attributeId}
        depth={depth + 1}
        elements={elements}
        expandedGroups={expandedGroups}
        getId={getId}
        hiddenAttributeTypeIds={hiddenAttributeTypeIds}
        key={attributeId.name}
        setExpandedGroups={setExpandedGroups}
        width={width - EXPAND_ICON_WIDTH}
      />
    );
  });

  return isGroup ? (
    <GroupItem
      dataGroup={dataGroup}
      depth={depth}
      expandedGroups={expandedGroups}
      isInitiallyExpandend={isInitiallyExpandend}
      setExpandedGroups={setExpandedGroups}
      width={width}
    >
      {Children}
    </GroupItem>
  ) : (
    <Grid container item spacing={2}>
      {Children}
    </Grid>
  );
};

DataGroupItem.propTypes = {
  dataGroup: PropTypes.object,
  depth: PropTypes.number,
  elements: PropTypes.array,
  expandedGroups: PropTypes.object,
  filterAttributeIds: PropTypes.func,
  getId: PropTypes.func,
  hiddenAttributeTypeIds: PropTypes.array,
  isInitiallyExpandend: PropTypes.bool,
  setExpandedGroups: PropTypes.func.isRequired,
  width: PropTypes.number,
};

export default DataGroupItem;
