import { RELEVANT_DISPATCH_PATH } from '@graphql/consts';

/**
 * Filters all patches except for relevant dispatches which are not closed yet.
 * @param {object} doc
 * @param {Map} idMap
 * @returns {Array}
 */
const getRemainingJsonPatch = (doc, idMap) => {
  let patches = doc?.jsonPatch ? [...doc.jsonPatch] : [];
  patches = patches
    .filter((patch) => patch.path === RELEVANT_DISPATCH_PATH)
    .map((patch) => ({
      ...patch,
      value: patch.value
        .filter(({ closed }) => !closed)
        .map((relevantDispatch) => ({
          ...relevantDispatch,
          blockId: idMap.has(relevantDispatch.blockId) ? idMap.get(relevantDispatch.blockId) : relevantDispatch.blockId,
        })),
    }));
  if (patches.length > 0 && patches[0].value.length > 0) {
    return patches;
  }
  return null;
};

export default getRemainingJsonPatch;
