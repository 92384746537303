import gql from 'graphql-tag';

import FragmentEntityReference from './FragmentEntityReference';
import FragmentProcessingStatus from './FragmentProcessingStatus';

const FragmentChecklistItem = gql`
  ${FragmentEntityReference}
  ${FragmentProcessingStatus}

  fragment ChecklistItem on ChecklistItem {
    assignment {
      ...EntityReference
      entityType {
        id
        className
      }
      ...ProcessingStatus
    }
    assignmentTypeCatId
    checked
    id
    name
    seqNumber
  }
`;

export default FragmentChecklistItem;
