import dayjs from 'dayjs';
import { i18n } from '@geomagic/i18n';
import { DATETIME_DISPLAY_FORMAT } from '@consts';

const getPrimaryText = ({ geolocationPositions: positions = [] }) => {
  let formattedStart;
  const timestamp = positions[0]?.timestamp;

  if (timestamp) {
    formattedStart = dayjs(timestamp).format(DATETIME_DISPLAY_FORMAT);
  }

  return i18n.t('locationRecording.label.record', { variables: { date: formattedStart || '' } });
};

export default getPrimaryText;
