import gql from 'graphql-tag';

import FragmentProcessInstance from '../fragments/FragmentProcessInstance';

const MutationDelegate = gql`
  ${FragmentProcessInstance}

  mutation MutationDelegate(
    $taskId: ID!
    $user: EntityReferenceInput
    $message: String
    $withHistory: Boolean = false
  ) {
    delegate(taskId: $taskId, user: $user, message: $message) {
      ...ProcessInstance
    }
  }
`;

export default MutationDelegate;
