import getDefaultSchema from '@database/getDefaultSchema';

const getDispatchSchema = params => {
  const defaultSchema = getDefaultSchema(params);
  return {
    ...defaultSchema,
    version: 4,
    properties: {
      ...defaultSchema.properties,
      isNearby: {
        default: false,
        type: 'boolean',
      },
      isWorkbench: {
        default: false,
        type: 'boolean',
      },
    },
  };
};

export default getDispatchSchema;
