import gql from 'graphql-tag';
import FragmentFormElementBlock from './FragmentFormElementBlock';
import FragmentFormFeatureCollections from './FragmentFormFeatureCollections';

const FragmentForms = gql`
  ${FragmentFormElementBlock}
  ${FragmentFormFeatureCollections}

  fragment Forms on Entity {
    forms {
      children {
        ...FormElementBlock
      }
      closedBy
      closedOn
      description
      funclocTypeId
      id
      name
      options
      seqNumber
      usedAsTemplate
      ...FormFeatureCollections
    }
  }
`;

export default FragmentForms;
