/**
 * Get the first task of an array of processInstances if there is any.
 * @param {Array} processInstances
 * @returns {object}
 */
const getCurrentTask = (processInstances = []) => {
  const hasProcessInstances = processInstances.length > 0;

  if (hasProcessInstances) {
    const processInstance = processInstances[0];
    const hasTasks = processInstance.tasks.length > 0;
    if (hasTasks) {
      return processInstance.tasks[0];
    }
  }

  return null;
};

export default getCurrentTask;
