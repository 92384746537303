import { fromPromise } from '@apollo/client';

let isRefreshing = false;
let pendingRequests = [];

const setIsRefreshing = value => {
  isRefreshing = value;
};

const addPendingRequest = pendingRequest => {
  pendingRequests.push(pendingRequest);
};

const resolvePendingRequests = () => {
  pendingRequests.map(callback => callback());
  pendingRequests = [];
};

const reAuthenticateUser = ({ promise, operation, forward, onCatchError }) => {
  if (!isRefreshing) {
    setIsRefreshing(true);

    return fromPromise(
      promise().catch(() => {
        resolvePendingRequests();
        setIsRefreshing(false);
        onCatchError && onCatchError();

        return forward(operation);
      })
    ).flatMap(() => {
      resolvePendingRequests();
      setIsRefreshing(false);

      return forward(operation);
    });
  } else {
    return fromPromise(
      new Promise(resolve => {
        addPendingRequest(() => resolve());
      })
    ).flatMap(() => {
      return forward(operation);
    });
  }
};

export default reAuthenticateUser;
