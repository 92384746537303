import gql from 'graphql-tag';

const FragmentAttributeValues = gql`
  fragment AttributeValues on Entity {
    attributeValues {
      attributeType {
        id
        className
      }
      enumValue {
        id
        className
      }
      value
    }
  }
`;

export default FragmentAttributeValues;
