import gql from 'graphql-tag';

import FragmentProcessInstance from '../fragments/FragmentProcessInstance';

const MutationDelegateTeam = gql`
  ${FragmentProcessInstance}

  mutation MutationDelegateTeam($taskId: ID!, $team: EntityReferenceInput, $withHistory: Boolean = false) {
    delegateTeam(taskId: $taskId, team: $team) {
      ...ProcessInstance
    }
  }
`;

export default MutationDelegateTeam;
