import gql from 'graphql-tag';

const FragmentFormElementLocRecording = gql`
  fragment FormElementLocRecording on FormElementLocRecording {
    description
    displayedIfDefect
    id
    locationRecords {
      id
      geolocationPositions {
        coords {
          latitude
          longitude
          altitude
          altitudeAccuracy
          speed
          heading
          accuracy
        }
        timestamp
      }
    }
    mandatory
    name
    options
    seqNumber
    typename
  }
`;

export default FragmentFormElementLocRecording;
