import gql from 'graphql-tag';

import FragmentEntityReference from '../fragments/FragmentEntityReference';
import FragmentFeatureCollections from '../fragments/FragmentFeatureCollections';
import FragmentProcessInstances from '../fragments/FragmentProcessInstances';

const MutationRemoveGeometry = gql`
  ${FragmentEntityReference}
  ${FragmentFeatureCollections}
  ${FragmentProcessInstances}

  mutation MutationRemoveGeometry(
    $entityReference: EntityReferenceInput!
    $featureId: ID!
    $withHistory: Boolean = false
    $srid: Int
    $worldId: NID
  ) {
    removeGeometry(entityReference: $entityReference, featureId: $featureId) {
      ...EntityReference
      ...FeatureCollections
      ...ProcessInstances # query here to get updates for e.g. Task disabled if no geometry
    }
  }
`;

export default MutationRemoveGeometry;
