import gql from 'graphql-tag';

import FragmentAttributeTypeDependency from './FragmentAttributeTypeDependency';

const FragmentAttributeType = gql`
  ${FragmentAttributeTypeDependency}

  fragment AttributeType on AttributeType {
    allowedValues {
      id
      className
      code
      dependentValue
      name
      value
    }
    id
    className
    code
    dataType
    defaultValue
    dependency {
      ...AttributeTypeDependency
    }
    grouping
    mandatory
    name
    options
    preselected
    readOnly
    searchable
    sortable
    unit
    unsupportedEntityTypeIds
  }
`;

export default FragmentAttributeType;
