import React from 'react';
import PropTypes from 'prop-types';
import FormElementEnumToggle from './FormElementEnumToggle';

const FormElementEnum = props => {
  return <FormElementEnumToggle {...props} />;
};

FormElementEnum.propTypes = {
  context: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  doc: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
};

export default FormElementEnum;
