import React from 'react';
import PropTypes from 'prop-types';
import ImageIcon from '@mui/icons-material/CropOriginal';
import Carousel from '@components/Carousel';
import Box from '@mui/material/Box';

const ImageCarousel = (props) => {
  const { activeStep, setActiveStep, images, onClose } = props;
  return (
    <Carousel activeStep={activeStep} setActiveStep={setActiveStep} withPadding={false}>
      {images.map((image, index) => {
        const { id, url, name } = image;

        return (
          <Box
            key={`${id}_${index}`}
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              display: 'flex',
              height: '100%',
              justifyContent: 'center',
              width: '100%',
            }}
            onClick={onClose}
          >
            {url ? (
              <img style={{ maxWidth: '100%' }} src={url} alt={name} />
            ) : (
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  height: '100%',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <ImageIcon color="action" fontSize="large" />
              </Box>
            )}
          </Box>
        );
      })}
    </Carousel>
  );
};

ImageCarousel.propTypes = {
  activeStep: PropTypes.number,
  images: PropTypes.array,
  onClose: PropTypes.func,
  setActiveStep: PropTypes.func,
};

export default ImageCarousel;
