import gql from 'graphql-tag';

import FragmentUser from './FragmentUser';

const FragmentTeam = gql`
  ${FragmentUser}

  fragment Team on Team {
    id
    name
    description
    members {
      ...User
    }
    orgUnits {
      id
      name
    }
  }
`;

export default FragmentTeam;
