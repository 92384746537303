import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@geomagic/core';

const useStyles = makeStyles()(({ palette, spacing }) => ({
  root: {
    borderBottom: `1px solid ${palette.divider}`,
    boxShadow: 'none',
    overflow: 'hidden',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: '0',
    },
    '&$$disabled': {
      background: 'green',
    },
  },
  summary: {
    backgroundColor: palette.background.default,
    borderBottom: `1px solid ${palette.divider}`,
    minHeight: 56,
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  title: {},
  subtitle: {
    marginTop: spacing(0.25),
    color: palette.text.secondary,
  },
  content: {
    margin: `0px !important`,
    alignItems: 'center',
    overflow: 'hidden',
  },
  detail: {
    display: 'flex',
    flexDirection: 'column',
  },
  leftContent: {},
  rightContent: {
    marginLeft: 'auto',
  },
  expanded: {},
}));

const AccordionItem = React.forwardRef((props, ref) => {
  const {
    children,
    className,
    detailProps,
    disabled,
    expanded,
    id,
    onSelect,
    panelAction,
    panelIcon,
    panelProps,
    subtitle,
    summaryProps,
    title,
  } = props;

  const { classes } = useStyles(props);
  const { onChange, ...otherPanelProps } = panelProps;

  /**
   *  EVENT HANDLER
   */

  const handleChange = (event, isExpanded) => {
    event.stopPropagation();
    onSelect && onSelect(event, isExpanded, id, ref);
  };

  const IconButtonProps = { onClick: (event) => handleChange(event, !expanded) };

  const extendedSummaryProps = {
    classes: { root: classes.summary, content: classes.content },
    ...(onChange && { IconButtonProps }),
    ...summaryProps,
  };

  const summary = (
    <Fragment>
      {panelIcon && <div className={classes.leftContent}>{panelIcon}</div>}
      <div className={classes.header}>
        {typeof title === 'string' ? (
          <Typography className={classes.title} variant="body2" component="div" noWrap>
            {title}
          </Typography>
        ) : (
          title
        )}
        <Typography className={classNames(classes.subtitle)} variant="body2" component="div" noWrap>
          {subtitle}
        </Typography>
      </div>
      {panelAction && <div className={classes.rightContent}>{panelAction}</div>}
    </Fragment>
  );

  return (
    <Accordion
      ref={ref}
      classes={{ root: classNames(className, classes.root), expanded: classes.expanded }}
      disabled={disabled}
      expanded={expanded}
      onChange={onChange || handleChange}
      square
      {...otherPanelProps}
    >
      <AccordionSummary {...extendedSummaryProps}>{summary}</AccordionSummary>
      {expanded && (
        <AccordionDetails className={classes.detail} {...detailProps}>
          {children}
        </AccordionDetails>
      )}
    </Accordion>
  );
});

AccordionItem.defaultProps = {
  panelProps: {},
};

AccordionItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  detailProps: PropTypes.object,
  disabled: PropTypes.bool,
  expanded: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onSelect: PropTypes.func,
  panelAction: PropTypes.element,
  panelIcon: PropTypes.node,
  panelProps: PropTypes.object,
  subtitle: PropTypes.node,
  summaryProps: PropTypes.object,
  title: PropTypes.node,
};

export default AccordionItem;
