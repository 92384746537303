import each from 'lodash/each';

/**
 * Replace a template string with params
 * @param {string} template - Template string for a specific OS navigation (http://maps.google.com/maps?saddr=&daddr=${latlon})
 * @param {object} params
 * @param {string} params.latlon - String from latitude and longitude
 * @param {number} params.lat - latitude
 * @param {number} params.lon - longitude
 * @param {string} params.name
 * @returns {string}
 */

const buildUrl = (template, params) => {
  let url = template;

  each(params, (value, param) => {
    url = url.replace('${' + param + '}', value);
  });

  return url;
};

export default buildUrl;
