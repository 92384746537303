import gql from 'graphql-tag';

const MutationAddComment = gql`
  mutation MutationAddComment($sourceEntity: EntityReferenceInput!, $message: String!) {
    addComment(sourceEntity: $sourceEntity, message: $message) {
      id
    }
  }
`;

export default MutationAddComment;
