const getDefaultSchema = params => ({
  ...params,
  title: 'GeoNAM offline entity schema',
  version: 3,
  primaryKey: 'uuid',
  type: 'object',
  properties: {
    createdOn: {
      type: 'number',
    },
    draft: {
      type: ['object', 'null'],
    },
    entity: {
      type: 'object',
    },
    modifiedOn: {
      type: 'number',
    },
    jsonPatch: {
      type: ['array', 'null'],
    },
    offlineMap: {
      type: ['object', 'null'],
    },
    relations: {
      type: ['array', 'null'],
    },
    remoteVersion: {
      type: 'number',
    },
    userId: {
      type: ['number', 'null'],
    },
    uuid: {
      type: 'string',
    },
  },
  attachments: {},
  indexes: ['createdOn', 'modifiedOn'],
});

export default getDefaultSchema;
