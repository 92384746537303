const getConflicts = conflicts => {
  const groups = [];

  if (conflicts) {
    const keys = Object.keys(conflicts);

    keys.forEach(key => {
      const item = conflicts[key];

      item?.conflicts?.length > 0 && groups.push({ id: key, items: item?.conflicts });
    });
  }

  return groups;
};

export default getConflicts;
