import gql from 'graphql-tag';

import FragmentAttributeValues from './FragmentAttributeValues';

const FragmentDocument = gql`
  ${FragmentAttributeValues}

  fragment Document on Entity {
    ...AttributeValues
    id
    className
    displayName
    entityType {
      id
      className
    }
    properties
  }
`;

export default FragmentDocument;
