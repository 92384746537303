/**
 * Get sorted features (id and coordinates) by id
 * @param {Array.<object>} features - Array of OpenLayers features
 * @returns {Array.<object>}
 */

const getSortedFeatures = (features) => {
  const sortedFeatures = features
    .map((feature) => ({
      id: feature.getId(),
      coordinates: feature.getGeometry().getCoordinates(),
    }))
    .sort((a, b) => a.id.localeCompare(b.id));

  return sortedFeatures;
};

export default getSortedFeatures;
