import gql from 'graphql-tag';

const FragmentFeatureCollections = gql`
  fragment FeatureCollections on Entity {
    featureCollections(srid: $srid, worldId: $worldId) {
      bbox
      features {
        geometry {
          coordinates
          type
        }
        geometryStyleId
        geometryTypeId
        id
        srid
        type
      }
      srid
      type
      worldId
    }
  }
`;

export default FragmentFeatureCollections;
