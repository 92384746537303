/**
 * A component to display a menu in the bottom left of the ref.
 */
import React, { forwardRef } from 'react';
import Menu from '@mui/material/Menu';
import { makeStyles } from '@geomagic/core';
import ListboxComponent from './ListBoxComponent';

const useStyles = makeStyles()(() => ({
  list: {
    paddingRight: '0px !important',
    width: '100% !important',
  },
  paper: {
    border: '1px solid #d3d4d5',
    height: 'auto',
  },
}));

const InlineMenu = forwardRef((props, ref) => {
  const { classes } = useStyles(props);

  return (
    <Menu
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom',
      }}
      classes={{ paper: classes.paper }}
      elevation={0}
      getContentAnchorEl={null}
      MenuListProps={{
        className: classes.list,
      }}
      PaperProps={{
        component: ListboxComponent,
      }}
      ref={ref}
      transformOrigin={{
        horizontal: 'left',
        vertical: 'top',
      }}
      {...props}
    />
  );
});

export default InlineMenu;
