import { getTypedAttributeValue } from '../../../utils';

export const EQUALS_OPERATOR = 'EQUALS';
export const NOT_EQUALS_OPERATOR = 'NOT_EQUALS';
export const INCLUDES_OPERATOR = 'INCLUDES';
export const INCLUDES_NOT_OPERATOR = 'INCLUDES_NOT';
export const GREATER_OPERATOR = 'GREATER';
export const GREATER_OR_EQUAL_OPERATOR = 'GREATER_OR_EQUAL';
export const LESS_OPERATOR = 'LESS';
export const LESS_OR_EQUAL_OPERATOR = 'LESS_OR_EQUAL';
export const EMPTY_OPERATOR = 'EMPTY';
export const NOT_EMPTY_OPERATOR = 'NOT_EMPTY';
export const STARTS_WITH_OPERATOR = 'STARTS_WITH';
export const ENDS_WITH_OPERATOR = 'ENDS_WITH';
export const PROCESS_DISCRIMINATOR = 'process';

const getStringComparision = (depValue, value, operator) => {
  switch (operator) {
    case EQUALS_OPERATOR:
      return value === depValue;
    case NOT_EQUALS_OPERATOR:
      return value !== depValue;
    case EMPTY_OPERATOR:
      return value === undefined;
    case NOT_EMPTY_OPERATOR:
      return value !== undefined;
    case INCLUDES_OPERATOR:
      return value?.includes(depValue);
    case INCLUDES_NOT_OPERATOR:
      return !value?.includes(depValue);
    case STARTS_WITH_OPERATOR:
      return value?.startsWith(depValue);
    case ENDS_WITH_OPERATOR:
      return value?.endsWith(depValue);
    default:
      return false;
  }
};

const getNumberComparision = (depValue, value, operator) => {
  switch (operator) {
    case EQUALS_OPERATOR:
      return value === depValue;
    case NOT_EQUALS_OPERATOR:
      return value !== depValue;
    case EMPTY_OPERATOR:
      return !value;
    case NOT_EMPTY_OPERATOR:
      return !!value;
    case LESS_OR_EQUAL_OPERATOR:
      return value <= depValue;
    case GREATER_OR_EQUAL_OPERATOR:
      return value >= depValue;
    case LESS_OPERATOR:
      return value < depValue;
    case GREATER_OPERATOR:
      return value > depValue;
    default:
      return false;
  }
};

const getDateComparision = (depValue, value, operator) => {
  switch (operator) {
    case EQUALS_OPERATOR:
      return value === depValue;
    case NOT_EQUALS_OPERATOR:
      return value !== depValue;
    case EMPTY_OPERATOR:
      return !value;
    case NOT_EMPTY_OPERATOR:
      return !!value;
    case LESS_OR_EQUAL_OPERATOR:
      return value <= depValue;
    case GREATER_OR_EQUAL_OPERATOR:
      return value >= depValue;
    default:
      return false;
  }
};

const getBooleanComparision = (depValue, value, operator) => {
  switch (operator) {
    case EQUALS_OPERATOR:
      return value === depValue;
    case EMPTY_OPERATOR:
      return value === null;
    case NOT_EMPTY_OPERATOR:
      return value !== null;
    default:
      return false;
  }
};

const getEnumComparision = (enumValueIds, value, allowedValues, operator) => {
  const valueId = allowedValues.find((allowedValue) => allowedValue.value === value)?.id;
  switch (operator) {
    case EQUALS_OPERATOR:
      return enumValueIds.includes(valueId);
    case NOT_EQUALS_OPERATOR:
      return !enumValueIds.includes(valueId);
    case INCLUDES_OPERATOR:
      return enumValueIds.includes(valueId);
    case INCLUDES_NOT_OPERATOR:
      return !enumValueIds.includes(valueId);
    case EMPTY_OPERATOR:
      return value === undefined;
    case NOT_EMPTY_OPERATOR:
      return value !== undefined;
    default:
      return false;
  }
};

/**
 *
 *
 * @param {*} depValue
 * @param {*} attributeValue
 * EQUALS
 * NOT_EQUALS
 * EMPTY
 * NOT_EMPTY
 * # strings
 * INCLUDES
 * INCLUDES_NOT
 * STARTS_WITH
 * ENDS_WITH
 * # dates and numbers
 * LESS_OR_EQUAL      # <=
 * GREATER_OR_EQUAL   # >=
 * LESS               # <
 * GREATER            # >
 * @param {*} operator
 * @param {*} dataType
 * @returns
 */

const isDependencyMet = (dependency, attributeTypes, attributeValues) => {
  const { attributeTypeId: depAttributeTypeId, enumValueIds, operator, value: depValue } = dependency;

  const depAttributeType = attributeTypes.find((type) => type.id === depAttributeTypeId);

  const { allowedValues, dataType } = depAttributeType;

  const attributeValue = attributeValues.find(
    ({ attributeType: attributeValueAttributeType, attributeTypeId }) =>
      attributeValueAttributeType?.id === depAttributeTypeId || attributeTypeId === depAttributeTypeId
  );

  const typedDepValue = getTypedAttributeValue(depAttributeType, depValue);
  const { value } = attributeValue || {};
  const typedValue = getTypedAttributeValue(depAttributeType, value);

  const isDepEnum = allowedValues.length > 0;

  if (isDepEnum) {
    return getEnumComparision(enumValueIds, value, allowedValues, operator);
  }

  switch (dataType) {
    case 'STRING':
    case 'LINK':
    case 'EMAIL':
      return getStringComparision(depValue, value, operator);
    case 'FRACTION':
    case 'INT':
    case 'FLOAT':
    case 'CURRENCY':
      return getNumberComparision(typedDepValue, typedValue, operator);
    case 'BOOLEAN':
      return getBooleanComparision(typedDepValue, typedValue, operator);
    case 'DATE':
    case 'TIMESTAMP':
      return getDateComparision(typedDepValue, typedValue, operator);
    default:
      return false;
  }
};

export default isDependencyMet;
