/**
 * Check if type is markdown
 * @param {object} options - Options
 * @returns {boolean}
 */

const MARKDOWN_IDENTIFIER = 'MARKDOWN';

const isMarkdownDescriptionType = data => {
  const { descriptionType } = data?.options || {};

  return descriptionType === MARKDOWN_IDENTIFIER;
};

export default isMarkdownDescriptionType;
