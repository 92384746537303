/**
 * Get the value of a fraction representation
 *@param {string} fraction
 * @returns {number}
 */

const getFloatFromFraction = (fraction) => {
  if (!fraction) {
    return undefined;
  }
  if (fraction.includes('/')) {
    const [a, b] = fraction.split('/');
    if (!a) {
      return 0;
    }
    if (!b) {
      return Number(a);
    }
    return Number(a) / Number(b);
  } else {
    return Number(fraction);
  }
};

export default getFloatFromFraction;
