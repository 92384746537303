import gql from 'graphql-tag';

const MutationConnectDispatchFormElementBlock = gql`
  mutation MutationConnectDispatchFormElementBlock(
    $dispatchReference: EntityReferenceInput!
    $formElementBlockId: NID!
  ) {
    connectDispatchFormElementBlock(dispatchReference: $dispatchReference, formElementBlockId: $formElementBlockId)
  }
`;

export default MutationConnectDispatchFormElementBlock;
