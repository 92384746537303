import { CLASSNAME_ASSIGNMENT } from '@graphql/consts';
import getEntityFilter from '@graphql/getEntityFilter';
import QueryAssignmentsPrefetch from '@graphql/queries/QueryAssignmentsPrefetch';

const getQueryConfigPrefetch = () => {
  return {
    query: QueryAssignmentsPrefetch,
    variables: {
      filter: getEntityFilter([CLASSNAME_ASSIGNMENT], true, true),
    },
  };
};

export default getQueryConfigPrefetch;
