import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import getIdByPathname from './getIdByPathname';
import DesktopNavigation from './DesktopNavigation';
import MobileNavigation from './MobileNavigation';

const Navigation = (props) => {
  const { className, items, state } = props;
  const { basePath, isMobile, routeProps } = state;
  const pathname = routeProps?.location?.pathname;

  const [activeId, setActiveId] = useState('');

  useEffect(() => {
    const id = getIdByPathname({ basePath, items, pathname });
    setActiveId(id);
  }, [basePath, items, pathname]);

  /**
   *  EVENT HANDLER
   */

  const handleChange = (newItemId) => {
    setActiveId(newItemId);
  };

  const navigationProps = {
    activeId,
    basePath,
    items,
    onChange: handleChange,
  };

  return (
    <div className={className}>
      {isMobile ? <MobileNavigation {...navigationProps} /> : <DesktopNavigation {...navigationProps} />}
    </div>
  );
};

Navigation.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      Component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
      Icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
      id: PropTypes.string.isRequired,
      path: PropTypes.string,
    })
  ).isRequired,
  state: PropTypes.object,
};

export default Navigation;
