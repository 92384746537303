import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@geomagic/core';
import { getEntityType } from '@geomagic/geonam';
import DynamicAvatar from '@components/DynamicAvatar';

const DEFAULT_SIZE = 35;

const getCharByDescription = (description, limit = 2) => {
  return description && description.length > limit ? description.substring(0, limit).toUpperCase() : description;
};

const useStyles = makeStyles()(({ palette, typography }) => {
  return {
    root: {
      display: 'flex',
    },
    icon: {
      color: palette.mode === 'light' ? palette.primary.main : 'inherit',
      height: 'inherit',
      width: 'inherit',
    },
    iconContainer: {
      alignItems: 'center',
      color: palette.mode === 'light' ? palette.primary.main : 'inherit',
      display: 'flex',
      height: 'inherit',
      justifyContent: 'center',
      position: 'absolute',
      width: 'inherit',
    },
    title: {
      fontSize: ({ size }) => typography.pxToRem((size || DEFAULT_SIZE) / 3),
    },
    small: {},
    large: {},
  };
});

const EntityAvatar = (props) => {
  const {
    className,
    ComponentProps = { color: 'primary', fontSize: 'large' },
    entity,
    entityClassName,
    entityClasses,
    size = DEFAULT_SIZE,
  } = props;
  const defaultClassName = entityClassName || entity?.className;
  const entityTypeId = entity?.entityType?.id;

  const entityType =
    defaultClassName && entityTypeId ? getEntityType(entityClasses, defaultClassName, entityTypeId) : {};
  const description = entityType?.shortName;

  const { classes } = useStyles(props);

  return (
    <Fade in timeout={500}>
      <div className={classNames(classes.root, className)} style={{ height: size, width: size }}>
        <DynamicAvatar
          className={classes.icon}
          imp={import(/* webpackMode: "eager" */ `@geomagic/nam-react-core/icons/${defaultClassName}`)}
          componentProps={ComponentProps}
        />
        <div className={classes.iconContainer}>
          <Typography className={classes.title} variant="caption">
            {getCharByDescription(description)}
          </Typography>
        </div>
      </div>
    </Fade>
  );
};

EntityAvatar.propTypes = {
  className: PropTypes.string,
  ComponentProps: PropTypes.object,
  entity: PropTypes.object,
  entityClassName: PropTypes.string,
  entityClasses: PropTypes.array,
  size: PropTypes.number,
};

export default EntityAvatar;
