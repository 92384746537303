import { createContext } from 'react';

const AppBarContext = createContext({
  actions: null,
  title: '',
  setActions: () => {},
  setTitle: () => {},
});

export default AppBarContext;
