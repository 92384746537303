import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { persistCache } from 'apollo-cache-persist';
import localforage from 'localforage';
import { getDefaultNetworkLink } from '@geomagic/geonam';

import { DEFAULT_ENVIRONMENT } from './consts';
import getDataIdFromObject from './getDataIdFromObject';

const cache = new InMemoryCache({ dataIdFromObject: getDataIdFromObject });
const networkLink = getDefaultNetworkLink(DEFAULT_ENVIRONMENT).link;
const offlineGraphQLStore = localforage.createInstance({ name: 'offlineGraphQLStore' });

const initialize = async errorLink => {
  const link = ApolloLink.from([errorLink, networkLink]);
  const persistCacheOptions = {
    cache,
    storage: offlineGraphQLStore,
    maxSize: false,
  };

  await persistCache(persistCacheOptions);
  console.log('OFFLINE GRAPHQL CACHE: persisted cache');

  return new ApolloClient({
    link,
    cache,
  });
};

export default initialize;
