import gql from 'graphql-tag';

import FragmentProcessInstance from '../fragments/FragmentProcessInstance';

const MutationClaimUnassignedTask = gql`
  ${FragmentProcessInstance}

  mutation MutationClaimUnassignedTask($taskId: ID!, $withHistory: Boolean = false) {
    claimUnassignedTask(taskId: $taskId) {
      ...ProcessInstance
    }
  }
`;

export default MutationClaimUnassignedTask;
