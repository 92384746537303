import { MAP_SRID } from '../consts';

/**
 * Get a srid from epsg code
 * @param {string|undefined} epsgCode
 * @returns {string}
 */

const getSridFromEPSG = epsgCode => {
  let srid;

  if (epsgCode) {
    const [, suffix] = epsgCode.split(':');

    if (suffix) {
      srid = Number(suffix);
    }
  }

  return srid || MAP_SRID;
};

export default getSridFromEPSG;
