const VARIANTS = {
  expanded: {
    height: 'auto',
    transition: {
      when: 'beforeChildren',
    },
  },
  collapsed: {
    height: 0,
    transition: {
      when: 'afterChildren',
    },
  },
};

const getGroupAnimation = (isGroupOpen) => ({
  variants: VARIANTS,
  initial: !isGroupOpen ? 'collapsed' : 'expanded',
  animate: !isGroupOpen ? 'collapsed' : 'expanded',
});

export default getGroupAnimation;
