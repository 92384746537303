import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getPointResolution } from 'ol/proj';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

const SCALE_WIDTH = 100;
const SCALE_HEIGHT = 28;

const getScale = (projection, resolution, center) => {
  let unit = 'm';
  let w = 0;
  if (projection && resolution && center) {
    w = getPointResolution(projection, resolution, center) * SCALE_WIDTH;
    if (w > 1000) {
      w /= 1000;
      unit = 'km';
    }
    return { width: w.toFixed(1), unit };
  }
};

const Scale = (props) => {
  const { controlProps, map } = props;
  const [scale, setScale] = useState(null);
  const view = map.getView();

  const handleChangeScale = useCallback(() => {
    const projection = view.getProjection();
    const resolution = view.getResolution();
    const center = view.getCenter();
    setScale(getScale(projection, resolution, center));
  }, [view]);

  useEffect(() => {
    handleChangeScale();
    map.on('moveend', handleChangeScale);
    return () => {
      map.un('moveend', handleChangeScale);
    };
  }, [handleChangeScale, map]);

  return (
    <Paper
      elevation={0}
      square
      sx={{ display: 'flex', pointerEvents: 'auto', backgroundColor: 'transparent' }}
      {...controlProps}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: SCALE_WIDTH,
          height: SCALE_HEIGHT,
          border: 1,
          borderColor: 'common.white',
          textAlign: 'center',
          color: 'common.white',
          textShadow: '0px 0px 5px black,0px 0px 3px black,0px 0px 1px black',
          borderTop: 'none',
          backgroundColor: 'rgba(255,255,255,0.25)',
          '&:before': {
            position: 'absolute; display: block; content: ""',
            border: 1,
            borderColor: 'common.black',
            borderTop: 'none',
            width: SCALE_WIDTH,
            height: SCALE_HEIGHT,
            boxSizing: 'border-box; -moz-box-sizing: border-box; -webkit-box-sizing: border-box',
          },
        }}
      >
        <Typography variant="caption" sx={{ color: 'common.white' }}>
          {scale && scale.width} {scale && scale.unit}
        </Typography>
      </Box>
    </Paper>
  );
};

Scale.propTypes = {
  controlProps: PropTypes.object,
  map: PropTypes.object.isRequired,
};

export default Scale;
