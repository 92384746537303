/**
 * A component to display a text with a max width.
 *
 * A tooltip is displayed if the text overflows.
 */

import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

const TooltipText = (props) => {
  const { content = '', typographyProps = {}, width } = props;

  const typographyRef = useRef();
  const [isOverflowed, setIsOverflow] = useState(false);

  /**
   *  EFFECTS
   */

  useEffect(() => {
    if (typographyRef.current) {
      setIsOverflow(typographyRef.current.scrollWidth > typographyRef.current.clientWidth);
    }
  }, []);

  return (
    <Box sx={{ ...(width && { maxWidth: width }) }}>
      <Tooltip disableHoverListener={!isOverflowed} title={content}>
        <Typography ref={typographyRef} noWrap variant="inherit" sx={{ display: 'block' }} {...typographyProps}>
          {content}
        </Typography>
      </Tooltip>
    </Box>
  );
};

TooltipText.propTypes = {
  content: PropTypes.string,
  typographyProps: PropTypes.object,
  width: PropTypes.number,
};

export default TooltipText;
