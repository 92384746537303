import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { DelayedTooltip } from '@geomagic/geonam';
import getEntityStateByProcessingStatus from '../utils/getEntityStateByProcessingStatus';

const EntityState = (props) => {
  const theme = useTheme();

  const { entity, size, variant } = props;
  const { criticalStatusMessage, processingStatus = [] } = entity;

  /**
   * COMPONENTS
   */

  const getBadgeComponent = () => {
    return processingStatus.map((status) => {
      const { style, title } = getEntityStateByProcessingStatus({
        criticalStatusMessage,
        status,
        theme,
      });

      return (
        <Box
          key={status}
          style={style}
          sx={{
            borderRadius: 1,
            display: 'flex',
            margin: '0 0.25',
            whiteSpace: 'nowrap',
            padding: 0.5,
            ...((size === 'small' || size === 'extraSmall') && { padding: 0.25 }),
            ...(size === 'large' && { padding: 1 }),
            ...(style && { border: 2, borderColor: 'background.paper' }),
          }}
        >
          <Typography
            sx={{
              fontSize: '13px',
              fontWeight: 'bold',
              padding: '0 0.5',
              textTransform: 'uppercase',
              ...((size === 'small' || size === 'extraSmall') && { fontSize: '12px', padding: '0 0.25' }),
              ...(size === 'large' && { fontSize: '14px', padding: '0 1' }),
            }}
          >
            {title}
          </Typography>
        </Box>
      );
    });
  };

  const getCircleComponent = () => {
    const { style, title } = getEntityStateByProcessingStatus({
      criticalStatusMessage,
      status: processingStatus?.[0],
      theme,
    });

    const sx = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      width: 26,
      height: 26,
      ...(size === 'extraSmall' && { width: 14, height: 14 }),
      ...(size === 'small' && { width: 18, height: 18 }),
      ...(size === 'large' && { width: 34, height: 34 }),
      ...(style && { border: 2, borderColor: 'background.paper' }),
    };

    return title ? (
      <DelayedTooltip title={title}>
        <Box style={style} sx={sx} />
      </DelayedTooltip>
    ) : (
      <Box style={style} sx={sx} />
    );
  };

  return <Box>{variant === 'badge' ? getBadgeComponent() : getCircleComponent()}</Box>;
};

EntityState.defaultProps = {
  size: 'medium',
  variant: 'badge',
};

EntityState.propTypes = {
  entity: PropTypes.object.isRequired,
  size: PropTypes.oneOf(['extraSmall', 'small', 'medium', 'large']),
  variant: PropTypes.oneOf(['badge', 'circle']),
};

export default EntityState;
