import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@geomagic/core';
import sanitizePath from '@utils/sanitizePath';

const useStyles = makeStyles()(({ palette, zIndex }) => ({
  root: {
    display: 'flex',
    height: '100%',
    backgroundColor: palette.background.paper,
    borderRight: `1px solid ${palette.divider}`,
    padding: 0,
    flexDirection: 'column',
    zIndex: zIndex.drawer,
  },
  listItem: {
    display: 'flex',
    height: 64,
    width: 64,
    padding: 0,
  },
  active: {
    color: palette.primary.main,
  },
  listItemIcon: {
    minWidth: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const DesktopNavigation = (props) => {
  const { activeId, basePath, className, items, onChange } = props;
  const { classes } = useStyles();

  const handleChange = (id) => () => {
    onChange(id);
  };

  return (
    <List className={classNames(classes.root, className)}>
      {items.map(({ id, name, path, Icon: IconComponent }) => {
        return (
          <Tooltip key={id} title={name}>
            <ListItem
              button
              className={classes.listItem}
              onClick={handleChange(id)}
              {...(basePath && path && { component: NavLink, to: sanitizePath(`${basePath}${path}`) })}
            >
              <ListItemIcon className={classNames(classes.listItemIcon, { [classes.active]: id === activeId })}>
                <IconComponent />
              </ListItemIcon>
            </ListItem>
          </Tooltip>
        );
      })}
    </List>
  );
};

DesktopNavigation.propTypes = {
  activeId: PropTypes.string.isRequired,
  basePath: PropTypes.string,
  className: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      Component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
      Icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
      id: PropTypes.string.isRequired,
      path: PropTypes.string,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default DesktopNavigation;
