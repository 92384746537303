import flatten from 'lodash/flatten';
import getFormElementsByKey from './getFormElementsByKey';

const getFormDocuments = (forms = [], keys = ['FormElementDocuments', 'FormElementPictures']) => {
  const formElements = getFormElementsByKey(forms, keys);
  const flattendDocuments = flatten(formElements.map(({ documents }) => documents));

  return flattendDocuments;
};

export default getFormDocuments;
