import gql from 'graphql-tag';

const MutationTokenLogin = gql`
  mutation MutationTokenLogin($token: String!) {
    tokenLogin(token: $token) {
      loginToken
      sessionId
      status
      user {
        loginName
        fullName
        id
      }
    }
  }
`;

export default MutationTokenLogin;
