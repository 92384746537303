import getLineStringStyle from './getLineStringStyle';
import getPointStyle from './getPointStyle';
import getPolygonStyle from './getPolygonStyle';

/**
 * Get an object with styles for geometry types
 * @param {object} params
 * @param {object} params.feature
 * @param {string} params.backgroundColor
 * @param {object} params.overrides
 * @returns {object}
 */

const getFeatureStyle = (params) => {
  const { feature, backgroundColor, overrides = {}, zIndex } = params;
  const type = feature.getGeometry().getType();
  const styleOverrides = { backgroundColor, zIndex, ...overrides[type] };

  if (type === 'Point') {
    return getPointStyle(styleOverrides);
  } else if (type === 'LineString') {
    return getLineStringStyle(styleOverrides);
  } else {
    return getPolygonStyle(styleOverrides);
  }
};

export default getFeatureStyle;
