import gql from 'graphql-tag';

import FragmentEntityReference from '../fragments/FragmentEntityReference';

const MutationUploadDocument = gql`
  ${FragmentEntityReference}

  mutation MutationUploadDocument($metadata: DocumentUploadInput!, $file: Upload!) {
    uploadDocument(metadata: $metadata, file: $file) {
      ...EntityReference
    }
  }
`;

export default MutationUploadDocument;
