import gql from 'graphql-tag';

import FragmentProcessInstance from '../fragments/FragmentProcessInstance';

const MutationExecuteProcessCommand = gql`
  ${FragmentProcessInstance}

  mutation MutationExecuteProcessCommand(
    $taskId: ID!
    $command: String!
    $message: String
    $variables: [ProcessVariableInput!]
    $withHistory: Boolean = false
  ) {
    executeProcessCommand(taskId: $taskId, command: $command, variables: $variables, message: $message) {
      ...ProcessInstance
    }
  }
`;

export default MutationExecuteProcessCommand;
