import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import { PRIMARY_TRIGGER_PROPS } from '@consts';
import { Trigger } from '@geomagic/core';

import SingleSelect from '../../components/SingleSelect';
import { getStringifiedAttributeValue } from '../../utils';
import { Typography } from '@mui/material';

const ENUM_SELECT_MAX_SIZE = 16;

const SingleEnumSelect = (props) => {
  const {
    attributeType,
    enumList = [],
    error,
    margin,
    label,
    isAlternativeEnumSelect = false,
    updateField,
    value,
  } = props;

  const { mandatory, readOnly } = attributeType;

  useEffect(() => {
    if (value) {
      const valueMissingInEnumList = !enumList.some((option) => option === value);
      if (valueMissingInEnumList) {
        updateField(undefined);
      }
    }
  }, [updateField, enumList, value]);

  const getOptionLabel = (item) => getStringifiedAttributeValue(attributeType, item);

  const handleChange = (event, newValue) => {
    updateField(newValue);
  };

  const getOptionSelected = (option, item) => {
    return option === item;
  };

  return isAlternativeEnumSelect && enumList.length <= ENUM_SELECT_MAX_SIZE ? (
    <>
      <FormControl focused fullWidth margin="normal" required={mandatory} size="medium" variant="outlined">
        <FormLabel>{label}</FormLabel>
        <Box
          sx={{
            border: '2px solid',
            borderColor: error ? 'error.main' : 'primary.main',
            borderRadius: 'shape.borderRadius',
            padding: 1,
          }}
        >
          <Grid alignItems="center" container spacing={2}>
            {enumList.map((option) => {
              const optionLabel = getOptionLabel(option);
              const isSelected = getOptionSelected(option, value);
              return (
                <Grid key={'option' + optionLabel} item xs={6} sm={3} md={3} lg={2}>
                  <Trigger
                    {...PRIMARY_TRIGGER_PROPS}
                    color={isSelected ? 'primary' : 'inherit'}
                    fullWidth
                    onClick={(event) => handleChange(event, isSelected ? undefined : option)}
                    sx={{
                      height: 78,
                      padding: 1.5,
                    }}
                  >
                    <Typography
                      sx={{
                        display: '-webkit-box',
                        overflow: 'hidden',
                        overflowWrap: 'break-word',
                        textOverflow: 'clip',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        WebkitHyphens: 'auto',
                        MozHyphens: 'auto',
                        hyphens: 'auto',
                        whiteSpace: 'normal',
                      }}
                      variant="button"
                    >
                      {optionLabel}
                    </Typography>
                  </Trigger>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </FormControl>
    </>
  ) : (
    <SingleSelect
      disableClearable={false}
      disabled={readOnly}
      error={error}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      margin={margin}
      label={label}
      onChange={handleChange}
      options={enumList}
      required={mandatory}
      value={value}
    />
  );
};

SingleEnumSelect.propTypes = {
  attributeType: PropTypes.object.isRequired,
  enumList: PropTypes.array,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  isAlternativeEnumSelect: PropTypes.bool,
  margin: PropTypes.string,
  label: PropTypes.string,
  updateField: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default SingleEnumSelect;
