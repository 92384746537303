import gql from 'graphql-tag';

import FragmentEntityPrefetch from './FragmentEntityPrefetch';

const FragmentDispatchPrefetch = gql`
  ${FragmentEntityPrefetch}

  fragment DispatchPrefetch on Entity {
    ...EntityPrefetch
  }
`;

export default FragmentDispatchPrefetch;
