import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';

import { DEFAULT_SYSTEM_ZINDEX } from '../consts';

/**
 * Add a vectorLayer to map and style features
 * @param {object} map - OpenLayers map
 * @param {object|Function} style - Feature styles
 * @returns {object}
 */

const addVectorLayer = (map, style, zIndex = DEFAULT_SYSTEM_ZINDEX) => {
  const vectorSource = new VectorSource();
  const vectorLayer = new VectorLayer({
    source: vectorSource,
    zIndex,
    ...(style && { style }),
  });

  map.addLayer(vectorLayer);

  return vectorLayer;
};

export default addVectorLayer;
