import loadImage from 'blueimp-load-image';

const getMetaData = file => {
  return new Promise((resolve, reject) => {
    loadImage.parseMetaData(file, data => {
      const exif = data.exif ? data.exif.getAll() : [];
      let orientation = 0,
        location,
        dateTime;

      if (data.exif) {
        orientation = data.exif.get('Orientation');
      }

      if (exif.GPSLatitude && exif.GPSLongitude) {
        location = {
          latitude: exif.GPSLatitude,
          latitudeRef: exif.GPSLatitudeRef,
          longitude: exif.GPSLongitude,
          longitudeRef: exif.GPSLongitudeRef,
        };
      }

      if (exif.DateTimeDigitized) {
        // Convert date to correct JS format
        const date = exif.DateTimeDigitized.split(' ')[0].replace(/:/g, '-');
        const time = exif.DateTimeDigitized.split(' ')[1];
        dateTime = new Date([date, time].join(' '));
      }

      resolve({
        location,
        dateTime,
        orientation,
      });
    });
  });
};

export default getMetaData;
