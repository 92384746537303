import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const DEFAULT_PLACEHOLDER_ICON_SIZE = 100;

const Placeholder = (props) => {
  const { action, icon, iconSize, subtitle, title } = props;

  return (
    <Box sx={{ alignItems: 'center', display: 'flex', flex: 1, justifyContent: 'center' }}>
      <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', maxWidth: 300 }}>
        {React.cloneElement(icon, {
          sx: { fontSize: iconSize || DEFAULT_PLACEHOLDER_ICON_SIZE },
        })}
        <Typography
          color="textPrimary"
          sx={{ marginBottom: 1, textAlign: 'center', whiteSpace: 'break-spaces' }}
          variant="h6"
        >
          {title}
        </Typography>
        <Typography
          color="textSecondary"
          sx={{ marginBottom: 1, textAlign: 'center', whiteSpace: 'break-spaces' }}
          variant="body2"
        >
          {subtitle}
        </Typography>
        <Box sx={{ marginTop: 1 }}>{action}</Box>
      </Box>
    </Box>
  );
};

Placeholder.propTypes = {
  action: PropTypes.node,
  icon: PropTypes.node,
  iconSize: PropTypes.number,
  subtitle: PropTypes.string,
  title: PropTypes.string,
};

export default Placeholder;
