/**
 * Add features to specific layer
 * @param {object} layer - OpenLayers layer
 * @param {Array.<object>} features - Array of OpenLayers features
 * @returns {void}
 */

const addFeaturesToLayer = (layer, features) => {
  const layerSource = layer.getSource();
  layerSource.addFeatures(features);
};

export default addFeaturesToLayer;
