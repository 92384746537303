import getFeatureCollection from '@components/Map/utils/getFeatureCollection';

const getFeatures = (dispatches, entityClasses) => {
  let features = [];

  dispatches.forEach(dispatch => {
    const entity = dispatch.getPatchedEntity();
    const featureCollection = getFeatureCollection(entity, entityClasses);

    if (featureCollection) {
      const newFeatures = featureCollection?.features.map(feature => ({
        ...feature,
        properties: { ...feature.properties, entity },
      }));

      features = [...features, ...newFeatures];
    }
  });

  return features;
};

export default getFeatures;
