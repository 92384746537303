import { i18n } from '@geomagic/i18n';

const handleFetchError = ({ error, isDefaultThrow = true }) => {
  const isFetchFailed = error?.message === 'Failed to fetch';
  const isNetworkError = error?.message === 'NetworkError when attempting to fetch resource';
  if (isFetchFailed || isNetworkError) {
    throw new Error(i18n.t('synchronization.notification.fetchError'));
  } else if (isDefaultThrow) {
    throw new Error(error?.message);
  }
};

export default handleFetchError;
