import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { i18n } from '@geomagic/i18n';

import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';

import { DEFAULT_TEXT_FIELD_PROPS } from '@consts';
import ListBoxComponent from './ListBoxComponent';

const MultipleSelect = (props) => {
  const {
    disableCloseOnSelect = false,
    disabled,
    error,
    fixedOptions = [],
    label = '',
    loading,
    getOptionSelected,
    onChange,
    options = [],
    resetOnSelect = false,
    required = false,
    value,
  } = props;

  const [inputValue, setInputValue] = useState('');

  /**
   *  EVENT HANDLER
   */

  const handleChangeInput = (event, newInputValue, reason) => {
    if (!resetOnSelect && reason === 'reset') return;

    setInputValue(newInputValue);
  };

  /**
   *  COMPONENTS
   */

  const renderInput = (params) => (
    <TextField {...params} error={error} label={label} required={required} {...DEFAULT_TEXT_FIELD_PROPS} />
  );

  const renderOptions = (optionProps, option, { selected }) => (
    <li {...optionProps}>
      <Checkbox checked={selected} color="primary" />
      {option.name}
    </li>
  );

  const renderTags = (tagValue, getTagProps) =>
    tagValue.map((option, index) => (
      <Chip
        disabled={disabled || (fixedOptions.length > 0 && fixedOptions.find((item) => item.id === option?.id))}
        label={option.name}
        key={option.id}
        variant="outlined"
        {...getTagProps({ index })}
      />
    ));

  return (
    <Autocomplete
      clearText={i18n.t('tooltip.clear')}
      closeText={i18n.t('tooltip.close')}
      disableCloseOnSelect={disableCloseOnSelect}
      disabled={disabled}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={getOptionSelected ? getOptionSelected : (option, item) => option.id === item.id}
      inputValue={inputValue}
      ListboxComponent={ListBoxComponent}
      loading={loading}
      loadingText={i18n.t('placeholder.loading')}
      multiple
      noOptionsText={i18n.t('placeholder.noOptions')}
      onChange={onChange}
      onInputChange={handleChangeInput}
      openText={i18n.t('tooltip.open')}
      options={options}
      renderOption={renderOptions}
      renderInput={renderInput}
      renderTags={renderTags}
      size="small"
      value={value}
    />
  );
};

MultipleSelect.propTypes = {
  disableCloseOnSelect: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  fixedOptions: PropTypes.array,
  getOptionSelected: PropTypes.func,
  label: PropTypes.string,
  loading: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array,
  resetOnSelect: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.array.isRequired,
};

export default MultipleSelect;
