import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import get from 'lodash/get';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import { makeStyles } from '@geomagic/core';
import ListItem from '@components/ListItem';
import ListSkeleton from './ListSkeleton';

const useStyles = makeStyles()(({ palette }) => {
  return {
    root: {
      backgroundColor: palette.background.paper,
      flex: 1,
      overflow: 'auto',
      padding: 0,
      position: 'relative',
    },
    li: {
      listStyle: 'none',
    },
    ul: {
      margin: 0,
      padding: 0,
      backgroundColor: palette.background.paper,
    },
  };
});

const GroupedList = (props) => {
  const { activeId, additionalProps, className, groups = [], idKey, loading, PlaceholderComponent } = props;

  const listItemRefs = useRef({});
  const { classes } = useStyles(props);

  /**
   *  COMPONENTS
   */

  const list = groups.map(({ id, label, items = [], onClick, ActionsComponent, AvatarComponent, TextComponent }) => {
    return (
      <li key={id} className={classes.li}>
        <ul className={classes.ul}>
          <ListSubheader>{label}</ListSubheader>
          <Divider />
          {items.map((item) => {
            const itemId = idKey ? get(item, idKey) : item.id;

            return (
              <ListItem
                ActionsComponent={ActionsComponent}
                activeId={activeId}
                additionalProps={additionalProps}
                AvatarComponent={AvatarComponent}
                key={itemId}
                listItemRefs={listItemRefs}
                item={item}
                itemId={itemId}
                onClick={onClick}
                TextComponent={TextComponent}
              />
            );
          })}
          {items.length < 1 && PlaceholderComponent}
        </ul>
      </li>
    );
  });

  return (
    <List className={classNames(classes.root, className)} subheader={<li />}>
      {loading ? <ListSkeleton /> : list}
    </List>
  );
};

GroupedList.propTypes = {
  activeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  additionalProps: PropTypes.object,
  className: PropTypes.string,
  groups: PropTypes.array,
  idKey: PropTypes.string,
  loading: PropTypes.bool,
  PlaceholderComponent: PropTypes.node,
};

export default GroupedList;
