import { WORLD_CODE } from '../consts';

/**
 * Get a world by code
 * @param {Array.<object>} worlds
 * @param {string|undefined} code
 * @returns {object|undefined}
 */

const getWorldByCode = (worlds = [], code) => {
  return worlds.find((world) => world?.code === (code || WORLD_CODE));
};

export default getWorldByCode;
