import { CLASSNAME_DISPATCH } from '@graphql/consts';
import getEntityFilter from '@graphql/getEntityFilter';
import QueryDispatchesPrefetch from '@graphql/queries/QueryDispatchesPrefetch';

const getQueryConfigPrefetch = () => {
  return {
    query: QueryDispatchesPrefetch,
    variables: {
      filter: getEntityFilter([CLASSNAME_DISPATCH], true, true),
    },
  };
};

export default getQueryConfigPrefetch;
