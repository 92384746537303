/**
 * A component to display an action.
 *
 * Currently only one command is allowed per action.
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import FormHelperText from '@mui/material/FormHelperText';

import { Trigger } from '@geomagic/core';
import { i18n } from '@geomagic/i18n';

import { PRIMARY_TRIGGER_PROPS } from '@consts';
import MutationExecuteProcessCommand from '@graphql/mutations/MutationExecuteProcessCommand';
import useLoadingSnackbar from '@utils/useLoadingSnackbar';
import useShowPrompt from '@utils/useShowPrompt';

import ConfirmationTaskDialog from './ConfirmationTaskDialog';

const getFormHelperErrorText = ({
  closeAction,
  closeableErrorText,
  description,
  isCloseable,
  isLoading,
  isOnline,
  isSelf,
}) => {
  if (!isOnline) {
    return i18n.t('process.description.notOnline');
  } else if (!isSelf || isLoading) {
    return '';
  } else if (description) {
    return description;
  } else if (closeAction && !isCloseable) {
    return closeableErrorText;
  } else {
    return '';
  }
};

const Action = (props) => {
  const {
    action,
    checkFailedMessage,
    className,
    closeableErrorText,
    data,
    isCloseable,
    isLoading,
    isMobile,
    isOnline,
    isSelf,
    onCheck,
    setLoading,
    syncEntity,
    task,
    updateEntity,
  } = props;

  const enqueueLoadingSnackbar = useLoadingSnackbar();

  const { enqueueSnackbar } = useSnackbar();
  const showPrompt = useShowPrompt();
  const [isConfirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const { id: taskId, name: taskName } = task;

  const { closeAction, commands, description, disabled, id: actionId } = action;
  const { confirmation, name: commandName, type } = commands[0];
  const label = commandName;
  const isTaskDisabled = !isOnline || isLoading || !isSelf || disabled || (closeAction && !isCloseable);
  const formHelperText = getFormHelperErrorText({
    closeAction,
    closeableErrorText,
    description,
    isCloseable,
    isLoading,
    isOnline,
    isSelf,
  });

  /**
   * GRAPHQL MUTATIONS
   */

  const [executeProcessCommand] = useMutation(MutationExecuteProcessCommand);

  /**
   *  EVENT HANDLER
   */

  const handleExecuteProcessCommand = async (executedAction, message) => {
    setLoading(true);
    const { closeAction: isCloseAction, commands: actionCommands } = executedAction;
    const command = actionCommands[0].command;

    const execute = () =>
      syncEntity()
        .then(() => executeProcessCommand({ variables: { taskId, command, message } }))
        .then(updateEntity);

    await enqueueLoadingSnackbar({
      loadingText: isCloseAction
        ? i18n.t('process.notification.completeTask', { variables: { taskName } })
        : i18n.t('process.notification.executeAction'),
      finishedText: isCloseAction
        ? i18n.t('process.notification.completedTask', {
            variables: { taskName },
          })
        : i18n.t('process.notification.executedAction'),
      finishedVariant: 'success',
      func: execute,
    });
    setLoading(false);
  };

  const handleClickProcessCommand = async (clickedAction) => {
    const isChecked = await onCheck(data);
    const { closeAction: isCloseAction, name: clickedCommandName } = clickedAction;

    if (!isChecked) {
      enqueueSnackbar(checkFailedMessage, {
        key: 'checkFailed',
        preventDuplicate: true,
        variant: 'info',
      });
    } else {
      showPrompt({
        title: clickedCommandName,
        content: isCloseAction
          ? i18n.t('process.dialog.processCompletion.content', {
              variables: {
                taskName,
              },
            })
          : i18n.t('process.dialog.processAction.content'),
        onOk: () => handleExecuteProcessCommand(clickedAction),
      });
    }
  };

  if (type === 'server') {
    if (confirmation) {
      return (
        <>
          <Trigger
            key={actionId}
            className={className}
            fullWidth
            isDisabled={isTaskDisabled}
            onClick={async () => {
              const isChecked = await onCheck(data);

              if (!isChecked) {
                enqueueSnackbar(checkFailedMessage, {
                  key: 'checkFailed',
                  preventDuplicate: true,
                  variant: 'info',
                });
              }
              setConfirmationDialogOpen(true);
            }}
            {...PRIMARY_TRIGGER_PROPS}
          >
            {label}
          </Trigger>
          {formHelperText && <FormHelperText>{formHelperText}</FormHelperText>}
          <ConfirmationTaskDialog
            commandName={commandName}
            isCloseAction={closeAction}
            isLoading={isLoading}
            isMobile={isMobile}
            open={isConfirmationDialogOpen}
            onConfirm={(message) => handleExecuteProcessCommand(action, message)}
            onClose={() => setConfirmationDialogOpen(false)}
            task={task}
          />
        </>
      );
    } else {
      return (
        <>
          <Trigger
            key={actionId}
            className={className}
            disabled={isTaskDisabled}
            fullWidth
            onClick={() => handleClickProcessCommand(action)}
            {...PRIMARY_TRIGGER_PROPS}
          >
            {label}
          </Trigger>
          {formHelperText && <FormHelperText>{formHelperText}</FormHelperText>}
        </>
      );
    }
  } else {
    return null;
  }
};

const actionPropType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string,
  closeAction: PropTypes.bool,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  commands: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      command: PropTypes.string.isRequired,
      confirmation: PropTypes.bool,
    })
  ),
});

Action.propTypes = {
  action: actionPropType,
  checkFailedMessage: PropTypes.string,
  className: PropTypes.string,
  closeableErrorText: PropTypes.string,
  data: PropTypes.object.isRequired,
  isCloseable: PropTypes.bool,
  isLoading: PropTypes.bool,
  isOnline: PropTypes.bool,
  isMobile: PropTypes.bool,
  isSelf: PropTypes.bool,
  onCheck: PropTypes.func,
  setLoading: PropTypes.func,
  syncEntity: PropTypes.func,
  task: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string,
    description: PropTypes.string,
    assignee: PropTypes.string,
    priority: PropTypes.string,
    actions: PropTypes.arrayOf(actionPropType),
  }),
  updateEntity: PropTypes.func,
};

export default Action;
