import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import deepEqual from 'deep-equal';
import Chip from '@mui/material/Chip';
import ListItemText from '@mui/material/ListItemText';
import { makeStyles } from '@geomagic/core';
import { getAttributeTypesByClassAndType, getEntityType, getEntityClass } from '@geomagic/geonam';
import { getAttributeValueByCode, getFormattedAttributeValue } from '@geomagic/nam-react-core/utils';

const useStyles = makeStyles()(({ palette, shape, spacing, typography }) => {
  const isLightTheme = palette.mode === 'light';
  const chipBackground = palette.primary[isLightTheme ? 300 : 500];

  return {
    root: {
      margin: 0,
    },
    relations: {
      marginTop: spacing(0.5),
    },
    chip: {
      background: chipBackground,
      borderRadius: shape.borderRadius,
      color: palette.getContrastText(chipBackground),
      fontSize: typography.pxToRem(11),
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      padding: 0,
      marginRight: 4,
      marginBottom: 4,
      maxWidth: '50%',
      height: 20,
    },
    chipLabel: {
      paddingLeft: 4,
      paddingRight: 4,
    },
  };
});

const getAttributeTypeByCode = (entityClass, code) => {
  const attributeTypes = entityClass?.attributeTypes;
  return attributeTypes?.find((type) => type.code === code);
};

const DISPATCH_CLASSNAME = 'Dispatch';

const DispatchListText = (props) => {
  const { data, dispatchCreationConfig, entityClasses, isDetail } = props;

  const { relations } = data.toJSON();
  const entity = data.getPatchedEntity();
  const { attributeValues, className } = entity;
  const entityClass = getEntityClass(entityClasses, className);
  const entityType = getEntityType(entityClasses, className, entity?.entityType?.id);
  const typeName = entityType?.name;
  const { dispatchAttribTypes: dispatchAttribTypesReferences = [], dispatchTypeId } = dispatchCreationConfig || {};

  const descriptionAttributeType = getAttributeTypeByCode(entityClass, 'property.description');

  const allAttributeTypes = useMemo(
    () => getAttributeTypesByClassAndType(entityClasses, DISPATCH_CLASSNAME, dispatchTypeId),
    [entityClasses, dispatchTypeId]
  );

  const isDraft = data?.draft;

  /**
   * In der Schnellmeldungserfassung könnte man (je nach Konfiguration) auch eine Beschreibung hinterlegen.
   * Damit diese nicht doppelt dargestellt werden (als Primary- und Secondary-Text), wird das hier
   * rausgefiltert.
   */
  const dispatchAttribTypes = useMemo(() => {
    return dispatchAttribTypesReferences
      .map((attribTypeReference) => allAttributeTypes.find((attribType) => attribType.id === attribTypeReference.id))
      .filter((attributeType) => attributeType.id !== descriptionAttributeType.id);
  }, [allAttributeTypes, descriptionAttributeType.id, dispatchAttribTypesReferences]);

  const { classes } = useStyles();

  /**
   *  COMPONENTS
   */

  const getRelationComponent = () => {
    return (
      <div key={entity.id} className={classes.relations}>
        {Object.values(relations).map((item) => (
          <Chip
            key={item.id}
            classes={{ root: classes.chip, label: classes.chipLabel }}
            label={item.name}
            size="small"
          />
        ))}
      </div>
    );
  };

  const getPrimaryText = () => {
    const text = [];

    // Fallback-Reihenfolge des Textes bzw. Labels für Entwürfe:
    // Attribute aus Schnellmeldeerfassung || Anzeigename || Meldungstyp

    // Entwürfe sind nur lokal vorhanden.
    if (isDraft) {
      const matchingAttributeValues = dispatchAttribTypes
        ?.map((attributeType) => {
          const foundAttributeValue = attributeValues?.find(
            (attributeValue) => attributeValue.attributeType.id === attributeType.id
          );

          return getFormattedAttributeValue(attributeType, foundAttributeValue?.value);
        })
        // Filter undefined-Werte
        .filter((f) => f);

      // Hat Attribute wie "Kategorie" oder "Ort" der Schnellmeldungserfassung
      if (matchingAttributeValues?.length > 0) {
        text.push(...matchingAttributeValues);
      } else {
        const displayName = getAttributeValueByCode(entityClasses, entity, 'property.name')?.formattedValue;
        // Bei manchen Meldungstypen kann man Anzeigenamen hinterlegen, falls nicht vorhanden, genügt der Meldungstyp.
        text.push(displayName || typeName);
      }
    } else {
      text.push(entity?.displayName);
    }

    return text;
  };

  const getSecondaryText = () => {
    const text = [];

    // Die Tags sollen nur in der allgemeinen Ansicht der Meldungen angezeigt werden.
    if (!isDetail) {
      text.push(relations ? getRelationComponent() : entity?.shortDescription);
    } else {
      if (isDraft) {
        const description = getAttributeValueByCode(entityClasses, entity, 'property.description')?.formattedValue;
        text.push(description);
      } else {
        text.push(entity?.shortDescription);
      }
    }

    return text;
  };

  return (
    <ListItemText
      className={classes.root}
      key={entity?.id}
      primary={getPrimaryText().join(' – ')}
      primaryTypographyProps={{ noWrap: true, variant: 'body1' }}
      secondary={getSecondaryText()}
      secondaryTypographyProps={{ component: 'div', noWrap: true, variant: 'body2' }}
    />
  );
};

DispatchListText.propTypes = {
  data: PropTypes.object.isRequired,
  dispatchCreationConfig: PropTypes.object,
  entityClasses: PropTypes.array,
  isDetail: PropTypes.bool,
};

export default memo(DispatchListText, (prev, next) => deepEqual(prev, next));
