/**
 * Sanitize Path
 * @param {string} path
 * @returns {string}
 */

const sanitizePath = path => {
  return path.replace(/\/\/+/g, '/');
};

export default sanitizePath;
