import gql from 'graphql-tag';

import FragmentAttributeValues from '../fragments/FragmentAttributeValues';
import FragmentEntityReference from '../fragments/FragmentEntityReference';

const MutationCreateEntity = gql`
  ${FragmentEntityReference}
  ${FragmentAttributeValues}

  mutation MutationCreateEntity($entity: EntityCreateInput!) {
    createEntity(entity: $entity) {
      ...EntityReference
      ...AttributeValues
    }
  }
`;

export default MutationCreateEntity;
