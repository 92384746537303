import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import pointer from 'json-pointer';
import Box from '@mui/material/Box';
import UploadLocIcon from '@mui/icons-material/AddLocation';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { makeStyles } from '@geomagic/core';
import LocationRecordUpload from '@components/LocationRecordUpload';
import LocationRecordList from '@components/LocationRecordList';
import getPatch from '@database/getPatch';
import { DEFAULT_LOCATION_ACCEPT_FORMATS } from '@consts';

import { FORM_COMPONENT_MIN_HEIGHT } from '../consts';

const useStyles = makeStyles()(({ palette, shape, spacing }) => ({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  button: {
    background: palette.primary.light,
    color: palette.getContrastText(palette.primary.main),
    '&$isSelected': {
      background: `${palette.primary.main} !important`,
    },
  },
  buttonUpload: {
    border: 'none !important',
    borderBottomRightRadius: `${shape.borderRadius}px !important`,
    borderTopRightRadius: `${shape.borderRadius}px !important`,
    '@media (hover: none)': {
      background: `${palette.primary.light} !important`,
      color: `${palette.getContrastText(palette.primary.main)} !important`,
    },
  },
  componentContainer: {
    display: 'flex',
    margin: spacing(1, 0.5),
    minHeight: FORM_COMPONENT_MIN_HEIGHT,
  },
  isMandatory: {
    border: `2px solid ${palette.warning.main}`,
    borderRadius: 4,
  },
  isSelected: {},
  listContainer: {
    border: `1px solid ${palette.divider}`,
    display: 'flex',
    marginTop: spacing(2),
  },
}));

const getFormElementValue = (doc, path) => {
  let value;

  if (doc && path) {
    const item = pointer.get(doc.getPatchedEntity(), path);
    value = item;
  }

  return value;
};

const FormElementLocRecording = (props) => {
  const { classes } = useStyles(props);

  const { context, data, doc, path } = props;
  const { entityClasses, isReadOnly, mapProps, onUpdate, setVisibleLocationRecordList, visibleLocationRecordList } =
    context;
  const { mandatory, name, id, locationRecords = [] } = data;

  const isListVisible = visibleLocationRecordList === id;
  const label = name.concat(!!locationRecords.length ? ` (${locationRecords.length})` : '');

  const isValid = locationRecords.length > 0;

  /**
   *  EVENT HANDLER
   */

  const handleClickListVisibility = () => {
    setVisibleLocationRecordList((prev) => (!!prev ? (prev === id ? null : id) : id));
  };

  const handleDelete = (locationRecordsToDelete) => {
    onUpdate({ locationRecords: locationRecordsToDelete }, path, data);
  };

  const handleUpload = async (newLocationRecord) => {
    const jsonPatch = doc?.jsonPatch;
    const value = getFormElementValue(doc, path);
    const newLocationRecords = [...value?.locationRecords];
    newLocationRecords.push(newLocationRecord);
    const newPatch = {
      op: 'replace',
      path,
      value: { ...value, locationRecords: newLocationRecords, typename: 'FormElementLocRecording' },
    };

    await doc.atomicUpdate((oldData) => {
      oldData.jsonPatch = getPatch(jsonPatch, newPatch);
      return oldData;
    });
  };

  return (
    <div className={classes.root}>
      <Box className={classes.componentContainer}>
        <ButtonGroup
          className={classNames({
            [classes.isMandatory]: mandatory && !isValid,
          })}
          color="primary"
          disableElevation
          size="medium"
          variant="contained"
        >
          <Button
            className={classNames(classes.button, { [classes.isSelected]: isListVisible })}
            disabled={!isValid}
            onClick={handleClickListVisibility}
          >
            {label}
          </Button>
          {!isReadOnly && (
            <LocationRecordUpload
              accept={DEFAULT_LOCATION_ACCEPT_FORMATS}
              className={classNames(classes.button, classes.buttonUpload)}
              entityClasses={entityClasses}
              icon={<UploadLocIcon />}
              onUpload={handleUpload}
            />
          )}
        </ButtonGroup>
      </Box>
      {isListVisible && (
        <Box className={classes.listContainer}>
          <LocationRecordList mapProps={mapProps} onDelete={handleDelete} records={locationRecords} />
        </Box>
      )}
    </div>
  );
};

FormElementLocRecording.propTypes = {
  context: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  doc: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
};

export default FormElementLocRecording;
