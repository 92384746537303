import isDependencyMet from './isDependencyMet';
import { HIDDEN_IF_UNMET } from './consts';

const filterAttributeType = (
  attributeType,
  { attributeTypes, attributeValues, entityTypeId },
  checkDependency = false
) => {
  const { dependency, unsupportedEntityTypeIds = [] } = attributeType;

  if (unsupportedEntityTypeIds.includes(entityTypeId)) return false;

  if (!checkDependency || !dependency || dependency?.visibility !== HIDDEN_IF_UNMET) {
    return true;
  }

  return isDependencyMet(dependency, attributeTypes, attributeValues);
};

export default filterAttributeType;
