import gql from 'graphql-tag';

const FragmentProcessHistory = gql`
  fragment ProcessHistory on ProcessInstance {
    history {
      type
      date
      user
      action
      message
    }
  }
`;

export default FragmentProcessHistory;
