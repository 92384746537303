import gql from 'graphql-tag';

import FragmentDispatch from '../fragments/FragmentDispatch';
import FragmentEntity from '../fragments/FragmentEntity';
import FragmentForms from '../fragments/FragmentForms';
import FragmentProcessInstances from '../fragments/FragmentProcessInstances';

const QueryAssignment = gql`
  ${FragmentDispatch}
  ${FragmentEntity}
  ${FragmentForms}
  ${FragmentProcessInstances}

  query QueryFindEntity(
    $entityReference: EntityReferenceInput!
    $srid: Int
    $withHistory: Boolean = false
    $worldId: NID
  ) {
    entity: findEntity(entityReference: $entityReference) {
      ...Entity
      ...Forms
      ...ProcessInstances
      relevantDispatches {
        ...Dispatch
      }
    }
  }
`;

export default QueryAssignment;
