import gql from 'graphql-tag';

import FragmentChecklistItem from '../fragments/FragmentCheckListItem';

const MutationUpdateChecklistItem = gql`
  ${FragmentChecklistItem}

  mutation MutationUpdateChecklistItem($item: UpdateChecklistItemInput!) {
    updateChecklistItem(item: $item) {
      ...ChecklistItem
    }
  }
`;

export default MutationUpdateChecklistItem;
