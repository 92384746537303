import gql from 'graphql-tag';

const FragmentProcessingStatus = gql`
  fragment ProcessingStatus on Entity {
    processingStatus
    criticalStatusMessage
  }
`;

export default FragmentProcessingStatus;
