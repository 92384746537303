/**
 * Displays collapsible group item .
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import getGroupAnimation from './utils/getGroupAnimation';
import GroupHeader from './GroupHeader';

const getLength = (values) => values.reduce((prev, cur) => (Array.isArray(cur) ? prev + cur.length : prev + 1), 0);

const setIntitialState = (field, expandedGroups, setExpandedGroups, isInitiallyExpandend) => {
  const { values = [], isGroup = false, key } = field;
  if (isGroup) {
    const isGroupOpen = isInitiallyExpandend || !!expandedGroups[key];
    setExpandedGroups((previousExpandedGroups) => ({ ...previousExpandedGroups, [key]: isGroupOpen }));
    values.forEach((childField) => {
      setIntitialState(childField, expandedGroups, setExpandedGroups);
    });
  }
};

const GroupItem = (props) => {
  const {
    children,
    dataGroup,
    depth = 0,
    expandedGroups,
    filterAttributeIds,
    isInitiallyExpandend = false,
    setExpandedGroups,
  } = props;
  const { values: attributeIds = [], name, key } = dataGroup;
  const filteredAttributeIds = filterAttributeIds ? filterAttributeIds(attributeIds) : attributeIds;
  const title = `${name} (${getLength(filteredAttributeIds)})`;

  const isGroupOpen = expandedGroups[key];

  /**
   *  EFFECTS
   */

  useEffect(() => {
    if (expandedGroups && setExpandedGroups) {
      setIntitialState(dataGroup, expandedGroups, setExpandedGroups, isInitiallyExpandend);
    }
    //Only on monunt groups writes itself in expanded groups object
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid item xs={12}>
      <GroupHeader
        dataGroup={dataGroup}
        depth={depth}
        expandedGroups={expandedGroups}
        title={title}
        setExpandedGroups={setExpandedGroups}
      />
      {isGroupOpen && (
        <Box
          component={motion.div}
          sx={{
            borderLeft: '1px dashed',
            borderColor: 'action.active',
            marginLeft: 1.5,
            width: ({ spacing }) => `calc(100% - ${spacing(1.5)})`,
            ...(depth > 0
              ? {
                  marginLeft: 2,
                  width: ({ spacing }) => `calc(100% - ${spacing(2)})`,
                }
              : {}),
          }}
          {...getGroupAnimation(isGroupOpen)}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              marginLeft: 2,
              width: ({ spacing }) => `calc(100% - ${spacing(2)})`,
            }}
          >
            <Grid container spacing={2}>
              {children}
            </Grid>
          </Box>
        </Box>
      )}
    </Grid>
  );
};

GroupItem.propTypes = {
  children: PropTypes.array.isRequired,
  dataGroup: PropTypes.object,
  depth: PropTypes.number,
  expandedGroups: PropTypes.object,
  filterAttributeIds: PropTypes.func,
  isInitiallyExpandend: PropTypes.bool,
  setExpandedGroups: PropTypes.func.isRequired,
};

export default GroupItem;
