import gql from 'graphql-tag';

import FragmentEntityReference from '../fragments/FragmentEntityReference';
import FragmentFeatureCollections from '../fragments/FragmentFeatureCollections';
import FragmentProcessInstances from '../fragments/FragmentProcessInstances';

const MutationAddGeometries = gql`
  ${FragmentEntityReference}
  ${FragmentFeatureCollections}
  ${FragmentProcessInstances}

  mutation MutationAddGeometries(
    $entityReference: EntityReferenceInput!
    $geometries: [GeometryInput!]!
    $withHistory: Boolean = false
    $srid: Int
    $worldId: NID
  ) {
    addGeometries(entityReference: $entityReference, geometries: $geometries) {
      ...EntityReference
      ...FeatureCollections
      ...ProcessInstances # query here to get updates for e.g. Task disabled if no geometry
    }
  }
`;

export default MutationAddGeometries;
