import React, { memo } from 'react';
import PropTypes from 'prop-types';
import deepEqual from 'deep-equal';
import RoomIcon from '@mui/icons-material/Room';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { Trigger } from '@geomagic/core';
import { getEntityType } from '@geomagic/geonam';
import { i18n } from '@geomagic/i18n';
import { DEFAULT_TRIGGER_ICON_PROPS } from '@consts';
import getFeatures from './getFeatures';

const DispatchListActions = (props) => {
  // Einige Props via "additionalContextProps" in Dispatchlist.js übergeben
  const { areMapActionsDisabled, data, entityClasses, onCloseSwipeableArea, onRemoveDraft, onShowOnMap } = props;

  const entity = data?.getPatchedEntity();

  const features = !areMapActionsDisabled && getFeatures([data], entityClasses);
  const isDraft = !!data?.draft;

  const entityType = getEntityType(entityClasses, entity?.className, entity?.entityType?.id);
  const hasFeatures = features?.length > 0;
  const label = data?.draft ? entityType?.name : entity?.displayName;

  /**
   *  EVENT HANDLER
   */

  const handleRemoveDraft = (event) => {
    event.stopPropagation();
    onRemoveDraft && onRemoveDraft(label, data);
  };

  const handleShowOnMap = (event) => {
    event.stopPropagation();
    onCloseSwipeableArea && onCloseSwipeableArea();
    onShowOnMap && onShowOnMap(features, entity.id);
  };

  /**
   *  ACTION MENU ITEMS
   */

  return (
    <Box sx={{ display: 'flex', gap: 1 }}>
      {hasFeatures && (
        <Tooltip title={i18n.t('tooltip.showOnMap')}>
          <Trigger {...DEFAULT_TRIGGER_ICON_PROPS} icon={<RoomIcon />} onClick={handleShowOnMap} />
        </Tooltip>
      )}
      {isDraft && (
        <Tooltip title={i18n.t('tooltip.delete')}>
          <Trigger {...DEFAULT_TRIGGER_ICON_PROPS} icon={<DeleteIcon />} onClick={handleRemoveDraft} />
        </Tooltip>
      )}
    </Box>
  );
};

DispatchListActions.propTypes = {
  areMapActionsDisabled: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  entityClasses: PropTypes.array.isRequired,
  onCloseSwipeableArea: PropTypes.func,
  onRemoveDraft: PropTypes.func,
  onShowOnMap: PropTypes.func,
};

export default memo(DispatchListActions, (prev, next) => deepEqual(prev, next));
