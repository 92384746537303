import gql from 'graphql-tag';

const MutationLogin = gql`
  mutation MutationLogin($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      loginToken
      sessionId
      status
      user {
        loginName
        fullName
        id
      }
    }
  }
`;

export default MutationLogin;
