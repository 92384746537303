const overrides = {
  MuiButton: {
    defaultProps: {
      size: 'small',
    },
  },
  MuiButtonGroup: {
    defaultProps: {
      size: 'small',
    },
  },
  MuiChip: {
    defaultProps: {
      size: 'small',
    },
  },
  MuiFab: {
    defaultProps: {
      size: 'small',
    },
  },
  MuiFilledInput: {
    defaultProps: {
      margin: 'dense',
    },
  },
  MuiFormControl: {
    defaultProps: {
      margin: 'dense',
    },
  },
  MuiFormHelperText: {
    defaultProps: {
      margin: 'dense',
    },
  },
  MuiIcon: {
    defaultProps: {
      fontSize: 'small',
    },
  },
  MuiIconButton: {
    defaultProps: {
      size: 'small',
    },
  },
  MuiInput: {
    defaultProps: {
      margin: 'dense',
    },
  },
  MuiInputBase: {
    defaultProps: {
      margin: 'dense',
    },
  },
  MuiInputLabel: {
    defaultProps: {
      margin: 'dense',
    },
  },
  MuiList: {
    defaultProps: {
      dense: true,
    },
  },
  MuiListItem: {
    defaultProps: {
      dense: true,
    },
  },
  MuiMenuItem: {
    defaultProps: {
      dense: true,
    },
  },
  MuiOutlinedInput: {
    defaultProps: {
      margin: 'dense',
    },
  },
  MuiRating: {
    defaultProps: {
      size: 'small',
    },
  },
  MuiSvgIcon: {
    defaultProps: {
      fontSize: 'small',
    },
  },
  MuiSwitch: {
    defaultProps: {
      size: 'small',
    },
  },
  MuiTable: {
    defaultProps: {
      size: 'small',
    },
  },
  MuiTextField: {
    defaultProps: {
      margin: 'dense',
    },
  },
  MuiToggleButtonGroup: {
    defaultProps: {
      size: 'small',
    },
  },
  MuiToggleButton: {
    defaultProps: {
      size: 'small',
    },
    styleOverrides: {
      root: ({ theme }) => ({
        '&.Mui-selected': {
          color: theme.palette.primary.contrastText,
          backgroundColor: theme.palette.primary.light,
        },
        '&.Mui-selected:hover': {
          color: theme.palette.primary.contrastText,
          backgroundColor: theme.palette.primary.light,
        },
      }),
    },
  },
};

export default overrides;
