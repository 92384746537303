import { useEffect } from 'react';
import { useMutation } from '@apollo/client';

import MutationTokenLogin from '@graphql/mutations/MutationTokenLogin';

const timeout = (ms, promise) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      reject(new Error('timeout'));
    }, ms);
    promise.then(resolve, reject);
  });
};

const CALLBACK_URL_PLACEHOLDER = '$callbackUrl';

const useSSO = ({
  callbackUrl,
  location,
  path,
  portalUrl,
  tokenParamName,
  onLogin,
  redirectPath,
  redirectParamName = 'redirectPath',
  urlCheckErrorPath,
}) => {
  const currentPathname = location.pathname;
  const searchParams = location.search;
  const isExecutable = currentPathname === path;

  /**
   * MUTATIONS
   */

  const [loginWithToken] = useMutation(MutationTokenLogin);

  /**
   * EFFECTS
   */

  useEffect(() => {
    if (isExecutable && portalUrl && searchParams.length === 0) {
      const isProduction = process.env.NODE_ENV === 'production';
      const redirectParam = redirectParamName ? `&${redirectParamName}=${window.btoa(redirectPath)}` : '';
      const url = `${portalUrl.replace(CALLBACK_URL_PLACEHOLDER, encodeURIComponent(callbackUrl))}${redirectParam}`;
      if (!!urlCheckErrorPath) {
        const checkTimeout = 10000;

        timeout(checkTimeout, fetch(isProduction ? portalUrl : '/devPortal', { mode: 'no-cors' }))
          .then(response => {
            if (response.status === 0 || response.status === 200) {
              window.location.replace(url);
            } else {
              window.location.replace(urlCheckErrorPath);
            }
          })
          .catch(error => {
            window.location.replace(urlCheckErrorPath);
          });
      } else {
        window.location.replace(url);
      }
    }
  }, [isExecutable, portalUrl, callbackUrl, redirectParamName, redirectPath, searchParams, urlCheckErrorPath]);

  useEffect(() => {
    if (isExecutable && searchParams.length > 0 && tokenParamName) {
      const parsedParams = new URLSearchParams(searchParams);
      const token = parsedParams.get(tokenParamName);

      if (token) {
        loginWithToken({ variables: { token } }).then(({ data }) => {
          onLogin && onLogin(data?.tokenLogin, undefined);
        });
      }
    }
  }, [isExecutable, loginWithToken, onLogin, searchParams, tokenParamName]);
};

export default useSSO;
