import dayjs from 'dayjs';
import 'dayjs/locale/de';
import utc from 'dayjs/plugin/utc';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';

/**
 * Get dayjs locale by navigator language
 * @returns {*}
 */

export const initDayJS = () => {
  const locale = window.navigator.userLanguage || window.navigator.language;
  locale && dayjs.locale(`${locale}`);
  dayjs.extend(localeData);
  dayjs.extend(localizedFormat);
  dayjs.extend(utc);
};

export default initDayJS;
