import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const EXPAND_ICON_WIDTH = 24;

const GroupHeader = (props) => {
  const { dataGroup, depth = 0, expandedGroups, setExpandedGroups, title } = props;
  const { isError = false, key } = dataGroup;

  const isGroupOpen = expandedGroups[key];

  /* EVENT HANDLER */

  const handleClickGroup = (event) => {
    event.stopPropagation();
    setExpandedGroups &&
      setExpandedGroups((previousExpandedGroups) => ({
        ...previousExpandedGroups,
        [key]: !previousExpandedGroups[key],
      }));
  };

  return (
    <ListItem disableGutters>
      <ListItemButton disableGutters onClick={handleClickGroup}>
        <ListItemIcon
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            minWidth: EXPAND_ICON_WIDTH,
            ...(depth > 0
              ? {
                  marginLeft: 0.5,
                }
              : {}),
          }}
        >
          {isGroupOpen ? <ExpandMoreIcon /> : <KeyboardArrowRightIcon />}
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography variant="subtitle2" noWrap>
              {title}
            </Typography>
          }
          sx={{
            color: isError ? 'error.main' : 'primary.main',
            fontWeight: 'bold',
          }}
        />
      </ListItemButton>
    </ListItem>
  );
};

GroupHeader.propTypes = {
  dataGroup: PropTypes.object,
  depth: PropTypes.number,
  expandedGroups: PropTypes.object,
  setExpandedGroups: PropTypes.func.isRequired,
  title: PropTypes.string,
};

export default GroupHeader;
