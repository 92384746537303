import React, { memo } from 'react';
import PropTypes from 'prop-types';
import deepEqual from 'deep-equal';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import CheckIcon from '@mui/icons-material/Check';
import EntityAvatar from '@geomagic/nam-react-core/components/EntityAvatar';
import EntityState from '@geomagic/nam-react-core/components/EntityState';

const DispatchListAvatar = (props) => {
  const { data, entityClasses } = props;
  const entity = data.getPatchedEntity();
  const isDraftClosed = data?.draft?.closed;

  return (
    <Box sx={{ minWidth: 56 }}>
      <Badge
        anchorOrigin={{ horizontal: 'right', vertical: isDraftClosed ? 'top' : 'bottom' }}
        badgeContent={
          isDraftClosed ? (
            <CheckIcon sx={{ fontSize: 16 }} />
          ) : (
            <EntityState entity={entity} size="small" variant="circle" />
          )
        }
        overlap="circular"
        {...(isDraftClosed && { color: 'info' })}
      >
        <EntityAvatar entity={entity} entityClasses={entityClasses} />
      </Badge>
    </Box>
  );
};

DispatchListAvatar.propTypes = {
  data: PropTypes.object.isRequired,
  entityClasses: PropTypes.array.isRequired,
};

export default memo(DispatchListAvatar, (prev, next) => deepEqual(prev, next));
