import cloneDeep from 'lodash/cloneDeep';

const isSameReplacePatch = (patchA, patchB) => {
  return patchA.op === 'replace' && patchA.path === patchB.path && patchA.op === patchB.op;
};

/**
 *  Merge a new patch into the patches array. Can include add and remove patches.
 * @param {Array.object} oldPatches
 * @param {Array.object|object} jsonPatch
 * @returns {Array.object}
 */
const getPatches = (oldPatches, jsonPatch) => {
  const clonedPatches = oldPatches ? cloneDeep(oldPatches) : [];
  if (jsonPatch.addId) {
    return clonedPatches.map((patch) => {
      const { value } = patch;
      if (value?.id === jsonPatch.addId) {
        return { ...patch, value: { ...patch.value, ...jsonPatch.value } };
      } else {
        return patch;
      }
    });
  }

  if (jsonPatch.removeId) {
    const { path, removeId } = jsonPatch;
    return clonedPatches.filter((patch) => {
      const { path: PatchPath, value } = patch;
      return value?.id !== removeId && !PatchPath.startsWith(path);
    });
  }

  const newPatchArray = Array.isArray(jsonPatch) ? jsonPatch : [jsonPatch];

  const filteredPatches = clonedPatches.filter(
    (clonedPatch) => !newPatchArray.find((item) => isSameReplacePatch(clonedPatch, item))
  );

  return [...filteredPatches, ...newPatchArray];
};

export default getPatches;
