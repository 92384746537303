import gql from 'graphql-tag';

const QueryCandidates = gql`
  query QueryCandidates($taskIds: [ID!]!) {
    candidates(taskIds: $taskIds) {
      id
      loginName
      fullName
    }
  }
`;

export default QueryCandidates;
