import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import { makeStyles } from '@geomagic/core';
import { AutoForm, submitForm } from '@geomagic/forms';
import { getRawAttributeValue, getReference } from '@geomagic/geonam';
import Dialog from '@geomagic/nam-react-core/components/Dialog';
import { getAutoFormPropsByAttributeType } from '@geomagic/nam-react-core/components/Entity';
import { formValidationHandler } from '@geomagic/nam-react-core/utils';
import ModalActionBar from './ModalActionBar';

const MARGIN_TOP = 140;

const useStyles = makeStyles()(({ breakpoints, palette }) => ({
  root: {},
  dialogActions: {
    display: 'block',
  },
  dialogContent: {
    flex: 1,
  },
  dialogPaper: {
    background: palette.background.default,
    boxShadow: 'none',
    height: '100%',
    [breakpoints.up('sm')]: {
      height: `calc(100% - ${MARGIN_TOP}px)`,
      marginTop: MARGIN_TOP,
    },
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  formField: {
    background: palette.background.default,
  },
}));

const AUTO_FORM_ID = 'FillAttributForm';

const createFormSchemaAndUI = (attributeTypes, entity, isMobile, attributeValues) => {
  const requiredList = [];
  const properties = {};

  const ui = {};

  attributeTypes.forEach((attributeType, index) => {
    const { mandatory, readOnly } = attributeType;
    if (!readOnly || entity) {
      const { fieldSchema, fieldUI } = getAutoFormPropsByAttributeType({
        attributeType,
        entity,
        attributeTypes,
        attributeValues,
        isMobile,
        isAlternativeEnumSelect: true,
      });

      if (fieldSchema) {
        properties[index] = fieldSchema;
      }

      ui[index] = fieldUI;

      if (mandatory && !readOnly) {
        requiredList.push(index.toString());
      }
    }
  });

  return {
    schema: {
      type: 'object',
      required: requiredList,
      properties,
    },
    ui,
  };
};

const getUpdatedAttributeValues = (attributeTypes, values) => {
  return attributeTypes.map((attributeType, index) => {
    const typedValue = values[String(index)];

    return {
      attributeType: getReference(attributeType),
      value: getRawAttributeValue(attributeType, typedValue),
    };
  });
};

const getMergedAttributeValues = (attributeTypes, attributeValues) => {
  return attributeTypes.map((attributeType, index) => {
    const value = attributeValues.find((attributeValue) => attributeValue.attributeType.id === attributeType.id);

    return value
      ? value
      : {
          attributeType: getReference(attributeType),
          value: getRawAttributeValue(attributeType, undefined),
        };
  });
};

const DCMAttributes = (props) => {
  const {
    allAttributeTypes = [],
    dispatchAttribTypes = [],
    doc,
    isAttributesFill,
    isSmallMobile,
    onBack,
    onConfirm,
  } = props;
  const { classes } = useStyles(props);

  const dispatch = doc?.getPatchedEntity();
  const [attributeValues, setAttributeValues] = useState(dispatch ? dispatch.attributeValues : []);

  const schemaUI = useMemo(() => {
    return createFormSchemaAndUI(dispatchAttribTypes, dispatch, isSmallMobile, attributeValues);
  }, [attributeValues, dispatch, dispatchAttribTypes, isSmallMobile]);
  const { schema, ui } = schemaUI;

  /**
   *  EVENT HANDLER
   */

  const updateAttributeValues = (newAttributeValues) => {
    if (!isEqual(attributeValues, newAttributeValues)) {
      setAttributeValues(newAttributeValues);
    }
  };

  const handleChange = (values, formContext) => {
    const newAttribValues = getUpdatedAttributeValues(dispatchAttribTypes, values);
    const mergedValues = getMergedAttributeValues(allAttributeTypes, newAttribValues);

    updateAttributeValues(mergedValues);
  };

  const handleSubmit = (values, formContext) => {
    const newAttribValues = getUpdatedAttributeValues(dispatchAttribTypes, values);
    const mergedValues = getMergedAttributeValues(allAttributeTypes, newAttribValues);

    updateAttributeValues(mergedValues);
    onConfirm(mergedValues);
  };

  return (
    <>
      <AutoForm
        id={AUTO_FORM_ID}
        schema={schema}
        ui={ui}
        onSubmit={handleSubmit}
        onChange={handleChange}
        onError={formValidationHandler}
      >
        {(fields, formContext) => {
          return (
            <Dialog
              classes={{
                dialogActions: classes.dialogActions,
                dialogContent: classes.dialogContent,
                paper: classes.dialogPaper,
              }}
              fullScreen={isSmallMobile}
              fullWidth
              maxWidth={false}
              open={isAttributesFill}
              actions={<ModalActionBar onBack={onBack} onConfirm={() => submitForm(AUTO_FORM_ID)} />}
              content={<div className={classes.flexContainer}>{fields}</div>}
            />
          );
        }}
      </AutoForm>
    </>
  );
};

DCMAttributes.propTypes = {
  allAttributeTypes: PropTypes.array,
  dispatchAttribTypes: PropTypes.array,
  doc: PropTypes.object,
  isAttributesFill: PropTypes.bool,
  isSmallMobile: PropTypes.bool,
  onBack: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default DCMAttributes;
