/**
 * Check if is a NotLoggedInException in errors
 * @param {Array} errors - GraphQL errors
 * @returns {boolean}
 */

export const isNotLoggedInException = errors => {
  return errors.map(error => error.message).includes('NotLoggedInException');
};

export default isNotLoggedInException;
