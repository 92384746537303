import React from 'react';
import PropTypes from 'prop-types';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { ErrorBoundary as Error } from '@geomagic/core';
import { i18n } from '@geomagic/i18n';

import Placeholder from './Placeholder';

const ErrorBoundary = (props) => {
  const ErrorComponent = (...args) => {
    return (
      <Placeholder
        icon={<ErrorOutlineIcon />}
        subtitle={i18n.t('placeholder.routeNotFound.subtitle')}
        title={i18n.t('placeholder.routeNotFound.title')}
      />
    );
  };

  return <Error ErrorComponent={ErrorComponent}>{props.children}</Error>;
};

ErrorBoundary.propTypes = {
  children: PropTypes.node,
};

export default ErrorBoundary;
