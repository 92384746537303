import gql from 'graphql-tag';

export const FragmentLayer = gql`
  fragment Layer on Layer {
    className
    copyright
    filterable
    grouping {
      id
      name
      selectAll
    }
    id
    name
    options
    seqNumber
    sourceOptions
    sourceType
    type
    visible
  }
`;

export default FragmentLayer;
