import React from 'react';

import AssignmentIcon from '@mui/icons-material/AssignmentOutlined';
import DispatchIcon from '@mui/icons-material/SpeakerNotes';

import { i18n } from '@geomagic/i18n';

import GeonamCircleIcon from '@geomagic/nam-react-core/icons/GeonamCircleIcon';

import AppBar from '@components/AppBar';
import Assignments from '@components/Assignments';
import Dispatches from '@components/Dispatches';
import Login from '@components/Login';
import Navigation from '@components/Navigation';
import ViewContainer from '@components/ViewContainer';

const getViewConfig = () => {
  return {
    AppBarComponent: AppBar,
    NavigationComponent: Navigation,
    views: [
      {
        id: 'assignment',
        name: i18n.t('type.assignments'),
        path: '/assignment',
        pathParams: '/:id?',
        Component: Assignments,
        Icon: AssignmentIcon,
      },
      {
        id: 'dispatches',
        name: i18n.t('type.dispatches'),
        path: '/dispatch',
        Component: Dispatches,
        Icon: DispatchIcon,
      },
    ],
  };
};

const getAppConfig = () => {
  return {
    apps: [
      {
        id: 'entity',
        path: '/',
        Component: props => <ViewContainer config={getViewConfig} state={props} />,
      },
    ],
    LoadingComponent: GeonamCircleIcon,
    LoginComponent: Login,
  };
};

export default getAppConfig;
