import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Group from 'ol/layer/Group';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import LayerListItem from './LayerListItem';
import TooltipText from '@geomagic/nam-react-core/components/TooltipText';

const GroupListItem = (props) => {
  const { childs = [], getState, isState, layer, level, map, name, onHandleLayerClick, openGroups, selectAll } = props;

  const [isGroupOpen, setGroupOpen] = useState(!!openGroups);

  /**
   *  EVENT HANDLER
   */

  const handleGroupClick = useCallback(() => {
    setGroupOpen((previous) => !previous);
  }, [setGroupOpen]);

  return (
    <>
      <ListItem
        disableGutters
        divider
        secondaryAction={
          <Checkbox
            color={isState === -1 ? 'default' : 'primary'}
            checked={isState === 1 || isState === -1}
            disabled={isState === 0 && !selectAll}
            onClick={(evt) => {
              evt.stopPropagation();
              onHandleLayerClick(layer);
            }}
          />
        }
        sx={{
          border: 0,
          fontStyle: 'oblique',
          paddingLeft: 0,
          marginLeft: level,
          width: (theme) => `calc(100% - ${theme.spacing(level)})`,
        }}
      >
        <ListItemButton disableGutters onClick={handleGroupClick}>
          <ListItemIcon key={!isGroupOpen ? 'Visibility' : 'close'} sx={{ minWidth: 24 }}>
            {isGroupOpen ? <ExpandMoreIcon /> : <KeyboardArrowRightIcon />}
          </ListItemIcon>
          <ListItemText
            primary={<TooltipText content={name} typographyProps={{ fontWeight: 'bold', variant: 'body1' }} />}
            secondary={
              <Typography variant="body2" color="textSecondary" noWrap>
                {layer.get('layerType')}
              </Typography>
            }
          />
        </ListItemButton>
      </ListItem>
      <Collapse in={isGroupOpen} timeout="auto" unmountOnExit>
        {childs.map(({ id, olLayer: childLayer, childs: layerChilds, groupName, selectAll: selectAllChild }) => {
          if (childLayer instanceof Group) {
            return (
              <GroupListItem
                key={id}
                childs={layerChilds}
                getState={getState}
                isState={getState(childLayer)}
                layer={childLayer}
                level={level + 1}
                map={map}
                name={groupName}
                onHandleLayerClick={onHandleLayerClick}
                openGroups={openGroups}
                selectAll={selectAllChild}
              />
            );
          } else {
            return (
              <LayerListItem
                key={id}
                isState={getState(childLayer)}
                layer={childLayer}
                level={level + 1}
                map={map}
                onHandleLayerClick={onHandleLayerClick}
              />
            );
          }
        })}
      </Collapse>
    </>
  );
};

GroupListItem.propTypes = {
  childs: PropTypes.array,
  getState: PropTypes.func.isRequired,
  isState: PropTypes.number.isRequired,
  layer: PropTypes.object.isRequired,
  level: PropTypes.number.isRequired,
  map: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  onHandleLayerClick: PropTypes.func.isRequired,
  openGroups: PropTypes.bool,
  selectAll: PropTypes.bool,
};

export default GroupListItem;
