/**
 * Triggers to edit a newly created funcloc.
 */

import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { DEFAULT_TRIGGER_ICON_PROPS, MOBILE_TRIGGER_SIZE } from '@consts';
import { makeStyles, Trigger } from '@geomagic/core';
import { i18n } from '@geomagic/i18n';
import useShowPrompt from '@utils/useShowPrompt';

const useStyles = makeStyles()(({ breakpoints, spacing }) => ({
  trigger: {
    marginLeft: spacing(),
    [breakpoints.down('md')]: {
      height: MOBILE_TRIGGER_SIZE,
      marginLeft: 0,
      width: MOBILE_TRIGGER_SIZE,
    },
  },
}));

const EditFunclocTriggers = (props) => {
  const { disableDelete, onEditNewFuncloc, onDeleteFuncloc } = props;

  const { classes } = useStyles();
  const showPrompt = useShowPrompt();

  /**
   *  EVENT HANDLER
   */

  const handleEditFuncloc = (event) => {
    event.stopPropagation();
    onEditNewFuncloc();
  };

  const handleDeleteFuncloc = (event) => {
    event.stopPropagation();
    showPrompt({
      title: i18n.t('dialog.deleteFuncloc.title'),
      content: i18n.t('dialog.deleteFuncloc.content'),
      onOk: onDeleteFuncloc,
    });
  };

  return (
    <>
      <Tooltip title={i18n.t('tooltip.edit')}>
        <Trigger
          {...DEFAULT_TRIGGER_ICON_PROPS}
          className={classes.trigger}
          icon={<EditIcon />}
          onClick={handleEditFuncloc}
        />
      </Tooltip>
      <Tooltip title={i18n.t('tooltip.delete')}>
        <span>
          <Trigger
            {...DEFAULT_TRIGGER_ICON_PROPS}
            className={classes.trigger}
            disabled={disableDelete}
            icon={<DeleteIcon />}
            onClick={handleDeleteFuncloc}
          />
        </span>
      </Tooltip>
    </>
  );
};

EditFunclocTriggers.propTypes = {
  disableDelete: PropTypes.bool,
  onEditNewFuncloc: PropTypes.func.isRequired,
  onDeleteFuncloc: PropTypes.func.isRequired,
};

export default EditFunclocTriggers;
