const BACKEND_SERVER = process.env.REACT_APP_BACKEND_SERVER || 'http://localhost:3000/';
const CONTEXT = process.env.REACT_APP_CONTEXT || 'demo';

const GRAPHQL_PATH = process.env.REACT_APP_GRAPHQL_PATH || '/graphql/react';

export const DEFAULT_ENVIRONMENT = {
  BACKEND_SERVER: BACKEND_SERVER + CONTEXT,
  GRAPHQL_PATH,
};

export const CLASSNAME_ASSIGNMENT = 'Assignment';
export const CLASSNAME_DISPATCH = 'Dispatch';
export const CLASSNAME_DOCUMENT = 'Document';
export const CLASSNAME_FOLDER = 'Folder';
export const CLASSNAME_FUNCLOC = 'Funcloc';
export const CLASSNAME_USER = 'User';

export const RELEVANT_DISPATCH_PATH = '/relevantDispatches';

export const UNCACHED_TYPES = [
  'AttributeType',
  'AttributeValue',
  'EntityReference',
  'EnumValue',
  'GeometryStyle',
  'GeometryType',
  'ProcessAction',
  'RelationType',
];

const SORT_TYPE_PROPERTY = 'PROPERTY';
const DEFAULT_SORT = { ascending: false, property: 'MODIFIED_ON', type: SORT_TYPE_PROPERTY };

export const EMPTY_FILTER_CONFIG = {
  entityClassNames: [],
  entityTypes: [],
  attributeFilters: [],
  extraFilters: [],
  propertyFilters: [],
  relationFilters: [],
  sort: DEFAULT_SORT,
  searchTerm: '',
  mixed: true,
};
