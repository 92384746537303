import getAutoFormPropsByAttributeType from './getAutoFormPropsByAttributeType';

const createFormSchemaAndUI = (attributeTypes, entity, attributeValues, hideReadOnlyFields, isReadOnly, isMobile) => {
  const requiredList = [];
  const properties = {};
  const isDraft = !!entity?.draftInfo;

  const ui = {};
  attributeTypes.forEach((attributeType, index) => {
    const { mandatory, readOnly } = attributeType;
    if (!readOnly || (entity && !isDraft)) {
      if (hideReadOnlyFields && readOnly) {
        return;
      }

      const { fieldSchema, fieldUI } = getAutoFormPropsByAttributeType({
        attributeType,
        entity,
        attributeTypes,
        attributeValues,
        isReadOnly,
        isMobile,
      });

      properties[index] = fieldSchema;
      ui[index] = fieldUI;

      if (mandatory && !readOnly && !fieldUI.disabled) {
        requiredList.push(index.toString());
      }
    }
  });

  return {
    schema: {
      type: 'object',
      required: requiredList,
      properties,
    },
    ui,
  };
};

export default createFormSchemaAndUI;
