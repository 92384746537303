import gql from 'graphql-tag';

const FragmentDispatchCreationConfig = gql`
  fragment DispatchCreationConfig on DispatchCreationConfig {
    dispatchTypeId
    documentTypeId
    geometryStyleId
    dispatchAttribTypes {
      id
      className
    }
  }
`;

export default FragmentDispatchCreationConfig;
