import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { makeStyles, Trigger } from '@geomagic/core';
import { Markdown } from '@geomagic/forms/markdown';
import { i18n } from '@geomagic/i18n';
import { ContentRoot } from '@geomagic/layout';
import { PRIMARY_TRIGGER_PROPS } from '@consts';

const useStyles = makeStyles()(({ breakpoints, spacing }) => ({
  appBar: {
    position: 'relative',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    marginRight: spacing(),
  },
  dialogContent: {
    display: 'flex',
    height: '100vh',
    paddingTop: 0,
    [breakpoints.down('md')]: {
      padding: spacing(),
      paddingRight: 0,
    },
  },
  content: {
    display: 'flex',
  },
}));

const MarkDownTrigger = (props) => {
  const { classes } = useStyles(props);
  const { className, isMobile, onSubmit, open, source, title, ...triggerProps } = props;
  const [isDialogOpen, setDialogOpen] = useState(open);

  useEffect(() => {
    setDialogOpen(open);
  }, [open]);

  const handleCloseDialog = (event) => {
    event.stopPropagation();
    setDialogOpen(false);
  };

  const handleOpenDialog = (event) => {
    event.stopPropagation();
    setDialogOpen(true);
  };

  const closeTrigger = (
    <Trigger onClick={handleCloseDialog} sx={{ marginLeft: 1, minWidth: 'auto' }} {...PRIMARY_TRIGGER_PROPS}>
      {i18n.t('button.close')}
    </Trigger>
  );

  const Actions = !isMobile ? <DialogActions>{closeTrigger}</DialogActions> : null;

  const Header = isMobile ? (
    <AppBar className={classes.appBar} enableColorOnDark>
      <Toolbar className={classes.toolbar}>
        <Typography className={classes.title} variant="h6" noWrap>
          {title}
        </Typography>
        {closeTrigger}
      </Toolbar>
    </AppBar>
  ) : (
    <DialogTitle>{title}</DialogTitle>
  );

  const dialogProps = isMobile ? { fullScreen: true } : { fullWidth: true, maxWidth: 'md' };

  return (
    <>
      <Trigger onClick={handleOpenDialog} sx={{ marginLeft: 0.5 }} {...triggerProps} />
      <Dialog
        onClick={(event) => event.stopPropagation()}
        onClose={handleCloseDialog}
        open={!!isDialogOpen}
        {...dialogProps}
      >
        {Header}
        <DialogContent className={classes.dialogContent}>
          <ContentRoot className={classes.content} scrollable withPadding={false} withCustomScrollbar={!isMobile}>
            <Markdown source={source} />
          </ContentRoot>
        </DialogContent>
        {Actions}
      </Dialog>
    </>
  );
};

MarkDownTrigger.propTypes = {
  className: PropTypes.string,
  isMobile: PropTypes.bool,
  onSubmit: PropTypes.func,
  open: PropTypes.bool,
  source: PropTypes.string,
  title: PropTypes.string,
};

export default MarkDownTrigger;
