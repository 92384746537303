/**
 * This component checks whether there are data changes and displays
 * a dialog if so, so that you can trigger synchronization directly.
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Trigger } from '@geomagic/core';
import { i18n } from '@geomagic/i18n';
import StackedDialog from '@geomagic/nam-react-core/components/StackedDialog';
import { PRIMARY_TRIGGER_PROPS, SECONDARY_TRIGGER_PROPS } from '@consts';
import hasUpdates from '@database/hasUpdates';
import getFetchConfigPrefetch from '@synchronization/getFetchConfigPrefetch';
import useLoadingSnackbar from '@utils/useLoadingSnackbar';

const useStyles = makeStyles()(({ spacing }) => {
  return {
    dialogContent: {
      padding: spacing(0, 1),
    },
  };
});

const PrefetchDataDialog = (props) => {
  const { client, database, handleFetch, userId } = props;

  const enqueueLoadingSnackbar = useLoadingSnackbar({ key: 'prefetch' });

  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isPrefetching, setPrefetching] = useState(false);

  const { classes } = useStyles(props);

  /**
   *  EVENT HANDLER
   */

  const handleFetchData = () => {
    setDialogOpen(false);
    handleFetch();
  };

  const handleCloseDialog = (event, reason) => {
    if (reason === 'backdropClick') return;
    setDialogOpen(false);
  };

  /**
   * EFFECTS
   */

  useEffect(() => {
    if (!isPrefetching) {
      setPrefetching(true);
      const fetchConfigPrefetch = getFetchConfigPrefetch();
      const checkForUpdates = async () => {
        return await enqueueLoadingSnackbar({
          loadingText: i18n.t('synchronization.notification.prefetch.loading'),
          func: () => hasUpdates({ database, client, config: fetchConfigPrefetch, userId }),
        });
      };

      checkForUpdates().then(setDialogOpen);
    }
  }, [client, database, enqueueLoadingSnackbar, isPrefetching, userId]);

  /**
   *  COMPONENTS
   */

  const DialogContent = <div className={classes.dialogContent}>{i18n.t('dialog.prefetch.content')}</div>;

  const ActionsComponent = (
    <>
      <Trigger {...SECONDARY_TRIGGER_PROPS} onClick={handleCloseDialog}>
        {i18n.t('button.notNow')}
      </Trigger>

      <Trigger {...PRIMARY_TRIGGER_PROPS} onClick={handleFetchData}>
        {i18n.t('button.fetchData')}
      </Trigger>
    </>
  );

  return (
    <StackedDialog
      actions={ActionsComponent}
      content={DialogContent}
      isFullscreen={false}
      handleClose={handleCloseDialog}
      open={isDialogOpen}
      title={i18n.t('dialog.prefetch.title')}
    />
  );
};

PrefetchDataDialog.propTypes = {
  client: PropTypes.object.isRequired,
  database: PropTypes.object.isRequired,
  handleFetch: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
};

export default PrefetchDataDialog;
