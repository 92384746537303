/**
 * Displays a track location feature with heading icon and accuracy.
 */

import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Feature from 'ol/Feature';
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';
import Point from 'ol/geom/Point';
import Circle from 'ol/geom/Circle';
import { transform } from 'ol/proj';

import { DEFAULT_GEOLOCATION_PROJECTION } from './consts';

const HEADING_ICON = `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" class="svgClass"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2L4.5 20.29l.71.71L12 18l6.79 3 .71-.71z" fill="#008000" stroke="#ffffff" stroke-width="2"/></svg>`;
const POSITION_FEATURE_ID = 'currentPosition';
const POSITION_ACCURANCY_FEATURE_ID = 'currentPositionAccuracy';

const POSITION_HEADING_STYLE = new Style({
  image: new Icon({
    src: 'data:image/svg+xml,' + encodeURIComponent(HEADING_ICON),
  }),
});

const useTrackLocationFeature = ({ accuracy, location, getTrackingOverlay, isVisible, map, rotation }) => {
  useEffect(() => {
    if (isVisible) {
      const trackingOverlay = getTrackingOverlay();
      const trackingOverlaySource = trackingOverlay.getSource();
      const positionFeature = new Feature();
      positionFeature.setId(POSITION_FEATURE_ID);
      trackingOverlaySource.addFeature(positionFeature);

      const accuracyFeature = new Feature();
      accuracyFeature.setId(POSITION_ACCURANCY_FEATURE_ID);
      trackingOverlaySource.addFeature(accuracyFeature);

      return () => {
        trackingOverlaySource.removeFeature(trackingOverlaySource.getFeatureById(POSITION_FEATURE_ID));
        trackingOverlaySource.removeFeature(trackingOverlaySource.getFeatureById(POSITION_ACCURANCY_FEATURE_ID));
      };
    }
  }, [isVisible, getTrackingOverlay]);

  useEffect(() => {
    if (location) {
      const trackingOverlay = getTrackingOverlay();
      const trackingOverlaySource = trackingOverlay.getSource();
      const positionFeature = trackingOverlaySource.getFeatureById(POSITION_FEATURE_ID);
      const accuracyFeature = trackingOverlaySource.getFeatureById(POSITION_ACCURANCY_FEATURE_ID);

      const projectionCode = map
        .getView()
        .getProjection()
        .getCode();

      positionFeature.setGeometry(new Point(transform(location, DEFAULT_GEOLOCATION_PROJECTION, projectionCode)));
      const geom = new Circle(location, (accuracy * 360) / (2 * Math.PI * 6400000));

      geom.transform(DEFAULT_GEOLOCATION_PROJECTION, projectionCode);

      accuracyFeature.setGeometry(geom);
    }
  }, [accuracy, location, map, getTrackingOverlay]);

  useEffect(() => {
    if (isVisible && !!window.DeviceOrientationEvent) {
      const trackingOverlay = getTrackingOverlay();
      const trackingOverlaySource = trackingOverlay.getSource();
      const positionFeature = trackingOverlaySource.getFeatureById(POSITION_FEATURE_ID);

      POSITION_HEADING_STYLE.getImage().setRotation(rotation);
      positionFeature.setStyle(POSITION_HEADING_STYLE);
    }
  }, [isVisible, rotation, getTrackingOverlay]);
};

useTrackLocationFeature.propTypes = {
  accuracy: PropTypes.number.isRequired,
  getTrackingOverlay: PropTypes.func.isRequired,
  isTrackLocation: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  map: PropTypes.object.isRequired,
  rotation: PropTypes.string.isRequired,
};

export default useTrackLocationFeature;
