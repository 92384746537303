import gql from 'graphql-tag';

import FragmentAttributeValues from '../fragments/FragmentAttributeValues';
import FragmentEntityReference from '../fragments/FragmentEntityReference';

const MutationUpdateEntity = gql`
  ${FragmentEntityReference}
  ${FragmentAttributeValues}

  mutation MutationUpdateEntity($entity: EntityUpdateInput!) {
    updateEntity(entity: $entity) {
      ...EntityReference
      shortDescription
      ...AttributeValues
    }
  }
`;

export default MutationUpdateEntity;
