import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@geomagic/core';

const useStyles = makeStyles()(() => ({
  root: {
    pointerEvents: 'auto',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const TriggerDrawInteractions = (props) => {
  const { icon, onChange, onClick, stylesByShapeType = [], triggerProps } = props;

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { classes } = useStyles(props);

  /**
   *  EVENT HANDLER
   */

  const handleClick = (event) => {
    const selectedShapeType = stylesByShapeType[selectedIndex];
    onClick && onClick(selectedShapeType);
  };

  const handleClickMenuItem = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
    onChange && onChange(event, index);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  return (
    <div className={classes.root}>
      <ButtonGroup color="default" ref={anchorRef} variant="contained" {...triggerProps}>
        <Button classes={{ label: classes.label }} onClick={handleClick} startIcon={icon}>
          <Typography variant="button" noWrap>
            {stylesByShapeType[selectedIndex]?.name}
          </Typography>
        </Button>
        {stylesByShapeType.length > 1 && (
          <Button onClick={handleToggle}>
            <ArrowDropDownIcon />
          </Button>
        )}
      </ButtonGroup>
      <Popper anchorEl={anchorRef.current} disablePortal open={open} transition>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {stylesByShapeType.map((item, index) => {
                    const { id, name } = item;
                    return (
                      <MenuItem
                        key={id}
                        selected={index === selectedIndex}
                        onClick={(event) => handleClickMenuItem(event, index)}
                      >
                        {name}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

TriggerDrawInteractions.propTypes = {
  icon: PropTypes.node,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  stylesByShapeType: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.node.isRequired,
    })
  ),
  triggerProps: PropTypes.object,
};

export default TriggerDrawInteractions;
