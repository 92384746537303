/**
 * Function to remove the draft status of a newly created entity.
 * @param {object} doc as RxDocument
 * @param {object} createdEntity
 * @returns
 */

const DISPATCH_CLASSNAME = 'Dispatch';

const resetDraftDoc = async (doc, createdEntity) => {
  const resettedDoc = await doc.atomicUpdate((oldData) => {
    oldData.entity = { ...oldData.entity, ...createdEntity };
    oldData.draft = null;
    if (oldData.entity.className === DISPATCH_CLASSNAME) {
      oldData.isWorkbench = true;
      oldData.isNearby = false;
    }

    return oldData;
  });

  return resettedDoc;
};

export default resetDraftDoc;
