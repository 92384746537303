import { Select } from 'ol/interaction';
import { singleClick } from 'ol/events/condition';

import clearFeatureStyle from './clearFeatureStyle';

/**
 * Add select interaction to OpenLayers map
 * @param {object} params
 * @param {object} params.map - OpenLayers map
 * @param {Array.<object>} params.layers - Array of layer refs
 * @param {Function} params.onSelect - Additional select interaction props (https://openlayers.org/en/latest/apidoc/module-ol_interaction_Select.html)
 * @param {object} params.options - Function which is called after modifying
 * @returns {object}
 */

const enableSelectInteraction = ({ map, layers, onSelect, options = {} }) => {
  const select = new Select({
    layers: layers.map((layerRef) => layerRef.current),
    style: false,
    ...options,
    condition: singleClick,
  });

  select.on('select', (event) => {
    const { selected, deselected } = event;

    if (deselected.length > 0 || selected.length > 0) {
      clearFeatureStyle(layers);
    }

    onSelect(event, selected[0]);
  });

  map.addInteraction(select);

  return select;
};

export default enableSelectInteraction;
