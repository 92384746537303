import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { FORM_COMPONENT_MIN_HEIGHT, FORM_COMPONENT_TOGGLE_BUTTON_WIDTH } from '../consts';
import getKey from '../utils/getKey';

const EnumToggle = (props) => {
  const { context, data, path } = props;
  const { isReadOnly, onUpdate } = context;
  const { mandatory, statusValue, statusValues } = data;

  const [selectedStatusValue, setSelectedStatusValue] = useState(statusValue);

  /**
   *  EVENT HANDLER
   */

  const handleChange = (_, value) => {
    const newStatusValue = statusValues.find(({ name: statusValueName }) => statusValueName === value) || null;

    if (statusValue !== newStatusValue) {
      setSelectedStatusValue(newStatusValue);
      onUpdate({ statusValue: newStatusValue }, path, data);
    }
  };

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        my: 1,
        mx: 0.5,
        overflow: 'hidden',
        width: {
          mobile: '100%',
          tablet: 'auto',
        },
        ...(isReadOnly && { pointerEvents: 'none' }),
      }}
    >
      <ToggleButtonGroup
        exclusive
        onChange={handleChange}
        sx={{
          alignItems: 'center',
          display: 'flex',
          flex: 1,
          overflow: 'hidden',
          ...(mandatory && !statusValue && { border: `2px solid`, borderColor: 'warning.main', borderRadius: 2 }),
          ...(mandatory && statusValue && { border: `2px solid`, borderColor: 'transparent', borderRadius: 2 }),
        }}
        value={selectedStatusValue && selectedStatusValue.name}
      >
        {statusValues.map(({ name: statusValueName }, index) => {
          return (
            <ToggleButton
              key={getKey(statusValueName, index)}
              sx={{
                flex: 1,
                minHeight: FORM_COMPONENT_MIN_HEIGHT,
                minWidth: {
                  mobile: 'auto',
                  tablet: FORM_COMPONENT_TOGGLE_BUTTON_WIDTH,
                },
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                '& span': {
                  display: 'block',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                },
              }}
              value={statusValueName}
            >
              <span>{statusValueName}</span>
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
    </Box>
  );
};

EnumToggle.propTypes = {
  context: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
};

export default EnumToggle;
