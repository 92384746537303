import { addPouchPlugin, addRxPlugin, createRxDatabase, getRxStoragePouch } from 'rxdb';
import getPatchedEntity from './getPatchedEntity';

addPouchPlugin(require('pouchdb-adapter-idb'));

const patchPlugin = {
  rxdb: true,
  prototypes: {
    RxDocument: proto => {
      proto.getPatchedEntity = function() {
        return getPatchedEntity(this);
      };
    },
  },
};

addRxPlugin(patchPlugin);

const initialize = async collections => {
  const database = await createRxDatabase({
    name: 'offline_database',
    storage: getRxStoragePouch('idb'),
  });
  console.log('OFFLINE DATABASE: created database');

  if (collections) {
    await database.addCollections(collections);
    console.log(`OFFLINE DATABASE: added collection(s) ${Object.keys(collections).join(', ')}`);
  }

  return database;
};

export default initialize;
