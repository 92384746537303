import React, { memo, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import classNames from 'classnames';
import { makeStyles } from '@geomagic/core';

const useStyles = makeStyles()(({ palette, spacing }) => ({
  listItem: {
    borderBottom: `1px solid ${palette.divider}`,
    backgroundColor: palette.background.paper,
    minHeight: 70,
    paddingLeft: spacing(),
    '&$firstItem': {
      borderTop: `1px solid ${palette.divider}`,
    },
  },
  listItemAvatar: {
    minWidth: 0,
  },
  listItemSecondaryActions: {
    right: spacing(),
  },
  listItemSecondaryActionsCount: {
    '&-0': {
      paddingRight: spacing(3),
    },
    '&-1': {
      paddingRight: spacing(6),
    },
    '&-2': {
      paddingRight: spacing(12),
    },
  },
}));

const ListItemComponent = (props) => {
  const {
    ActionsComponent,
    activeId,
    additionalProps,
    AvatarComponent,
    item,
    itemId,
    listItemRefs,
    onClick,
    TextComponent,
  } = props;

  const { classes } = useStyles(props);
  const [listActionsCount, setListActionsCount] = useState(0);

  const listItemSecondaryActionRef = useRef(null);

  useEffect(() => {
    const children = listItemSecondaryActionRef?.current?.children?.[0]?.children;
    const count = children?.length || 0;
    setListActionsCount(count);
    return () => {
      setListActionsCount(0);
    };
  }, [listItemSecondaryActionRef]);

  return (
    <ListItem
      ref={(el) => (listItemRefs.current[itemId] = el)}
      className={classNames(classes.listItem, `${classes.listItemSecondaryActionsCount}-${listActionsCount}`)}
      selected={itemId === activeId}
      {...(onClick && { button: true, onClick: () => onClick(item) })}
    >
      {AvatarComponent && (
        <ListItemAvatar className={classes.listItemAvatar}>
          <AvatarComponent data={item} {...additionalProps} />
        </ListItemAvatar>
      )}
      {TextComponent && <TextComponent data={item} {...additionalProps} />}
      {ActionsComponent && (
        <ListItemSecondaryAction className={classes.listItemSecondaryActions} ref={listItemSecondaryActionRef}>
          <ActionsComponent data={item} {...additionalProps} />
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

ListItemComponent.propTypes = {
  ActionsComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  activeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  additionalProps: PropTypes.object,
  AvatarComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  item: PropTypes.object,
  itemId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  listItemRefs: PropTypes.object,
  onClick: PropTypes.func,
  TextComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

export default memo(ListItemComponent);
