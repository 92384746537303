/**
 * UI form element to select a team.
 */

import React from 'react';
import PropTypes from 'prop-types';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import InputAdornment from '@mui/material/InputAdornment';

import { i18n } from '@geomagic/i18n';
import SingleSelect from '@geomagic/nam-react-core/components/SingleSelect';

const getInvalidOption = value => ({
  label: i18n.t('placeholder.invalidOption'),
  value,
  disabled: true,
});

const SelectTeam = props => {
  const { label = i18n.t('default.team'), teamOptions, updateField, value = null } = props;

  const defaultTeam = value ? teamOptions.find(option => option.id === value.id) || getInvalidOption(value) : null;

  return (
    <SingleSelect
      disableClearable={false}
      getOptionDisabled={option => (option ? option.disabled : false)}
      getOptionLabel={item => item.name}
      getOptionSelected={(option, item) => option.id === item.id}
      options={teamOptions}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <PeopleOutlineIcon />
          </InputAdornment>
        ),
      }}
      label={label}
      onChange={(event, newValue) => {
        updateField(newValue);
      }}
      value={defaultTeam}
    />
  );
};

SelectTeam.propTypes = {
  teamOptions: PropTypes.array.isRequired,
  label: PropTypes.string,
  updateField: PropTypes.func.isRequired,
  value: PropTypes.object,
};

export default SelectTeam;
