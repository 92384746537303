/**
 * Clear all features from a specific layer
 * @param {object} layer - OpenLayers layer object
 * @returns {void}
 */

const clearFeaturesFromLayer = (layer) => {
  layer && layer.getSource().clear(true);
};

export default clearFeaturesFromLayer;
