import React from 'react';
import Typography from '@mui/material/Typography';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { showModal as showCustomModal } from '@geomagic/core';
import { PRIMARY_TRIGGER_PROPS } from '@consts';

/**
 * Provides an custom show modal function
 * @param {object} params
 * @param {*} params.content - modal content
 * @param {*} params.title - modal title
 * @param {Function} params.onOk - modal ok function
 * @returns {void}
 */

const showModal = (params) => {
  const { isFullScreen, okAction, onOk, title, ...other } = params;

  showCustomModal({
    maxWidth: 'sm',
    DialogTitleProps: {
      sx: { padding: isFullScreen ? 0 : 2 },
      ...(isFullScreen && {
        iconLeft: <ChevronLeftIcon />,
      }),
      ...(isFullScreen && {
        toolbarProps: {
          sx: {
            minHeight: '56px !important',
          },
        },
      }),
    },
    DialogContentProps: {
      sx: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: '0px !important',
        marginTop: isFullScreen ? '56px !important' : 0,
      },
    },
    isMobile: isFullScreen,
    okAction: okAction || onOk ? okAction : <div />,
    okActionProps: PRIMARY_TRIGGER_PROPS,
    onOk,
    title: (
      <Typography noWrap sx={{ fontWeight: 'bold' }} variant="body1">
        {title}
      </Typography>
    ),
    withDivider: true,
    ...other,
  });
};

export default showModal;
