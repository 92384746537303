import buildUrl from './buildUrl';
import getUrlScheme from './getUrlScheme';

/**
 * Get an url for navigation
 * @param {object} options
 * @param {number} options.lat - latitude
 * @param {number} options.lon - longitude
 * @param {string} options.name
 * @param {object} config - Config with endpoints for OS specific map applications
 * @param {string} platform - OS
 * @returns {string}
 */

const getUrl = ({ lat, lon, name }, config, platform) => {
  const latlon = `${lat},${lon}`;

  const urlTemplate = getUrlScheme(config, platform);
  if (!urlTemplate) {
    throw new Error('invalid URL navigation scheme');
  }

  return buildUrl(urlTemplate, {
    latlon,
    lat,
    lon,
    name,
  });
};

export default getUrl;
