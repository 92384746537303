import { RELEVANT_DISPATCH_PATH } from '@graphql/consts';

const selector = {
  jsonPatch: {
    $elemMatch: {
      $or: [
        {
          path: RELEVANT_DISPATCH_PATH,
          value: {
            $elemMatch: {
              closed: { $eq: true },
            },
          },
        },
        {
          path: {
            $nin: [RELEVANT_DISPATCH_PATH],
          },
        },
      ],
    },
  },
};

export default selector;
