import getLayer from './getLayer';
/**
 * Is a layer visible.
 * @param {string} id
 * @param {object} map
 * @returns {boolean}
 */
const isLayerVisible = (id, map) => {
  const layer = getLayer(id, map);
  if (layer) {
    return layer.getVisible();
  }
  return false;
};

export default isLayerVisible;
