import React, { memo } from 'react';
import PropTypes from 'prop-types';
import deepEqual from 'deep-equal';
import HelpIcon from '@mui/icons-material/Help';
import { i18n } from '@geomagic/i18n';
import { FORM_COMPONENT_CHILDREN_KEY } from './consts';
import FormPage from './FormElementPage';
import FormElementBlock from './FormElementBlock';
import FormElementRow from './FormElementRow';
import FormElement from './FormElement';
import isMarkdownDescriptionType from './utils/isMarkdownDescriptionType';
import MarkDownTrigger from './MarkDownTrigger';
import NewFunclocTrigger from './NewFunclocTrigger';

const getElementByTypename = ({ typename }) => {
  let Component;

  switch (typename) {
    case 'Form':
      Component = FormPage;
      break;
    case 'FormElementBlock':
      Component = FormElementBlock;
      break;
    case 'FormElementRow':
      Component = FormElementRow;
      break;
    default:
      Component = FormElement;
  }

  return Component;
};

const FormComponent = (props) => {
  const {
    context,
    data,
    doc,
    entityClasses,
    getPreviousMap,
    id,
    isDefectStatusSelected,
    mapProps,
    newChildIndex,
    onEditNewFuncloc,
    onRemoveFuncloc,
    path,
    parentPath,
    activeStep,
    user,
    ...elementProps
  } = props;

  const { isMobile } = context;
  const { children = [], description, displayedIfDefect, name, usedAsTemplate = false } = data;

  const isElementDisplayed = !displayedIfDefect || isDefectStatusSelected;

  const Component = getElementByTypename(data);
  const isMarkdown = isMarkdownDescriptionType(data);

  const infoTrigger = isMarkdown && (
    <MarkDownTrigger
      icon={<HelpIcon />}
      isMobile={isMobile}
      source={description}
      title={i18n.t('assignment.dialog.description.title', { variables: { name } })}
      variant="icon"
    />
  );

  return isElementDisplayed ? (
    <>
      {usedAsTemplate ? (
        <NewFunclocTrigger
          data={data}
          entityClasses={entityClasses}
          newChildIndex={newChildIndex}
          onEditNewFuncloc={onEditNewFuncloc}
          parentPath={parentPath}
        />
      ) : (
        <Component
          context={context}
          data={data}
          doc={doc}
          entityClasses={entityClasses}
          getPreviousMap={getPreviousMap}
          infoTrigger={infoTrigger}
          isDefectStatusSelected={isDefectStatusSelected}
          mapProps={mapProps}
          onEditNewFuncloc={onEditNewFuncloc}
          onRemoveFuncloc={onRemoveFuncloc}
          path={path}
          user={user}
          {...elementProps}
        >
          {children?.map((child, idx) => {
            return (
              <FormComponent
                activeStep={activeStep}
                context={context}
                data={child}
                doc={doc}
                entityClasses={entityClasses}
                getPreviousMap={getPreviousMap}
                id={child.id}
                key={child.id}
                mapProps={mapProps}
                newChildIndex={children.length}
                onEditNewFuncloc={onEditNewFuncloc}
                onRemoveFuncloc={onRemoveFuncloc}
                parentPath={`${path}/${FORM_COMPONENT_CHILDREN_KEY}`}
                path={`${path}/${FORM_COMPONENT_CHILDREN_KEY}/${idx}`}
                user={user}
              />
            );
          })}
        </Component>
      )}
    </>
  ) : null;
};

FormComponent.propTypes = {
  activeStep: PropTypes.number,
  context: PropTypes.object,
  data: PropTypes.object,
  doc: PropTypes.object,
  entityClass: PropTypes.object,
  entityClasses: PropTypes.array,
  getPreviousMap: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isDefectStatusSelected: PropTypes.bool,
  mapProps: PropTypes.object.isRequired,
  newChildIndex: PropTypes.number,
  onEditNewFuncloc: PropTypes.func,
  onRemoveFuncloc: PropTypes.func,
  parentPath: PropTypes.string,
  path: PropTypes.string,
  user: PropTypes.object.isRequired,
};

export default memo(FormComponent, (prev, next) => deepEqual(prev, next));
