import { getTypedAttributeValue } from '../../../utils';

const getEnumList = (allowedValues, attributeType, multiSelect = false, attributeValues) => {
  const { dependency } = attributeType;

  return allowedValues.reduce((acc, allowedValue) => {
    const { key, value, dependentValue } = allowedValue;
    let isDependencyMet = true;

    if (dependency?.restrictEnumValues && dependentValue) {
      const { attributeTypeId: depAttributeTypeId } = dependency;
      const attributeValue = attributeValues.find(
        ({ attributeType: attributeValueAttributeType, attributeTypeId }) =>
          attributeValueAttributeType?.id === depAttributeTypeId || attributeTypeId === depAttributeTypeId
      );
      isDependencyMet = attributeValue?.value === dependentValue;
    }

    if (isDependencyMet) {
      if (multiSelect) {
        acc.push({
          id: key,
          name: getTypedAttributeValue(attributeType, value),
        });
      } else {
        acc.push(getTypedAttributeValue(attributeType, value));
      }
    }

    return acc;
  }, []);
};

export default getEnumList;
