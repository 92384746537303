import getLayer from './getLayer';
/**
 * Set the layer visibility.
 * @param {object} map
 * @param {string} id
 * @param {boolean} vis
 * @returns {void}
 */
const setLayerVisible = (map, id, vis) => {
  const layer = getLayer(id, map);
  if (layer) {
    layer.setVisible(vis);
  }
};

export default setLayerVisible;
