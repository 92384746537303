import { useEffect, useRef, useState } from 'react';

const useSyncCount = (database, config = [], userId) => {
  const [counts, setCounts] = useState(0);
  const subRefs = useRef([]);

  useEffect(() => {
    if (database) {
      for (let i = 0; i < config.length; i++) {
        const { collectionName, selector } = config[i];

        const sub = database[collectionName].find({ selector: { ...selector, userId } }).$.subscribe((results) => {
          if (results) {
            const count = results.length;

            setCounts((prev) => (prev[collectionName] !== count ? { ...prev, [collectionName]: count } : prev));
          }
        });

        subRefs.current = [...subRefs.current, sub];
      }
    }

    return () => {
      subRefs.current.forEach((sub) => sub.unsubscribe());
    };
  }, [config, database, userId]);

  return Object.values(counts).reduce((prev, cur) => prev + cur, 0);
};

export default useSyncCount;
