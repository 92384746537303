import gql from 'graphql-tag';

import FragmentDispatchPrefetch from '../fragments/FragmentDispatchPrefetch';

const QueryDispatchesPrefetch = gql`
  ${FragmentDispatchPrefetch}

  query QueryEntities($filter: FilterInput!) {
    entities(filter: $filter) {
      values {
        ...DispatchPrefetch
      }
      totalValueCount
    }
  }
`;

export default QueryDispatchesPrefetch;
