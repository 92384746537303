/**
 * A info box for a dispatch displayed in the map.
 */

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useRxDocument } from 'rxdb-hooks';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Zoom from '@mui/material/Zoom';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles, Trigger } from '@geomagic/core';
import { getEntityType } from '@geomagic/geonam';
import { i18n } from '@geomagic/i18n';
import { ContentRoot } from '@geomagic/layout';

import { DEFAULT_TRIGGER_ICON_PROPS, MOBILE_TRIGGER_SIZE } from '@consts';
import { ENTITY_SELECTOR_KEY } from '@database/consts';
import { getFormattedAttributeValue } from '@geomagic/nam-react-core/utils';

import AddAPhotoAction from './AddAPhotoAction';

const FILTERED_READ_ONLY_CODES = ['property.name', 'property.referenceNumber', 'property.processingStatus'];

const PAPER_SIZE = 300;
const PAPER_XS_SIZE = 200;
const PAPER_SM_SIZE = 250;

const useStyles = makeStyles()(({ breakpoints, palette, shape, spacing }) => ({
  actions: {
    display: 'flex',
  },
  content: {},
  listItem: {
    margin: 0,
    padding: spacing(0.5, 1),
  },
  listItemText: {
    margin: 0,
  },
  paper: {
    borderRadius: shape.borderRadius,
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
    maxHeight: PAPER_SIZE,
    padding: 0,
    pointerEvents: 'auto',
    position: 'absolute',
    right: spacing(1),
    top: spacing(1),
    width: PAPER_SIZE,
    zIndex: 2,
    [breakpoints.down('sm')]: {
      maxHeight: PAPER_SM_SIZE,
      width: PAPER_SM_SIZE,
    },
    [breakpoints.down('xs')]: {
      maxHeight: PAPER_XS_SIZE,
      width: PAPER_XS_SIZE,
    },
  },
  title: {
    marginRight: spacing(0.5),
  },
  titleContainer: {
    alignItems: 'center',
    borderBottom: `1px solid ${palette.divider}`,
    display: 'flex',
    justifyContent: 'space-between',
    padding: spacing(1),
  },
  trigger: {
    height: MOBILE_TRIGGER_SIZE,
    width: MOBILE_TRIGGER_SIZE,
  },
}));

const getInfoValue = (attributeType, attributeValue) => {
  return { label: attributeType.name, value: getFormattedAttributeValue(attributeType, attributeValue) };
};

const isReadOnlyAttributeType = (attributeType) =>
  attributeType.readOnly && !FILTERED_READ_ONLY_CODES.some((filteredCode) => attributeType.code === filteredCode);
const isDCMAttributeType = (attributeType, dispatchAttribTypes) =>
  dispatchAttribTypes.some((dispatchAttribType) => dispatchAttribType.id === attributeType.id);
const isNotNullish = (value) => value !== undefined && value !== null;

const getInfoValues = (entity, allAttributeTypes, dispatchAttribTypes) => {
  const { attributeValues } = entity;
  const filteredAttributeTypes = allAttributeTypes.filter(
    (attributeType) => isReadOnlyAttributeType(attributeType) || isDCMAttributeType(attributeType, dispatchAttribTypes)
  );
  return filteredAttributeTypes
    .map((attributeType) => {
      const foundAttributeValue = attributeValues?.find(
        (attributeValue) => attributeValue.attributeType.id === attributeType.id
      );
      return getInfoValue(attributeType, foundAttributeValue?.value);
    })
    .filter((infoValue) => isNotNullish(infoValue.value));
};

const DispatchInfoBox = (props) => {
  const { allAttributeTypes, dispatchAttribTypes, documentTypeId, entityClasses, selectedEntityId, onClose } = props;

  const { classes } = useStyles(props);

  const { result: dispatch } = useRxDocument('dispatches', selectedEntityId, {
    idAttribute: ENTITY_SELECTOR_KEY,
  });
  const entity = dispatch?.getPatchedEntity();
  const showInfoBox = selectedEntityId && dispatch;

  const { className, displayName } = entity || {};
  const entityType = entity?.entityType?.id ? getEntityType(entityClasses, className, entity?.entityType?.id) : {};

  const title = displayName || entityType?.name;

  const infoValues = useMemo(() => {
    if (entity) {
      return getInfoValues(entity, allAttributeTypes, dispatchAttribTypes);
    }
    return [];
  }, [allAttributeTypes, dispatchAttribTypes, entity]);

  /**
   *  COMPONENTS
   */

  const CloseActionComponent = (
    <Trigger
      {...DEFAULT_TRIGGER_ICON_PROPS}
      className={classes.trigger}
      onClick={onClose}
      icon={<CloseIcon />}
      tooltip={i18n.t('tooltip.close')}
    />
  );

  return (
    <Zoom in={showInfoBox} exit={false}>
      <Paper elevation={4} className={classes.paper}>
        <div className={classes.titleContainer}>
          <Typography className={classes.title} color="primary" noWrap variant="h6">
            {title}
          </Typography>
          <div className={classes.actions}>
            <AddAPhotoAction doc={dispatch} documentTypeId={documentTypeId} />
            {CloseActionComponent}
          </div>
        </div>
        <ContentRoot className={classes.content} withCustomScrollbar withPadding={false}>
          {infoValues.map((infoValue, index) => {
            const { label, value } = infoValue;

            return (
              <ListItem key={'infoValue-' + index} className={classes.listItem} disableGutters>
                <ListItemText
                  className={classes.listItemText}
                  primary={label}
                  primaryTypographyProps={{ color: 'textSecondary', noWrap: true, variant: 'body2' }}
                  secondary={value}
                  secondaryTypographyProps={{ color: 'textPrimary', noWrap: true, variant: 'body1' }}
                />
              </ListItem>
            );
          })}
        </ContentRoot>
      </Paper>
    </Zoom>
  );
};

DispatchInfoBox.propTypes = {
  allAttributeTypes: PropTypes.array,
  dispatchAttribTypes: PropTypes.array,
  documentTypeId: PropTypes.string.isRequired,
  entityClasses: PropTypes.array.isRequired,
  selectedEntityId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

export default DispatchInfoBox;
