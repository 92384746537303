/**
 * A trigger component to add a new funcloc to a form.
 */

import React from 'react';
import PropTypes from 'prop-types';
import AddOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { DEFAULT_TRIGGER_PROPS_OUTLINED } from '@consts';
import { makeStyles, Trigger } from '@geomagic/core';
import { i18n } from '@geomagic/i18n';
import getFunclocTypeName from './utils/getFunclocTypeName';

const useStyles = makeStyles()(({ spacing }) => ({
  trigger: {},
  triggerAdd: {
    margin: spacing(2, 2),
    display: 'flex',
    justifyContent: 'center',
  },
}));

const NewFunclocTrigger = (props) => {
  const { classes } = useStyles();

  const { data, entityClasses, newChildIndex, onEditNewFuncloc, parentPath } = props;

  const triggerLabel = i18n.t(`funcloc.button.add`, {
    variables: {
      name: getFunclocTypeName(entityClasses, data.funclocTypeId),
    },
  });

  /**
   *  EVENT HANDLER
   */

  const handleClick = () =>
    onEditNewFuncloc({
      template: data,
      parentPath,
      path: `${parentPath}/${newChildIndex}`,
    });

  return (
    <div className={classes.triggerAdd}>
      <Trigger
        className={classes.trigger}
        icon={<AddOutlineIcon />}
        onClick={handleClick}
        {...DEFAULT_TRIGGER_PROPS_OUTLINED}
      >
        {triggerLabel}
      </Trigger>
    </div>
  );
};

NewFunclocTrigger.propTypes = {
  data: PropTypes.object.isRequired,
  entityClasses: PropTypes.array.isRequired,
  newChildIndex: PropTypes.number.isRequired,
  onEditNewFuncloc: PropTypes.func.isRequired,
  parentPath: PropTypes.string.isRequired,
};

export default NewFunclocTrigger;
