import React, { Fragment, useCallback, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FormControl from '@mui/material/FormControl';
import Toolbar from '@mui/material/Toolbar';
import TaskIcon from '@mui/icons-material/AssignmentOutlined';
import { makeStyles } from '@geomagic/core';
import { i18n } from '@geomagic/i18n';
import { ContentRoot } from '@geomagic/layout';
import Accordion from '@components/Accordion';
import Placeholder from '@components/Placeholder';
import PageSelect from './PageSelect';
import PageTrigger from './PageTrigger';
import Progress from './Progress';

const useStyles = makeStyles()(({ breakpoints, palette, spacing }) => ({
  toolbar: {
    alignItems: 'flex-start',
    backgroundColor: palette.background.paper,
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    display: 'flex',
    flexDirection: 'column-reverse',
    padding: 0,
    [breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    overflow: 'hidden',
    width: '100%',
  },
  progressContainer: {
    width: '100%',
  },
  titleContainer: {
    alignItems: 'center',
    display: 'flex',
    marginLeft: spacing(0.5),
    marginRight: spacing(0.5),
  },
  formControlContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: spacing(0, 1),
    marginBottom: spacing(0.5),
    overflow: 'hidden',
  },
  formControl: {
    overflow: 'hidden',
    margin: 0,
    marginTop: spacing(0.5),
  },
  formHelperText: {
    marginTop: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  placeholderContainer: {
    display: 'flex',
    height: '100%',
    width: '100%',
  },
  withAction: {
    paddingBottom: 64,
  },
}));

const scrollToPosition = ({ scrollRef, itemRef, behavior = 'smooth', isMobile }) => {
  const scrollNode = scrollRef?.current;
  const itemNode = itemRef?.current;
  const { offsetTop } = scrollNode || {};

  setTimeout(() => {
    if (itemNode) {
      const scrollTo = itemNode.offsetTop - offsetTop;
      if (isMobile) {
        scrollNode && scrollNode.scrollTo({ top: scrollTo, behavior });
      } else {
        scrollNode.scrollTop = scrollTo;
      }
    }
  }, 200);
};

const FormElementPage = (props) => {
  const {
    children,
    CloseAssignmentTriggerComponent,
    context,
    data,
    disableBack,
    disableForward,
    doc,
    entityClasses,
    forms,
    infoTrigger,
    onChangeMenu,
    onClickBack,
    onClickForward,
    onEditNewFuncloc,
    onRemoveFuncloc,
    path,
    templateForms,
  } = props;

  const scrollRef = useRef(null);

  const { isMobile } = context;
  const { id: formId } = data;

  const entity = doc.getPatchedEntity();
  const selectedIndex = forms.findIndex(({ id }) => id === formId);
  const isMultiForm = forms && forms.length > 1;
  const isEmptyForm = forms.length === 0;

  const { classes } = useStyles(props);
  const [activePanel, setActivePanel] = useState();

  /**
   *  EVENT HANDLER
   */

  const handleChangeSelect = (event, newValue) => {
    onChangeMenu(event, newValue);
  };

  const handleSelectPanel = useCallback(
    (event, isExpanded, panelId, itemRef) => {
      event.stopPropagation();
      setActivePanel(panelId);
      isExpanded && scrollToPosition({ scrollRef, itemRef, isMobile });
    },
    [isMobile]
  );

  /**
   *  EFFECTS
   */

  useEffect(() => {
    if (children && children.length > 0) {
      setActivePanel(children[0].props.id);
    } else {
      setActivePanel(null);
    }

    /* eslint-disable-next-line */
  }, [formId]);

  return (
    <Fragment>
      {!isEmptyForm && (
        <Toolbar className={classes.toolbar}>
          <div className={classes.progressContainer}>
            <Progress forms={[data]} variant="linear" />
          </div>
          <div className={classes.header}>
            {isMultiForm && <PageTrigger disabled={disableBack} onClick={onClickBack} variant="back" />}
            <div className={classes.formControlContainer}>
              <FormControl className={classes.formControl}>
                <div className={classes.titleContainer}>
                  <PageSelect
                    entityClasses={entityClasses}
                    forms={forms}
                    isMarkdown={!!infoTrigger}
                    onChange={handleChangeSelect}
                    onEditNewFuncloc={onEditNewFuncloc}
                    onRemoveFuncloc={onRemoveFuncloc}
                    path={path}
                    secondaryAction={infoTrigger}
                    templateForms={templateForms}
                    title={entity?.displayName}
                    value={selectedIndex}
                  />
                </div>
              </FormControl>
            </div>
            {isMultiForm && <PageTrigger disabled={disableForward} onClick={onClickForward} variant="forward" />}
          </div>
        </Toolbar>
      )}
      {children?.length > 0 ? (
        <ContentRoot
          className={classNames({ [classes.withAction]: !!CloseAssignmentTriggerComponent })}
          ref={scrollRef}
          scrollable
          scrollbarProps={{
            containerRef: (ref) => {
              scrollRef.current = ref;
            },
          }}
          withPadding={false}
          withCustomScrollbar={!isMobile}
        >
          <Accordion selectedId={activePanel} onSelect={handleSelectPanel}>
            {children}
          </Accordion>

          {CloseAssignmentTriggerComponent}
        </ContentRoot>
      ) : (
        <div className={classes.placeholderContainer}>
          <Placeholder
            icon={<TaskIcon />}
            title={i18n.t('assignment.placeholder.noCheckpoints.title')}
            subtitle={i18n.t('assignment.placeholder.noCheckpoints.subtitle')}
          />
        </div>
      )}
    </Fragment>
  );
};

FormElementPage.propTypes = {
  children: PropTypes.node,
  CloseAssignmentTriggerComponent: PropTypes.node,
  context: PropTypes.object.isRequired,
  data: PropTypes.object,
  disableBack: PropTypes.bool,
  disableForward: PropTypes.bool,
  doc: PropTypes.object,
  entityClasses: PropTypes.array.isRequired,
  forms: PropTypes.array.isRequired,
  infoTrigger: PropTypes.node,
  isMobile: PropTypes.bool,
  onChangeMenu: PropTypes.func.isRequired,
  onClickBack: PropTypes.func.isRequired,
  onClickForward: PropTypes.func.isRequired,
  onEditNewFuncloc: PropTypes.func.isRequired,
  onRemoveFuncloc: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  templateForms: PropTypes.array.isRequired,
};

export default FormElementPage;
