import React from 'react';
import PropTypes from 'prop-types';
import ListItemText from '@mui/material/ListItemText';

const AssignmentListText = (props) => {
  const { data } = props;
  const entity = data.getPatchedEntity();

  return (
    <ListItemText
      primary={entity?.displayName}
      secondary={entity?.shortDescription}
      primaryTypographyProps={{ noWrap: true, variant: 'body1' }}
      secondaryTypographyProps={{ noWrap: true, variant: 'body2' }}
    />
  );
};

AssignmentListText.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AssignmentListText;
