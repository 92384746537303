import isObject from 'lodash/isObject';
import { useSnackbar } from 'notistack';
import { i18n } from '@geomagic/i18n';
import { transformExtent } from 'ol/proj';

import useLoadingSnackbar from '@utils/useLoadingSnackbar';
import useShowPrompt from '@utils/useShowPrompt';

import addToCache from './addToCache';
import fetchUrl from './fetchUrl';
import getVectorTileUrls from './getVectorTileUrls';

const BASE_KEY = 'offlineMap';
const BASE_EXTENT = { epsgCode: 'EPSG:4326', extent: [5.98865807458, 47.3024876979, 15.0169958839, 54.983104153] };

const useOfflineMapActions = params => {
  const enqueueLoadingSnackbar = useLoadingSnackbar();
  const { enqueueSnackbar } = useSnackbar();
  const showPrompt = useShowPrompt();

  const { baseUrl, doc, features, srid, vectorTileLayers = [] } = params;
  const { uuid, offlineMap } = doc;
  const offlineMapId = `${BASE_KEY}_${uuid}`;

  /**
   *  EVENT HANDLER
   */

  const getTileUrl = async () => {
    let baseFetchResult;
    const urlsToCache = [];
    const cache = await window.caches.open(BASE_KEY);

    try {
      baseFetchResult = await fetchUrl(baseUrl);
    } catch (error) {
      throw new Error(i18n.t('offlineMap.notification.fetchError'));
    }

    if (baseFetchResult) {
      const { json: baseJson, response: baseResponse } = baseFetchResult;
      urlsToCache.push({ url: baseUrl, response: baseResponse });

      if (isObject(baseJson?.sources)) {
        const sources = baseJson.sources;
        const source = Object.keys(sources)[0];
        const { url: sourceUrl } = sources[source] || {};

        if (sourceUrl) {
          const { json: dataJson, response: sourceResponse } = await fetchUrl(sourceUrl);
          const tileUrl = dataJson?.tiles?.[0];
          urlsToCache.push({ url: sourceUrl, response: sourceResponse });

          for (let i = 0; i < urlsToCache.length; i++) {
            const { url, response } = urlsToCache[i];
            await cache.put(url, response);
          }

          return tileUrl;
        }
      }
    }
  };

  const handleCreate = async (finishedText = i18n.t('offlineMap.notification.created')) => {
    try {
      enqueueLoadingSnackbar({
        loadingText: i18n.t('offlineMap.notification.loadingCreate'),
        finishedText,
        func: handleFetch,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handlePromptDelete = () => {
    showPrompt({
      title: i18n.t('offlineMap.dialog.remove.title'),
      content: i18n.t('offlineMap.dialog.remove.content'),
      onOk: async () => {
        try {
          await handleDelete();
          enqueueSnackbar(i18n.t('offlineMap.notification.deleted'), {
            key: 'deleteOfflineMap',
            preventDuplicate: true,
            variant: 'success',
          });
        } catch (error) {
          console.log(error);
        }
      },
    });
  };

  const handleDelete = async () => {
    const { cacheIds = [] } = doc.offlineMap;

    for (let i = 0; i < cacheIds.length; i++) {
      const id = cacheIds[i];
      await window.caches.delete(id);
    }

    await doc.atomicPatch({ offlineMap: null });
  };

  const handleFetch = async () => {
    const cacheIds = [];
    const tileUrl = await getTileUrl();

    if (tileUrl) {
      const urls = getVectorTileUrls({ features, minZoomLevel: 7, maxZoomLevel: 14, url: tileUrl });
      await handleFetchBaseTiles(tileUrl);
      await addToCache(offlineMapId, urls);

      cacheIds.push(offlineMapId);
    }

    for (let i = 0; i < vectorTileLayers.length; i++) {
      const { id, sourceOptions } = vectorTileLayers[i];
      const mapId = `${offlineMapId}_${id}`;
      const url = sourceOptions?.url;

      if (url) {
        const urls = getVectorTileUrls({ features, minZoomLevel: 7, maxZoomLevel: 14, url });
        await handleFetchBaseTiles(url);
        await addToCache(mapId, urls);

        cacheIds.push(mapId);
      }
    }

    if (cacheIds.length > 0) {
      await doc.atomicPatch({ offlineMap: { cacheIds, modifiedOn: +new Date() } });
    } else {
      throw Error(i18n.t('offlineMap.notification.download.error'));
    }
  };

  const handleFetchBaseTiles = async url => {
    const { epsgCode, extent } = BASE_EXTENT;
    const transformedExtent = transformExtent(extent, epsgCode, `EPSG:${srid || 3857}`);

    const urls = getVectorTileUrls({
      extent: transformedExtent,
      minZoomLevel: 1,
      maxZoomLevel: 6,
      url,
    });

    await addToCache(BASE_KEY, urls);
  };

  const handleUpdate = async event => {
    await handleDelete();
    await handleCreate(i18n.t('offlineMap.notification.updated'));
  };

  const getMenuItems = () => {
    let menuItems;

    if (features?.length > 0 && (baseUrl || vectorTileLayers.length > 0)) {
      if (!!offlineMap) {
        menuItems = [
          {
            id: 'refresh',
            label: i18n.t('offlineMap.label.update'),
            onClick: handleUpdate,
          },
          {
            id: 'remove',
            label: i18n.t('offlineMap.label.delete'),
            onClick: handlePromptDelete,
            color: 'secondary',
          },
        ];
      } else {
        menuItems = [
          {
            id: 'create',
            label: i18n.t('offlineMap.label.create'),
            onClick: () => handleCreate(),
          },
        ];
      }
    }

    return menuItems;
  };

  return getMenuItems();
};

export default useOfflineMapActions;
