import getFormDocuments from '@components/FormComponent/utils/getFormDocuments';
import fetchAndStoreAttachments from '@database/fetchAndStoreAttachments';

const getAfter = async ({ added = [], updated = [] }) => {
  const affected = [...added, ...updated];

  for (let i = 0; i < affected.length; i++) {
    const databaseDoc = affected[i];
    const documents = getFormDocuments(databaseDoc?.entity?.forms);

    await fetchAndStoreAttachments(documents, databaseDoc);
  }
};

export default getAfter;
