import getDefaultModifiedOn from '@database/getDefaultModifiedOn';
import getAssignmentQueryConfigPrefetch from './assignment/getQueryConfigPrefetch';
import getDispatchQueryConfigPrefetch from './dispatch/getQueryConfigPrefetch';
import getAssignmentBeforeInsertDoc from './assignment/getBeforeInsertDoc';

const getFetchConfigPrefetch = () => {
  return [
    {
      collectionName: 'assignments',
      query: getAssignmentQueryConfigPrefetch(),
      getModifiedOn: getDefaultModifiedOn,
      beforeInsertDoc: getAssignmentBeforeInsertDoc,
    },
    {
      collectionName: 'dispatches',
      query: getDispatchQueryConfigPrefetch(),
      getModifiedOn: getDefaultModifiedOn,
    },
  ];
};

export default getFetchConfigPrefetch;
