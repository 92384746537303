/**
 * A component to display a task.
 *
 * The task can be claimed/unclaimed.
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import makeStyles from '@mui/styles/makeStyles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import UserIcon from '@mui/icons-material/AccountCircle';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import Typography from '@mui/material/Typography';

import { Trigger } from '@geomagic/core';
import { getEntityClass, getReference, isAllowed, isAllowedUPDATE } from '@geomagic/geonam';
import { i18n } from '@geomagic/i18n';

import { DEFAULT_TRIGGER_PROPS, PRIMARY_TRIGGER_PROPS } from '@consts';
import { CLASSNAME_USER } from '@graphql/consts';
import MutationClaimTask from '@graphql/mutations/MutationClaimTask';
import MutationDelegate from '@graphql/mutations/MutationDelegate';
import MutationDelegateTeam from '@graphql/mutations/MutationDelegateTeam';
import MutationDisclaimTask from '@graphql/mutations/MutationDisclaimTask';
import useIsMountedRef from '@geomagic/nam-react-core/utils/useIsMountedRef';
import useLoadingSnackbar from '@utils/useLoadingSnackbar';

import Action from './Action';
import DelegateTaskDialog from './DelegateTaskDialog';

const useStyles = makeStyles(({ palette, spacing }) => ({
  task: {
    padding: 0,
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginTop: spacing(1.5),
    marginBottom: spacing(0.5),
    '& > *': {
      width: '100%',
    },
  },
  avatar: {
    color: palette.primary.contrastText,
    backgroundColor: palette.primary.main,
  },
  trigger: {
    minWidth: 'auto',
    marginTop: spacing(),
  },
}));

const DELEGATE_FORM_ID = 'DELEGATE_TASKS';

const Task = (props) => {
  const classes = useStyles();
  const {
    checkFailedMessage,
    closeableErrorText,
    data,
    entityClasses,
    isCloseable,
    isLoading,
    isMobile,
    isOnline,
    onCheck = () => true,
    onSyncEntity,
    onUpdateEntity,
    processInstanceName,
    setLoading,
    task,
    user,
  } = props;

  const entity = data?.getPatchedEntity();

  const entityReference = getReference(entity);

  const entityClass = getEntityClass(entityClasses, entity.className);

  const isAllowedUpdate = isAllowedUPDATE(entity);
  const isMountedRef = useIsMountedRef();

  // Delegate permission only for assignments.
  const isAllowedDelegate =
    (isAllowed(entityClass, 'DELEGATE') || entity.className !== 'Assignment') && isAllowedUpdate;

  const { actions = [], assignee, assignedTeam, description, id: taskId, name: taskName, stage } = task;
  const assignedTeamName = assignedTeam?.name;

  const isUserAssigned = !!task.assignee;
  const assigneeText = task.assignee || i18n.t('placeholder.noUserAssigned');

  const isSelf = user?.loginName === assignee;
  const label = isSelf ? i18n.t('button.handOff') : i18n.t('button.takeOver');

  const enqueueLoadingSnackbar = useLoadingSnackbar();

  const { enqueueSnackbar } = useSnackbar();

  const [isDelegateDialogOpen, setDelegateDialogOpen] = useState(false);

  /**
   * GRAPHQL MUTATIONS
   */

  const [claimTask] = useMutation(MutationClaimTask);
  const [disclaimTask] = useMutation(MutationDisclaimTask);
  const [delegateTask] = useMutation(MutationDelegate);
  const [delegateTeam] = useMutation(MutationDelegateTeam);

  /**
   *  EVENT HANDLER
   */

  const syncEntity = async () => {
    await onSyncEntity(data);
  };

  const updateEntity = async () => {
    await onUpdateEntity(data);
  };

  const handleSubmitDelegateTask = async (values) => {
    const { assignee: newAssignee, message, team } = values;
    setLoading(true);

    const delegateUserPromise = () =>
      delegateTask({
        variables: {
          taskId,
          user: newAssignee ? { id: newAssignee.id, className: CLASSNAME_USER } : null,
          message,
        },
      });
    const delegateTeamPromise = () =>
      delegateTeam({
        variables: {
          taskId,
          team: team ? { id: team.id, className: 'Team' } : null,
        },
      });

    const execute = () =>
      syncEntity()
        .then(delegateUserPromise)
        .then(delegateTeamPromise)
        .then(updateEntity)
        .then(() => setDelegateDialogOpen(false))
        .finally(() => isMountedRef.current && setLoading(false));

    await enqueueLoadingSnackbar({
      loadingText: i18n.t('process.notification.delegateTask'),
      finishedText: i18n.t('process.notification.delegatedTask'),
      finishedVariant: 'success',
      func: execute,
    });
  };

  const handleDelegateTaskOpen = async () => {
    const isChecked = await onCheck(data);

    if (!isChecked) {
      enqueueSnackbar(checkFailedMessage, {
        key: 'openDrafts',
        preventDuplicate: true,
        variant: 'info',
      });
    } else {
      setDelegateDialogOpen(true);
    }
  };

  const handleClaimTask = async () => {
    setLoading(true);

    const execute = () =>
      claimTask({ variables: { taskId } })
        .then(updateEntity)
        .finally(() => setLoading(false));

    await enqueueLoadingSnackbar({
      loadingText: i18n.t('process.notification.claimTask'),
      finishedText: i18n.t('process.notification.taskClaimed'),
      finishedVariant: 'success',
      func: execute,
    });
  };

  const handleDisclaimTask = async () => {
    setLoading(true);

    const execute = () =>
      syncEntity()
        .then(() => disclaimTask({ variables: { taskId } }))
        .then(updateEntity)
        .finally(() => setLoading(false));

    await enqueueLoadingSnackbar({
      loadingText: i18n.t('process.notification.disclaimTask'),
      finishedText: i18n.t('process.notification.taskDisclaimed'),
      finishedVariant: 'success',
      func: execute,
    });
  };

  return (
    <List className={classes.task}>
      <ListItem disableGutters divider>
        <ListItemText
          primary={<Typography variant="body2">{taskName}</Typography>}
          secondary={processInstanceName + (stage ? ` (${stage})` : '')}
        />
      </ListItem>
      {assignedTeamName ? (
        <ListItem disableGutters divider>
          <ListItemIcon>
            <PeopleOutlineIcon />
          </ListItemIcon>
          <ListItemText primary={assignedTeamName} />
        </ListItem>
      ) : null}
      <ListItem disableGutters divider={actions.length > 0}>
        <ListItemIcon>
          <UserIcon color={isUserAssigned ? 'primary' : 'disabled'} />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography color={isUserAssigned ? 'textPrimary' : 'textSecondary'} noWrap variant="body2">
              {assigneeText}
            </Typography>
          }
        />
        <Trigger
          className={classes.trigger}
          onClick={isSelf ? handleDisclaimTask : handleClaimTask}
          disabled={!isOnline || isLoading}
          {...DEFAULT_TRIGGER_PROPS}
        >
          {label}
        </Trigger>
      </ListItem>
      <div className={classes.actions}>
        {actions.map((action) => {
          const { id: actionId } = action;
          return (
            <Action
              key={actionId}
              action={action}
              checkFailedMessage={checkFailedMessage}
              className={classes.trigger}
              closeableErrorText={closeableErrorText}
              data={data}
              isCloseable={isCloseable}
              isLoading={isLoading}
              isMobile={isMobile}
              isOnline={isOnline}
              isSelf={isSelf}
              onCheck={onCheck}
              setLoading={setLoading}
              syncEntity={syncEntity}
              task={task}
              updateEntity={updateEntity}
            />
          );
        })}
        {isAllowedDelegate && (
          <>
            <Trigger
              className={classes.trigger}
              disabled={!isOnline || isLoading}
              fullWidth
              onClick={handleDelegateTaskOpen}
              tooltip={description}
              {...PRIMARY_TRIGGER_PROPS}
            >
              {i18n.t('process.label.delgateTask')}
            </Trigger>
            <DelegateTaskDialog
              entityReference={entityReference}
              formId={DELEGATE_FORM_ID}
              isLoading={isLoading}
              isMobile={isMobile}
              open={isDelegateDialogOpen}
              onClose={() => setDelegateDialogOpen(false)}
              onSubmit={handleSubmitDelegateTask}
              task={task}
              user={user}
            />
          </>
        )}
      </div>
    </List>
  );
};

Task.propTypes = {
  checkFailedMessage: PropTypes.string,
  className: PropTypes.string,
  closeableErrorText: PropTypes.string,
  data: PropTypes.object.isRequired,
  entityClasses: PropTypes.array,
  isCloseable: PropTypes.bool,
  isLoading: PropTypes.bool,
  isMobile: PropTypes.bool,
  isOnline: PropTypes.bool,
  onCheck: PropTypes.func,
  onSyncEntity: PropTypes.func,
  onUpdateEntity: PropTypes.func,
  processInstanceName: PropTypes.string,
  setLoading: PropTypes.func.isRequired,
  task: PropTypes.shape({
    actions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        name: PropTypes.string,
        description: PropTypes.string,
        disabled: PropTypes.bool,
        commands: PropTypes.arrayOf(
          PropTypes.shape({
            confirmation: PropTypes.bool,
            command: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
          })
        ),
      })
    ),
    assignee: PropTypes.string,
    assignedTeam: PropTypes.object,
    description: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string,
    priority: PropTypes.string,
    stage: PropTypes.string,
  }),
  user: PropTypes.object.isRequired,
};

export default Task;
