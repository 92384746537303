/**
 * A component to add a checklist item.
 *
 * The item can be added by pressing the icon button or the enter key.
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/AddOutlined';
import { Trigger } from '@geomagic/core';
import { i18n } from '@geomagic/i18n';

const AddChecklistItem = (props) => {
  const { handleAddNewItem } = props;

  const [value, setValue] = useState('');

  /**
   *  EVENT HANDLER
   */

  const handleAddValue = () => {
    if (value.length > 0) {
      handleAddNewItem(value);
      setValue('');
    }
  };

  const handleKeyPressAddOption = (event) => {
    if (event.keyCode === 13) {
      handleAddValue();
    }
  };

  /**
   *  COMPONENTS
   */

  const EndAdornmentComponent = (
    <Tooltip title={i18n.t('checklist.tooltip.addNewItem')}>
      <span>
        <Trigger disabled={value.length === 0} icon={<AddIcon />} onClick={handleAddValue} />
      </span>
    </Tooltip>
  );

  return (
    <TextField
      fullWidth
      InputProps={{
        endAdornment: EndAdornmentComponent,
      }}
      onChange={(event) => setValue(event.target.value)}
      onKeyDown={handleKeyPressAddOption}
      placeholder={i18n.t('checklist.placeholder.newItem')}
      sx={{ px: 2, maxWidth: '100%' }}
      value={value}
      variant="outlined"
    />
  );
};

AddChecklistItem.propTypes = {
  handleAddNewItem: PropTypes.func.isRequired,
};

export default AddChecklistItem;
