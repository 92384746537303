import gql from 'graphql-tag';

const QuerySSOSettings = gql`
  query QuerySystemInformation {
    systemInformation {
      ssoSettings {
        ... on JwtSettings {
          redirectUrl
          tokenParamName
        }
      }
    }
  }
`;

export default QuerySSOSettings;
