import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@geomagic/core';
import Progress from '@components/FormComponent/Progress';

const useStyles = makeStyles()(() => ({
  root: {
    display: 'flex',
  },
}));

const AssignmentProgress = (props) => {
  const { data } = props;
  const entity = data.getPatchedEntity();
  const { forms } = entity;
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <Progress forms={forms} variant="circular" />
    </div>
  );
};

AssignmentProgress.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AssignmentProgress;
