import flattenDeepByKey from '@utils/flattenDeepByKey';
import { FORM_COMPONENT_CHILDREN_KEY } from '../consts';

const findParent = (id, elements) => {
  return elements.find(({ children }) => children?.some(({ id: childrenId }) => childrenId === id));
};

const isDefectStatusSelected = (parent) => {
  return parent.children.some(({ statusValue }) => statusValue?.isDefectStatus);
};

const isAnyParentUsedAsTemplate = (parent, elements) => {
  if (parent.usedAsTemplate) return true;
  const grandparent = findParent(parent.id, elements);
  if (grandparent) return isAnyParentUsedAsTemplate(grandparent, elements);
  return false;
};

const isMandatoryElement = (id, elements, displayedIfDefect) => {
  const parent = findParent(id, elements);
  return !isAnyParentUsedAsTemplate(parent, elements) && (!displayedIfDefect || isDefectStatusSelected(parent));
};

/**
 *  Returns all mandatory elements of given forms.
 * @param {Array} forms Can be pages (forms), blocks, rows
 * @returns {Array}
 */
const getMandatoryElements = (forms = []) => {
  if (forms.length === 0) return [];

  const elements = flattenDeepByKey(forms, FORM_COMPONENT_CHILDREN_KEY);
  const mandatoryElements = elements.filter(
    ({ displayedIfDefect, id, mandatory }) => mandatory && isMandatoryElement(id, elements, displayedIfDefect)
  );
  return mandatoryElements;
};

export default getMandatoryElements;
