import gql from 'graphql-tag';

import FragmentFormElementBlock from '../fragments/FragmentFormElementBlock';
import FragmentFormElementRow from '../fragments/FragmentFormElementRow';

const MutationUpdateFormElement = gql`
  ${FragmentFormElementBlock}
  ${FragmentFormElementRow}

  mutation MutationUpdateFormElement($formElement: FormElementInput!) {
    updateFormElement(formElement: $formElement) {
      id
      name
      ...FormElementBlock
      ...FormElementRow
    }
  }
`;

export default MutationUpdateFormElement;
