import groupBy from 'lodash/groupBy';

import { i18n } from '@geomagic/i18n';

const getGroups = ({ items, componentProps }) => {
  const groups = [];

  if (items && items.length > 0) {
    const { drafts, dispatches } = groupBy(items, ({ draft }) => (!!draft ? 'drafts' : 'dispatches'));

    if (drafts) {
      groups.push({
        id: 'dispatchDrafts',
        label: i18n.t('dispatch.label.draftAmount', {
          variables: { amount: drafts.length || '0' },
        }),
        items: drafts,
        ...componentProps,
      });
    }

    if (dispatches) {
      groups.push({
        id: 'dispatches',
        label: i18n.t('dispatch.label.dispatchAmount', {
          variables: { amount: dispatches.length || '0' },
        }),
        items: dispatches,
        ...componentProps,
      });
    }
  }

  return groups;
};

export default getGroups;
