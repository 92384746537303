const fetchUrl = async url => {
  const response = await fetch(url);

  if (response.status === 200) {
    const clonedResponse = response.clone();
    const json = await clonedResponse.json();
    return { json, response };
  } else {
    console.log(`${url}`, response.text && response.text());
  }
};

export default fetchUrl;
