import React from 'react';
import PropTypes from 'prop-types';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import dayjs from 'dayjs';

const DateTimeInput = (props) => {
  const { attributeType, dateFormatObject, error, updateField, value = null, label, isMobile } = props;

  const { dataType, readOnly, mandatory } = attributeType;

  const isTime = dataType === 'TIMESTAMP';
  const { format, minDate, maxDate } = dateFormatObject;

  const onChange = (newValue) => {
    updateField(newValue ? newValue.valueOf() : undefined);
  };

  const Component = !isMobile
    ? isTime
      ? DesktopDateTimePicker
      : DesktopDatePicker
    : isTime
    ? MobileDateTimePicker
    : MobileDatePicker;

  return (
    <Component
      fullWidth
      disabled={readOnly}
      error={error}
      format={format}
      slotProps={{
        actionBar: {
          actions: isMobile ? ['clear', 'accept'] : ['clear'],
        },
      }}
      label={label}
      minDate={minDate}
      maxDate={maxDate}
      onChange={onChange}
      onSubmit={onChange}
      required={mandatory}
      value={value ? dayjs(value) : null}
    />
  );
};

DateTimeInput.propTypes = {
  attributeType: PropTypes.object,
  dateFormatObject: PropTypes.object,
  error: PropTypes.bool,
  isMobile: PropTypes.bool,
  label: PropTypes.string.isRequired,
  updateField: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
};

export default DateTimeInput;
