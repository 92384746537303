import uniqBy from 'lodash/uniqBy';
import { ENTITY_UNIQ_KEY } from './consts';

const updateAdditionalEntities = additionalEntities => (collectionName, values) => {
  if (additionalEntities[collectionName] && values) {
    const newValues = [...additionalEntities[collectionName], ...values];
    additionalEntities[collectionName] = uniqBy(newValues, ENTITY_UNIQ_KEY);
  }
};

export default updateAdditionalEntities;
