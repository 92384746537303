import gql from 'graphql-tag';

import FragmentAttributeType from '../fragments/FragmentAttributeType';
import FragmentEntityType from '../fragments/FragmentEntityType';

const QueryEntityClasses = gql`
  ${FragmentAttributeType}
  ${FragmentEntityType}

  query QueryEntityClasses($classNames: [String!]) {
    entityClasses(classNames: $classNames) {
      characteristic {
        hierarchical
        supportsAccessPermissions
      }
      className
      name
      namePlural
      entityTypes {
        ...EntityType
      }
      attributeTypes {
        ...AttributeType
      }
      relationTypes {
        id
        name
        targetEntityClassName
        cardinality
        code
        mandatory
        typeRestrictions {
          sourceEntityTypeId
          targetEntityTypeIds
        }
        preselected
        readOnly
        sourcePermissionType
        targetPermissionType
      }
      permissionTypes
    }
  }
`;

export default QueryEntityClasses;
