/**
 * A convienent hook to react to back button clicks/touches.
 */

import { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const useBackButton = () => {
  const [backActions, setBackActions] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const currentPathname = location.pathname;
  const backButtonListener = useRef(null);

  const addBackAction = useCallback(
    action => {
      setBackActions(prevActions => [...prevActions, action]);
      history.push(currentPathname);
    },
    [currentPathname, history]
  );

  const resetBackActions = () => {
    setBackActions([]);
  };

  useEffect(() => {
    if (backActions.length > 0) {
      const lastAction = backActions[backActions.length - 1];
      backButtonListener.current = history.listen((_, action) => {
        if (action === 'POP') {
          lastAction();
          setBackActions(prevActions => prevActions.slice(0, -1));
        }
      });
      return () => {
        backButtonListener.current();
        backButtonListener.current = null;
      };
    }
  }, [backActions, history]);

  return {
    addBackAction,
    resetBackActions,
  };
};

export default useBackButton;
