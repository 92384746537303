import dayjs from 'dayjs';
import { TIMESTAMP_DISPLAY_FORMAT } from '@geomagic/geonam';
import { DATE_DISPLAY_FORMAT } from '@consts';

import convertDateFormat from './convertDateFormat';

/**
 * Get picker props from an entity
 * @param {object} attributeType - GeoNAM attribute type
 * @param {object} entity - GeoNAM entity
 * @returns {object}
 */

const getDateFormatObject = (attributeType, entity) => {
  const { options = {} } = attributeType;
  const locale = dayjs.locale();
  const { pattern, minRelDays, maxRelDays } = options;
  const format = pattern
    ? convertDateFormat(pattern)
    : attributeType.dataType === 'DATE'
    ? locale
      ? dayjs.localeData().longDateFormat('L')
      : DATE_DISPLAY_FORMAT
    : locale
    ? dayjs.localeData().longDateFormat('LLL')
    : TIMESTAMP_DISPLAY_FORMAT;
  return {
    format,
    ...(minRelDays && {
      minDate: entity ? dayjs(entity.auditInfo.createdOn).add(minRelDays, 'd') : dayjs().add(minRelDays, 'd'),
    }),
    ...(maxRelDays && {
      maxDate: entity ? dayjs(entity.auditInfo.createdOn).add(maxRelDays, 'd') : dayjs().add(maxRelDays, 'd'),
    }),
  };
};

export default getDateFormatObject;
