/**
 * The Dialog component returns a customized dialog window.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AppBar from '@mui/material/AppBar';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { makeStyles, Trigger } from '@geomagic/core';
import { i18n } from '@geomagic/i18n';
import { ContentRoot } from '@geomagic/layout';
import { MOBILE_TRIGGER_SIZE, SECONDARY_TRIGGER_PROPS } from '@consts';

const useStyles = makeStyles()(({ palette, spacing }) => ({
  action: {
    alignItems: 'center',
    display: 'flex',
  },
  appBar: {
    position: 'relative',
  },
  dialog: { display: 'flex', flex: 1, flexDirection: 'column', overflow: 'hidden' },
  dialogActions: {
    borderTop: `1px solid ${palette.divider}`,
  },
  dialogContent: {
    padding: '0 !important',
  },
  dialogContentFullScreen: {
    display: 'flex',
    height: '100vh',
  },
  dialogTitle: {
    borderBottom: `1px solid ${palette.divider}`,
  },
  paper: {
    flex: 1,
  },
  title: {
    display: 'flex',
    flex: 1,
    overflow: 'hidden',
    paddingRight: spacing(),
  },
  titleText: {
    cursor: 'pointer',
    fontWeight: 'bold',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: 56,
    paddingRight: spacing(),
    paddingLeft: 0,
  },
  trigger: {},
  triggerIcon: {
    height: MOBILE_TRIGGER_SIZE,
    width: MOBILE_TRIGGER_SIZE,
  },
}));

const StackedDialog = (props) => {
  const { classes } = useStyles(props);
  const {
    actions,
    content,
    handleClose,
    isFullscreen = true,
    maxWidth = 'sm',
    open = false,
    withPadding = false,
    title,
    ...dialogProps
  } = props;

  /**
   *  COMPONENTS
   */

  const CloseTrigger = isFullscreen ? (
    <div className={classes.action}>
      <Trigger className={classes.triggerIcon} color="inherit" onClick={handleClose} icon={<ChevronLeftIcon />} />
    </div>
  ) : (
    <Trigger className={classes.trigger} onClick={handleClose} {...SECONDARY_TRIGGER_PROPS}>
      {i18n.t('button.close')}
    </Trigger>
  );

  const Actions = !isFullscreen ? (
    <DialogActions className={classes.dialogActions}>{actions ? actions : CloseTrigger}</DialogActions>
  ) : actions ? (
    <DialogActions className={classes.dialogActions}>{actions}</DialogActions>
  ) : null;

  const Header = isFullscreen ? (
    <AppBar className={classes.appBar} enableColorOnDark>
      <Toolbar className={classes.toolbar}>
        {CloseTrigger}
        <div className={classes.title}>
          <Typography className={classes.titleText} color="inherit" variant="body1" noWrap>
            {title}
          </Typography>
        </div>
      </Toolbar>
    </AppBar>
  ) : (
    <DialogTitle className={classes.dialogTitle}>{title}</DialogTitle>
  );

  return (
    <Dialog
      className={classes.dialog}
      maxWidth={maxWidth}
      onClose={handleClose}
      onClick={(evt) => {
        evt.stopPropagation();
      }}
      open={open}
      PaperProps={{ className: classes.paper }}
      fullScreen={isFullscreen}
      {...dialogProps}
    >
      {Header}
      {content ? (
        <ContentRoot
          className={classNames({
            [classes.dialogContentFullScreen]: isFullscreen,
          })}
          withPadding={withPadding || !isFullscreen}
          withCustomScrollbar
        >
          <DialogContent className={classes.dialogContent}>{content}</DialogContent>
        </ContentRoot>
      ) : null}
      {Actions}
    </Dialog>
  );
};

StackedDialog.propTypes = {
  actions: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
  handleClose: PropTypes.func.isRequired,
  isFullscreen: PropTypes.bool,
  maxWidth: PropTypes.string,
  open: PropTypes.bool.isRequired,
  withPadding: PropTypes.bool,
  title: PropTypes.string,
};

export default StackedDialog;
