import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import ListSubheader from '@mui/material/ListSubheader';
import { makeStyles } from '@geomagic/core';
import { i18n } from '@geomagic/i18n';
import { ContentRoot } from '@geomagic/layout';
import { getLayerStoreInstance, MINIMUM_CONFIG } from '@geomagic/map';
import { transformLayers } from '@components/Map';
import LayerTreeView from '@components/Map/LayerTreeView';
import SelectBackgroundLayer from '@components/Map/SelectBackgroundLayer';

const useStyles = makeStyles()(({ palette, spacing }) => {
  return {
    '@global': {
      '.MuiInputBase-root': {
        minHeight: 40,
      },
    },
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },
    layerTreeViewRoot: {
      margin: 0,
      boxShadow: 'none',
      width: '100%',
    },
    layerTreeViewContent: {
      padding: 0,
    },
  };
});

const MapPreferences = (props) => {
  const { classes } = useStyles(props);
  const { className, isMobile, mapProps } = props;
  const { mapRef, view } = mapProps;
  const layerSelectionStore = getLayerStoreInstance(MINIMUM_CONFIG.mapId);
  const [layers] = useState(() => transformLayers(view, layerSelectionStore));

  const map = mapRef.current.map;

  return (
    <ContentRoot
      className={classNames(classes.root, className)}
      scrollable
      withPadding={false}
      withCustomScrollbar={!isMobile}
    >
      <ListSubheader>{i18n.t('map.default.background')}</ListSubheader>
      <Divider />
      <Box sx={{ padding: 1 }}>
        <SelectBackgroundLayer layers={layers} map={map} />
      </Box>
      <Divider />
      <ListSubheader>{i18n.t('map.default.layers')}</ListSubheader>
      <Divider />
      <Box sx={{ padding: 1 }}>
        <LayerTreeView
          classes={{ root: classes.layerTreeViewRoot, content: classes.layerTreeViewContent }}
          layers={layers}
          map={map}
        />
      </Box>
    </ContentRoot>
  );
};

MapPreferences.propTypes = {
  className: PropTypes.string,
  isMobile: PropTypes.bool,
  mapProps: PropTypes.object.isRequired,
};

export default MapPreferences;
