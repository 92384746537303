import gql from 'graphql-tag';

import FragmentDispatch from '../fragments/FragmentDispatch';

const QueryDispatch = gql`
  ${FragmentDispatch}

  query QueryFindEntity(
    $entityReference: EntityReferenceInput!
    $srid: Int
    $withHistory: Boolean = false
    $worldId: NID
  ) {
    entity: findEntity(entityReference: $entityReference) {
      ...Dispatch
    }
  }
`;

export default QueryDispatch;
