import gql from 'graphql-tag';

const FragmentEntityReference = gql`
  fragment EntityReference on Entity {
    id
    className
    displayName
  }
`;

export default FragmentEntityReference;
