import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

const DEBOUNCE_TIME_MS = 200;

const FormElementFieldInput = (props) => {
  const { context, data, path } = props;
  const { isReadOnly, onUpdate } = context;
  const { mandatory, name, unit, value } = data;

  const [inputValue, setInputValue] = useState(value || '');

  /* EVENT HANDLER */

  // Debounce useCallback
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedValueChange = useCallback(
    debounce((newValue) => {
      onUpdate({ value: newValue || null }, path, data);
    }, DEBOUNCE_TIME_MS),
    [data, path, onUpdate]
  );

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    if (value !== newValue) {
      debouncedValueChange(newValue);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        mx: 1,
        minWidth: 120,
        width: {
          mobile: '100%',
          tablet: 'auto',
        },
        flex: {
          mobile: 'initial',
          tablet: 1,
        },
        marginTop: {
          mobile: 1.5,
          tablet: 1,
        },
        marginBottom: {
          mobile: 1,
          desktop: 0.5,
        },
        ...(isReadOnly && { pointerEvents: 'none' }),
      }}
    >
      <TextField
        fullWidth
        sx={{
          '.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'primary.main !important',
          },
          ...(mandatory &&
            !value && {
              '.MuiOutlinedInput-notchedOutline': {
                border: '2px solid',
                borderColor: 'warning.main',
              },
            }),
        }}
        label={name}
        margin="none"
        onChange={handleInputChange}
        required={mandatory}
        variant="outlined"
        value={inputValue}
        {...(unit && { InputProps: { endAdornment: <InputAdornment position="end">{unit}</InputAdornment> } })}
      />
    </Box>
  );
};

FormElementFieldInput.propTypes = {
  context: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  doc: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
};

export default FormElementFieldInput;
