import React from 'react';
import PropTypes from 'prop-types';
import { i18n } from '@geomagic/i18n';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import TooltipText from '@geomagic/nam-react-core/components/TooltipText';

const LayerListItem = (props) => {
  const { layer, level, map, isState, onHandleLayerClick } = props;

  const currentMapResolution = map.getView().getResolution();
  const layerMinResolution = layer.getMinResolution();
  const layerMaxResolution = layer.getMaxResolution();

  const isErrorTreeLayerLoading = layer.getSource() && layer.getSource().getState() !== 'ready';
  const isZoomOutNeeded = layerMinResolution && currentMapResolution < layerMinResolution;
  const isZoomInNeeded = layerMaxResolution && currentMapResolution > layerMaxResolution;

  const title = layer.get('layerName');

  const handleLayerClick = () => onHandleLayerClick(layer);

  const getErrorMessage = () => {
    const errors = [];
    if (isErrorTreeLayerLoading) {
      errors.push(i18n.t('map.label.errorTreeLayerLoading'));
    }
    if (isZoomOutNeeded) {
      errors.push(i18n.t('map.label.errorZoomLevelBoundsZoomOut'));
    }
    if (isZoomInNeeded) {
      errors.push(i18n.t('map.label.errorZoomLevelBoundsZoomIn'));
    }
    return errors.join(' ');
  };

  return (
    <ListItem
      disableGutters
      divider
      secondaryAction={<Checkbox color="primary" checked={isState === 1} onClick={handleLayerClick} />}
      sx={{
        border: 0,
        paddingLeft: 0,
        marginLeft: level,
        width: (theme) => `calc(100% - ${theme.spacing(1)})`,
      }}
    >
      <ListItemButton onClick={handleLayerClick}>
        <ListItemText
          primary={<TooltipText content={title} typographyProps={{ variant: 'body1' }} />}
          secondary={
            <>
              <Typography component="span" variant="inherit" color="error" noWrap>
                {layer.get('error')}
              </Typography>
              {isErrorTreeLayerLoading || isZoomOutNeeded || isZoomInNeeded ? (
                <Typography component="span" variant="inherit" color="textSecondary">
                  {getErrorMessage()}
                </Typography>
              ) : null}
            </>
          }
        />
      </ListItemButton>
    </ListItem>
  );
};

LayerListItem.propTypes = {
  isState: PropTypes.number.isRequired,
  layer: PropTypes.object.isRequired,
  level: PropTypes.number.isRequired,
  map: PropTypes.object.isRequired,
  onHandleLayerClick: PropTypes.func.isRequired,
};

export default LayerListItem;
