import { getBackendInfo } from '@geomagic/core';
import { DEFAULT_ENVIRONMENT } from '@graphql/consts';

/**
 * Get GeoNAM document download url
 * @param {string} downloadPath
 * @returns {string}
 */

const getDownloadUrl = downloadPath => {
  const { backend } = getBackendInfo(DEFAULT_ENVIRONMENT);
  const url = `${backend}/${downloadPath}`;

  return url;
};

export default getDownloadUrl;
