import { makeStyles, showPrompt as showCustomPrompt } from '@geomagic/core';

const useStyles = makeStyles()(({ palette, spacing, typography }) => ({
  root: {
    '& > .MuiDialogTitle-root > h3': {
      alignItems: 'center',
      background: palette.background.paper,
      boxShadow: 'none',
      display: 'flex',
      height: 56,
      padding: spacing(0, 2),
      '& > div': {
        color: palette.text.primary,
        fontSize: typography.pxToRem(16),
        fontWeight: 'bold',
        margin: 0,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
      '& > div:first-of-type, & > div:last-child': {
        display: 'none',
      },
    },
    '& > .MuiDialogContent-root': {
      background: palette.background.paper,
      borderBottom: `1px solid ${palette.divider}`,
      borderTop: `1px solid ${palette.divider}`,
      margin: '0 !important',
      padding: spacing(1, 2),
      '& > .MuiTypography-root': {
        margin: 0,
      },
    },
    '& > .MuiDialogActions-root': {
      background: palette.background.paper,
    },
  },
}));

const useShowPrompt = () => {
  const { classes } = useStyles();

  const handleShowPrompt = ({ content, onOk, title }) => {
    showCustomPrompt({
      fullWidth: true,
      isOk: onOk,
      maxWidth: 'sm',
      PaperProps: { classes: { root: classes.root } },
      text: content,
      title,
    });
  };

  return handleShowPrompt;
};

export default useShowPrompt;
