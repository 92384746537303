import gql from 'graphql-tag';

const FragmentUser = gql`
  fragment User on User {
    id
    loginName
    fullName
  }
`;

export default FragmentUser;
