import dayjs from 'dayjs';

const checkFileNameExists = (documents = [], fileName = '') =>
  documents.some((document) => document.displayName.toLowerCase() === fileName.toLowerCase());

/**
 * Get a unique file name with the current timestamp if the file was just created
 * otherwise take the old file name.
 * @param {object} file
 * @param {Array} documents
 * @returns
 */

const getNewFileName = (file, documents) => {
  const { lastModified, name: fileName } = file;
  const fileExtension = '.' + fileName.split('.').pop();
  const fileNameWithoutExtension = fileName.slice(0, -fileExtension.length);
  const now = dayjs();
  // Image is created in last minute.
  const isFileJustCreated = now.valueOf() - lastModified < 60000;
  const timestamp = now.format('YYYY-MM-DD_HH-mm-ss');

  const newFileNameWithoutExtension = isFileJustCreated ? timestamp : fileNameWithoutExtension;
  let newFileName = newFileNameWithoutExtension + fileExtension;

  let isFileNameExisting = checkFileNameExists(documents, newFileName);
  let fileNameCount = 1;

  while (isFileNameExisting) {
    newFileName = newFileNameWithoutExtension + '_' + fileNameCount + fileExtension;
    isFileNameExisting = checkFileNameExists(documents, newFileName);
    fileNameCount++;
  }
  return newFileName;
};

export default getNewFileName;
