import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import NotFoundIcon from '@mui/icons-material/SentimentDissatisfiedOutlined';
import { makeStyles } from '@geomagic/core';
import { i18n } from '@geomagic/i18n';
import Placeholder from '@components/Placeholder';
import { PRIMARY_TRIGGER_PROPS } from '@consts';

const useStyles = makeStyles()(() => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    width: '100%',
  },
}));

const NotFound = (props) => {
  const history = useHistory();
  const { classes } = useStyles();

  /**
   * EVENT HANDLER
   */

  const handleClickRedirect = () => {
    history && history.push('/');
  };

  /**
   * COMPONENTS
   */

  const action = (
    <Button onClick={handleClickRedirect} {...PRIMARY_TRIGGER_PROPS}>
      {i18n.t('button.backToHome')}
    </Button>
  );

  return (
    <div className={classes.root}>
      <Placeholder
        action={action}
        icon={<NotFoundIcon />}
        subtitle={i18n.t('placeholder.routeNotFound.subtitle')}
        title={i18n.t('placeholder.routeNotFound.title')}
      />
    </div>
  );
};

export default NotFound;
