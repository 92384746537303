import { i18n } from '@geomagic/i18n';

const getSchemaAndUI = () => ({
  schema: {
    type: 'object',
    required: ['username', 'password'],
    properties: {
      username: {
        title: i18n.t('label.username'),
        type: 'string',
      },
      password: {
        title: i18n.t('label.password'),
        type: 'string',
      },
    },
  },
  ui: {
    username: {
      InputLabelProps: { shrink: true },
    },
    password: {
      title: i18n.t('label.password'),
      type: 'password',
      InputLabelProps: { shrink: true },
    },
  },
});

export default getSchemaAndUI;
