import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';

/**
 * Get an object with styles for geometry types
 * @param {object} params
 * @returns {object}
 */

const getFeatureStyle = (params = {}) => {
  const { backgroundColor, borderColor = '#ffffff', borderWidth = 2, radius = 8, zIndex = 3 } = params;

  return new Style({
    image: new CircleStyle({
      radius,
      fill: new Fill({ color: backgroundColor }),
      stroke: new Stroke({ color: borderColor, width: borderWidth }),
    }),
    zIndex,
  });
};

export default getFeatureStyle;
