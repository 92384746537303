import flatten from 'lodash/flatten';

/**
 * Get flat coordinates from a geometry
 * @param {object} geometry
 * @param {Array.<number>} geometry.coordinates - Coordinates
 * @param {string} params.type - Geometry type
 * @returns {Array.<number>}
 */

const flattenCoordinates = (geometry) => {
  let flatCoordinates;
  const type = geometry.type;
  const coordinates = geometry.coordinates;

  switch (type) {
    case 'Polygon':
      flatCoordinates = flatten(flatten(coordinates));
      break;
    case 'LineString':
      flatCoordinates = flatten(coordinates);
      break;
    default:
      flatCoordinates = coordinates;
  }

  return flatCoordinates;
};

export default flattenCoordinates;
