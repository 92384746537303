/**
 * Returns the amount of tasks in a processInstances array
 * @param {Array} processInstances
 * @returns {number}
 */

const getTasksAmount = (processInstances = []) => {
  return processInstances.reduce((acc, processInstance) => {
    const { tasks } = processInstance;
    acc = acc + tasks.length;
    return acc;
  }, 0);
};

export default getTasksAmount;
