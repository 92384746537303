import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@geomagic/core';
import ReadOnlyField from '@components/ReadOnlyField';

const useStyles = makeStyles()(({ breakpoints, spacing }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    margin: spacing(1, 0.5),
    minWidth: 120,
    [breakpoints.down('sm')]: {
      flex: 'initial',
      marginTop: spacing(1.5),
      width: '100%',
    },
    [breakpoints.down('md')]: {
      marginBottom: spacing(1),
    },
  },
}));

const FormElementText = (props) => {
  const { data } = props;
  const { name, value } = data;

  const { classes } = useStyles(props);
  return (
    <div className={classes.root}>
      <ReadOnlyField title={name} value={value} />
    </div>
  );
};

FormElementText.propTypes = {
  data: PropTypes.object.isRequired,
};

export default FormElementText;
