import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import uuidv4 from 'uuid/v4';
import isArray from 'lodash/isArray';
import { XMLParser } from 'fast-xml-parser';
import { i18n } from '@geomagic/i18n';
import { FileUpload } from '@geomagic/files';
import { PRIMARY_TRIGGER_PROPS } from '@consts';
import useLoadingSnackbar from '@utils/useLoadingSnackbar';

const getLocationRecordsFromGPX = (gpxFile) => {
  const parser = new XMLParser({
    ignoreAttributes: false,
    attributeNamePrefix: 'att_',
  });
  const jObj = parser.parse(gpxFile);
  const actualTime = +new Date();
  const geolocationPositions = jObj.gpx.trk.trkseg.trkpt.map((pt) => {
    return {
      coords: {
        accuracy: 1,
        altitude: Number(pt.ele),
        latitude: Number(pt.att_lat),
        longitude: Number(pt.att_lon),
      },
      timestamp: pt.time ? +new Date(pt.time) : actualTime,
    };
  });
  return {
    id: uuidv4(),
    geolocationPositions,
  };
};

const LocationRecordUpload = (props) => {
  const { accept = ['.gpx'], className, icon, label, onUpload, triggerProps } = props;

  const enqueueLoadingSnackbar = useLoadingSnackbar();

  /**
   *  EVENT HANDLER
   */

  const handleAdd = async (event) => {
    const execute = async () => {
      const reader = new FileReader();
      reader.onload = (onLoadEvent) => {
        onUpload(getLocationRecordsFromGPX(onLoadEvent.target.result));
      };
      reader.onerror = (onErrorEvent) => alert(onErrorEvent.target.error.name);
      reader.readAsText(event.target.files[0]);
    };

    await enqueueLoadingSnackbar({
      loadingText: i18n.t('notification.uploadGPX'),
      finishedText: i18n.t('notification.uploadedGPX'),
      finishedVariant: 'success',
      func: execute,
    });
  };

  return (
    <Fragment>
      <FileUpload
        accept={isArray(accept) ? accept.join(',') : accept}
        className={className}
        color="primary"
        icon={icon}
        multiple={false}
        onChange={handleAdd}
        {...PRIMARY_TRIGGER_PROPS}
        {...triggerProps}
      >
        {label}
      </FileUpload>
    </Fragment>
  );
};

LocationRecordUpload.propTypes = {
  accept: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  className: PropTypes.string,
  icon: PropTypes.node,
  label: PropTypes.node,
  onUpload: PropTypes.func.isRequired,
  triggerProps: PropTypes.object,
};

export default LocationRecordUpload;
