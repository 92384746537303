import { omitDeep } from '@patomation/omit-deep';
import getExtendedEntity from './getExtendedEntity';

const getUpdatedDoc = async (client, query, entityReference, mapProps) => {
  const { srid, world } = mapProps;
  const mapVariables = { srid, worldId: world?.id };

  const remoteEntity = await client.query({
    query,
    variables: { entityReference, ...mapVariables },
    fetchPolicy: 'no-cache',
  });
  const entity = omitDeep(remoteEntity?.data?.entity, '__typename');
  const extendedEntity = getExtendedEntity(entity, mapProps);

  return extendedEntity;
};

export default getUpdatedDoc;
