/**
 * Displays a data item in a masonry/fitting style corresponding to the width.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

const EXPAND_ICON_WIDTH = 24;

const getRows = (field = {}) => {
  const { props } = field;
  return props?.options?.rows || props?.fieldUI?.rows;
};

const getXS = (isFullWidth, currentWidth, breakpoint) => {
  if (isFullWidth || currentWidth < breakpoint.sm) {
    return 12;
  } else if (currentWidth < breakpoint.md) {
    return 6;
  } else if (currentWidth < breakpoint.lg) {
    return 4;
  } else {
    return 3;
  }
};

const DataItem = (props) => {
  const { field, getFieldAction, isHidden, width = 0 } = props;

  const { breakpoints } = useTheme();
  const { values: breakpoint } = breakpoints;

  const rows = getRows(field);
  const isFullWidth = rows && rows > 0;

  return (
    <Grid
      sx={{
        alignSelf: 'center',
        position: 'relative',
        width: '100%',
        ...(isHidden ? {} : {}),
      }}
      item
      xs={getXS(isFullWidth, width - EXPAND_ICON_WIDTH, breakpoint)}
    >
      {!!getFieldAction ? (
        <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'row', width: '100%' }}>
          {field}
          {getFieldAction(field)}
        </Box>
      ) : (
        field
      )}
    </Grid>
  );
};

DataItem.propTypes = {
  field: PropTypes.object.isRequired,
  getFieldAction: PropTypes.func,
  isHidden: PropTypes.bool,
  width: PropTypes.number,
};

export default DataItem;
