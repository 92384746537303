import gql from 'graphql-tag';

const FragmentAttributeTypeDependency = gql`
  fragment AttributeTypeDependency on AttributeTypeDependency {
    attributeTypeId
    enumValueIds
    operator
    restrictEnumValues
    value
    visibility
  }
`;

export default FragmentAttributeTypeDependency;
