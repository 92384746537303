import isEqual from 'lodash/isEqual';
import getSortedFeatures from './getSortedFeatures';

/**
 * Compare two feature arrays
 * @param {object} currentFeatures - Array of OpenLayers features
 * @param {object} features - Array of OpenLayers features
 * @returns {boolean}
 */

const checkEquality = (currentFeatures, features) => {
  const currentFeatureCompare = getSortedFeatures(currentFeatures);
  const newFeatureCompare = getSortedFeatures(features);

  return isEqual(currentFeatureCompare, newFeatureCompare);
};

export default checkEquality;
