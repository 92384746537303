import gql from 'graphql-tag';

import FragmentChecklistItem from '../fragments/FragmentCheckListItem';

const MutationCreateChecklistItem = gql`
  ${FragmentChecklistItem}

  mutation MutationCreateChecklistItem($item: CreateChecklistItemInput!, $owner: EntityReferenceInput!) {
    createChecklistItem(item: $item, owner: $owner) {
      ...ChecklistItem
    }
  }
`;

export default MutationCreateChecklistItem;
