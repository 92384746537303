/**
 * Get the fraction representation of a number
 * @param {number} value as float
 * @returns {string}
 */

const isAlmostEqual = (a, b) => {
  return Math.abs(a - b) <= 1e-6;
};

const getGCD = (a, b) => {
  if (isAlmostEqual(b, 0)) return a;
  return getGCD(b, a % b);
};

const getPrecision = (n) => {
  let precision = 1;
  while (!isAlmostEqual(Math.round(n * precision) / precision, n)) {
    precision *= 10;
  }
  return precision;
};

const getFractionFromFloat = (value) => {
  if (isAlmostEqual(value, 0.0)) {
    return '0';
  }
  if (!value) {
    return null;
  }

  const precision = getPrecision(value);
  const intValue = value * precision;
  const gcd = Math.abs(getGCD(intValue, precision));
  const numerator = intValue / gcd;
  const denominator = precision / gcd;

  return Math.round(denominator) === 1 ? Math.round(numerator) : Math.round(numerator) + '/' + Math.round(denominator);
};

export default getFractionFromFloat;
