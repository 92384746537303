import CryptoJS from 'crypto-js';

const getHash = async file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = event => {
      const result = event.target.result;
      const wordArray = CryptoJS.lib.WordArray.create(result);
      const hash = CryptoJS.SHA1(wordArray).toString();

      resolve(hash);
    };

    reader.readAsArrayBuffer(file);
  });
};

export default getHash;
