/**
 * UI form element for auto start configuartion to select a default assignee for a planning.
 */

import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import deepEqual from 'deep-equal';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import InputAdornment from '@mui/material/InputAdornment';

import { i18n } from '@geomagic/i18n';
import SingleSelect from '@geomagic/nam-react-core/components/SingleSelect';

const getInvalidOption = value => ({
  ...value,
  fullName: i18n.t('placeholder.invalidOption'),
  disabled: true,
});

const SelectAssignee = props => {
  const {
    assigneeOptions,
    formContext,
    helperText = i18n.t('description.hasDisabledTeamMembers'),
    label = i18n.t('default.user'),
    teamKey = 'team',
    updateField,
    value = null,
  } = props;

  const team = formContext?.values?.[teamKey];
  const options = useMemo(
    () =>
      team
        ? team.members.map(
            member => assigneeOptions.find(option => option.id === member.id) || { ...member, disabled: true }
          )
        : assigneeOptions,
    [assigneeOptions, team]
  );
  const teamRef = useRef(team);
  const assignee = value ? options.find(option => option.id === value.id) || getInvalidOption(value) : null;
  const hasDisabledTeamMembers = team && options.some(option => option.disabled);

  useEffect(() => {
    const teamChanged = !deepEqual(teamRef.current, team);
    if (teamChanged) {
      teamRef.current = team;
      if (team && value) {
        if (!options.find(option => option.id === value.id)) {
          updateField(null);
        }
      }
    }
  }, [team, options, updateField, value]);

  return (
    <SingleSelect
      disableClearable={false}
      getOptionDisabled={option => (option ? option.disabled : false)}
      getOptionLabel={item => item.fullName}
      getOptionSelected={(option, item) => option.id === item.id}
      helperText={hasDisabledTeamMembers ? helperText : null}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <AccountCircleIcon />
          </InputAdornment>
        ),
      }}
      options={options}
      label={label}
      onChange={(event, newValue) => {
        updateField(newValue);
      }}
      value={assignee}
    />
  );
};

SelectAssignee.propTypes = {
  assigneeOptions: PropTypes.array.isRequired,
  formContext: PropTypes.object.isRequired,
  helperText: PropTypes.string,
  label: PropTypes.string,
  teamKey: PropTypes.string,
  updateField: PropTypes.func.isRequired,
  value: PropTypes.object,
};

export default SelectAssignee;
