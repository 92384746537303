import { getReference } from '@geomagic/geonam';

import getUpdatedDoc from './getUpdatedDoc';
import handleFetchError from './handleFetchError';
import handleSyncError from './handleSyncError';
import isIncludingInPatch from './isIncludingInPatch';
import createEntity from './updates/createEntity';
import removePatchByPath from './removePatchByPath';
import resetDoc from './resetDoc';
import resetDraftDoc from './resetDraftDoc';
import updateChecklist from './updates/updateChecklist';
import updateDocuments from './updates/updateDocuments';
import updateEntity from './updates/updateEntity';
import updateGeometries from './updates/updateGeometries';
import updateJournal from './updates/updateJournal';

/**
 * Synchronizes the changes/json patches to the doc with the backend and remove them separately
 * as there might me connection issues.
 * @param {object} params
 * @param {object} params.client
 * @param {object} params.doc as RxDocument
 * @param {Array} params.entityClasses
 * @param {object} params.mapProps
 * @param {Function} params.refetchQuery
 * @param {Array} params.syncErrors
 * @returns
 */

const getDefaultSync = async (params) => {
  const { client, doc, entityClasses, mapProps, refetchQuery, syncErrors } = params;
  let entityReference = getReference(doc?.entity);

  try {
    const isDraft = !!doc?.draft;

    const updateProps = {
      client,
      doc,
      entityClasses,
      entity: doc?.entity,
      patchedEntity: doc.getPatchedEntity(),
    };

    if (isDraft) {
      const createdEntityReference = await createEntity(updateProps);

      if (createdEntityReference) {
        entityReference = createdEntityReference;
        await resetDraftDoc(doc, entityReference);
      }
    } else {
      const updateEntityPointer = '/attributeValues';

      if (isIncludingInPatch(doc, updateEntityPointer)) {
        await updateEntity({ ...updateProps, entityReference, path: updateEntityPointer });
        await removePatchByPath(doc, updateEntityPointer);
      }
    }

    const updateGeometriesPointer = '/featureCollections/0/features';

    if (isIncludingInPatch(doc, updateGeometriesPointer)) {
      await updateGeometries({ ...updateProps, entityReference, path: updateGeometriesPointer });
      await removePatchByPath(doc, updateGeometriesPointer);
    }

    const updateDocumentsPointer = '/documents';

    if (isIncludingInPatch(doc, updateDocumentsPointer)) {
      await updateDocuments({ ...updateProps, entityReference, path: updateDocumentsPointer });
      await removePatchByPath(doc, updateDocumentsPointer);
    }

    const updateJournalPointer = '/journal';

    if (isIncludingInPatch(doc, updateJournalPointer)) {
      await updateJournal({ ...updateProps, entityReference, path: updateJournalPointer });
      await removePatchByPath(doc, updateJournalPointer);
    }

    const updateChecklistPointer = '/checklistItems';

    if (isIncludingInPatch(doc, updateChecklistPointer)) {
      await updateChecklist({ ...updateProps, entityReference, path: updateChecklistPointer });
      await removePatchByPath(doc, updateChecklistPointer);
    }

    const updatedEntity = refetchQuery && (await getUpdatedDoc(client, refetchQuery, entityReference, mapProps));

    if (updatedEntity) {
      await resetDoc(doc, updatedEntity);
    }

    return entityReference;
  } catch (error) {
    handleFetchError({ error, isDefaultThrow: false });
    handleSyncError({ entityReference, error, syncErrors });
  }
};

export default getDefaultSync;
