import { getEntityClass } from '@geomagic/geonam';

import addGeometryTypeToFeatures from './addGeometryTypeToFeatures';
import getGeometryTypesByStyleId from './getGeometryTypesByStyleId';

/**
 * Get a feature collection with styles
 * @param {object} entity - GeoNAM entity
 * @param {Array.<object>} entityClasses - Array of GeoNAM entity classes
 * @returns {object}
 */

const getFeatureCollection = (entity, entityClasses) => {
  let collection = null;

  if (entity && entity.entityType && entityClasses) {
    const featureCollection = entity?.featureCollections[0];
    const entityClass = getEntityClass(entityClasses, entity?.className);
    const features = featureCollection?.features || [];
    const { typesByStyleId } = getGeometryTypesByStyleId(entity.entityType, entityClass);

    collection = {
      ...featureCollection,
      features: addGeometryTypeToFeatures(features, typesByStyleId),
    };
  }

  return collection;
};

export default getFeatureCollection;
