import parseLocaleNumber from './parseLocaleNumber';

/**
 * Get a <NumberFormat /> props
 * @param {object} attributeType - GeoNAM attribute type
 * @returns {object}
 */

const getLocalDefaultNumberFormat = () => {
  const thousandSeparator = (1111).toLocaleString().replace(/1/g, '');
  const decimalSeparator = (1.1).toLocaleString().replace(/1/g, '');
  return {
    thousandSeparator,
    decimalSeparator,
    decimalScale: 2,
  };
};

const hasAnyFormattingKeys = (options) => {
  return (
    options.hasOwnProperty('groupingSize') ||
    options.hasOwnProperty('multiplier') ||
    options.hasOwnProperty('minFractionDigits') ||
    options.hasOwnProperty('maxFractionDigits') ||
    options.hasOwnProperty('minIntegerDigits')
  );
};

const getNumberFormatObject = (attributeType) => {
  let suffix = attributeType.unit ? ' ' + attributeType.unit : '';
  let format, removeFormatting;
  const { options = {} } = attributeType;
  const numberFormat = getLocalDefaultNumberFormat();
  const { groupingSize, multiplier, minFractionDigits, maxFractionDigits, minIntegerDigits } = options;

  if (!hasAnyFormattingKeys(options)) {
    return {
      ...numberFormat,
      ...(attributeType.dataType === 'INT' && { decimalScale: 0 }),
      suffix,
    };
  }

  if (multiplier > 1) {
    if (multiplier === 100) {
      suffix = ' %';
    }
    format = (value) => {
      const formattedValue =
        value != null
          ? Intl.NumberFormat(undefined, {
              useGrouping: groupingSize > 0,
              minimumIntegerDigits: minIntegerDigits,
              minimumFractionDigits: minFractionDigits,
              maximumFractionDigits: maxFractionDigits,
            }).format(value * multiplier) + suffix
          : '';
      return formattedValue;
    };
    removeFormatting = (formattedValue) => {
      const suffixLastIndex = formattedValue.lastIndexOf(suffix);
      const value =
        suffix && suffixLastIndex !== -1 && suffixLastIndex === formattedValue.length - suffix.length
          ? parseLocaleNumber(formattedValue.substring(0, suffixLastIndex))
          : parseLocaleNumber(formattedValue);

      return value / multiplier;
    };
  } else {
    format = (value) => {
      const formattedValue =
        value != null
          ? Intl.NumberFormat(undefined, {
              useGrouping: groupingSize > 0,
              minimumIntegerDigits: minIntegerDigits,
              minimumFractionDigits: minFractionDigits,
              maximumFractionDigits: maxFractionDigits,
            }).format(value) + suffix
          : '';
      return formattedValue;
    };
    removeFormatting = (formattedValue) => {
      const suffixLastIndex = formattedValue.lastIndexOf(suffix);
      const value =
        suffix && suffixLastIndex !== -1 && suffixLastIndex === formattedValue.length - suffix.length
          ? parseLocaleNumber(formattedValue.substring(0, suffixLastIndex))
          : parseLocaleNumber(formattedValue);

      return value;
    };
  }

  const thousandSeparator = groupingSize > 0 ? numberFormat.thousandSeparator : '';
  const decimalScale = maxFractionDigits;
  return {
    ...numberFormat,
    thousandSeparator,
    decimalScale,
    suffix,
    fixedDecimalScale: minFractionDigits === maxFractionDigits,
    format,
    removeFormatting,
  };
};

export default getNumberFormatObject;
