import isFunction from 'lodash/isFunction';
import uniqBy from 'lodash/uniqBy';
import { omitDeep } from '@patomation/omit-deep';

import handleFetchError from './handleFetchError';
import updateAdditionalEntities from './updateAdditionalEntities';
import getAdditionalCollectionEntitiesByConfig from './getAdditionalCollectionEntitiesByConfig';

import { ENTITY_SELECTOR_KEY, ENTITY_UNIQ_KEY, REMOTE_VERSION } from './consts';

/**
 * Check whether there are any changes (see pull.js)
 * @param {object} params
 * @param {object} params.client
 * @param {object} params.config
 * @param {object} params.database
 * @param {string} params.userId
 * @returns {boolean}
 */
const hasUpdates = async ({ database, client, config, userId }) => {
  const additionalCollectionEntities = getAdditionalCollectionEntitiesByConfig(config);

  for (let i = 0; i < config.length; i++) {
    const currentConfig = config[i];
    const { collectionName, getModifiedOn, beforeInsertDoc } = currentConfig;

    try {
      const collection = database[collectionName];
      const { data } = await client.query({ ...currentConfig?.query, fetchPolicy: 'no-cache' });
      const remoteEntities = data?.entities?.values;

      if (remoteEntities) {
        const additionalEntities = additionalCollectionEntities[collectionName];
        const entitiesToInsert = uniqBy([...remoteEntities, ...additionalEntities], ENTITY_UNIQ_KEY);

        const removeSelector = {
          [ENTITY_SELECTOR_KEY]: { $nin: entitiesToInsert.map(({ id }) => id) },
          draft: null,
          userId,
        };

        const docsToRemove = await collection.find({ selector: removeSelector }).exec();

        if (docsToRemove.length > 0) {
          return true;
        }

        for (let j = 0; j < entitiesToInsert.length; j++) {
          const entity = omitDeep(entitiesToInsert[j], '__typename');
          const selector = { [ENTITY_SELECTOR_KEY]: entity.id, userId };
          const existingDoc = await collection.findOne({ selector }).exec();
          const modifiedOn = isFunction(getModifiedOn) ? await getModifiedOn(entity) : null;
          const entityRemoteVersion = existingDoc?.remoteVersion;

          if (beforeInsertDoc) {
            await beforeInsertDoc(entity, updateAdditionalEntities(additionalCollectionEntities));
          }

          if (!existingDoc) {
            return true;
          } else if (modifiedOn > existingDoc.modifiedOn || REMOTE_VERSION > entityRemoteVersion) {
            return true;
          }
        }
      }
    } catch (error) {
      handleFetchError({ error });
    }
  }

  return false;
};

export default hasUpdates;
