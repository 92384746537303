import React from 'react';
import PropTypes from 'prop-types';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { makeStyles } from '@geomagic/core';
import { i18n } from '@geomagic/i18n';
import { DEFAULT_TRIGGER_ICON_PROPS } from '@consts';

const useStyles = makeStyles()(({ palette, spacing }) => ({
  root: {
    pointerEvents: 'auto',
  },
  triggerFab: {
    backgroundColor: palette.background.paper,
    marginTop: spacing(),
  },
  speedDial: {
    backgroundColor: palette.background.paper,
  },
}));

const TriggerModifyInteractions = (props) => {
  const { handleToggleOpen, modifiedFeature, onDelete, onModify, open, selectedFeature } = props;
  const { classes } = useStyles(props);

  return (
    <SpeedDial
      ariaLabel="modifyInteractions"
      className={classes.root}
      FabProps={{
        ...DEFAULT_TRIGGER_ICON_PROPS,
        className: classes.speedDial,
        onClick: handleToggleOpen,
      }}
      icon={<SpeedDialIcon icon={<EditIcon />} openIcon={<CloseIcon />} />}
      open={open}
    >
      {open && [
        <SpeedDialAction
          key="saveGeometryAction"
          icon={<SaveIcon />}
          tooltipTitle={i18n.t('map.tooltip.saveSelectedGeometry')}
          FabProps={{
            ...DEFAULT_TRIGGER_ICON_PROPS,
            className: classes.triggerFab,
            disabled: !modifiedFeature,
          }}
          onClick={onModify}
        />,
        <SpeedDialAction
          key="deleteGeometryAction"
          icon={<DeleteIcon />}
          tooltipTitle={i18n.t('map.tooltip.removeSelectedGeometry')}
          FabProps={{
            ...DEFAULT_TRIGGER_ICON_PROPS,
            className: classes.triggerFab,
            disabled: !selectedFeature,
          }}
          onClick={onDelete}
        />,
      ]}
    </SpeedDial>
  );
};

TriggerModifyInteractions.propTypes = {
  handleToggleOpen: PropTypes.func,
  modifiedFeature: PropTypes.object,
  onDelete: PropTypes.func.isRequired,
  onModify: PropTypes.func.isRequired,
  open: PropTypes.bool,
  selectedFeature: PropTypes.object,
};

export default TriggerModifyInteractions;
