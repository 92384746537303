import getMandatoryElements from './getMandatoryElements';
import isCompletedFormElement from './isCompletedFormElement';

const getProgress = (forms = []) => {
  const mandatoryElements = getMandatoryElements(forms);
  const progress = mandatoryElements.filter(isCompletedFormElement).length;
  const total = mandatoryElements.length;

  return { progress, total };
};

export default getProgress;
