import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@geomagic/core';
import { FORM_COMPONENT_MIN_HEIGHT } from '../consts';
import getKey from '../utils/getKey';

const DEFAULT_LISTITEM_PROPS = {
  noWrap: true,
};

const useStyles = makeStyles()(({ breakpoints, palette, spacing }) => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    margin: spacing(1, 0.5),
    width: 200,
    [breakpoints.down('sm')]: {
      flex: 'initial',
      width: '100%',
    },
    [breakpoints.down('md')]: {
      marginBottom: spacing(1),
    },
  },
  readOnly: {
    pointerEvents: 'none',
  },
  select: {
    '& .MuiSelect-select': {
      alignItems: 'center',
      display: 'flex',
      height: FORM_COMPONENT_MIN_HEIGHT,
      paddingBottom: 0,
      paddingTop: 0,
    },
    '& label': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width: 'calc(100% - 32px)',
    },
  },
  menuItem: {
    minHeight: 40,
  },
  inputRoot: {
    '&$cssFocused $notchedOutline': {
      borderColor: `${palette.primary.main} !important`,
    },
  },
  isFocused: {},
  isMandatory: {
    border: `2px solid ${palette.warning.main}`,
  },
}));

const EnumSelect = (props) => {
  const { context, data, path } = props;
  const { isMobile, isReadOnly, onUpdate } = context;
  const { mandatory, name, statusValue, statusValues } = data;
  const extendedStatusValues = [{ name: null }, ...statusValues];

  const [selectedStatusValue, setSelectedStatusValue] = useState(statusValue);
  const { classes } = useStyles(props);

  /**
   *  EVENT HANDLER
   */

  const handleChange = (event) => {
    const newStatusValue = statusValues.find(({ name: statusValueName }) => statusValueName === event.target.value);

    if (statusValue !== newStatusValue) {
      setSelectedStatusValue(newStatusValue);
      onUpdate({ statusValue: newStatusValue }, path, data);
    }
  };

  return (
    <div className={classNames(classes.root, { [classes.readOnly]: isReadOnly })}>
      <TextField
        className={classes.select}
        fullWidth
        InputProps={{
          classes: {
            root: classes.inputRoot,
            focused: classes.isFocused,
            notchedOutline: classNames({
              [classes.isMandatory]: mandatory && !selectedStatusValue,
            }),
          },
        }}
        label={name}
        margin="none"
        SelectProps={{ native: isMobile }}
        onChange={handleChange}
        required={mandatory}
        select
        value={selectedStatusValue ? selectedStatusValue.name : ''}
        variant="outlined"
      >
        {extendedStatusValues.map(({ label, name: statusValueName }, index) =>
          isMobile ? (
            <option key={getKey(statusValueName, index)} value={statusValueName}>
              {statusValueName}
            </option>
          ) : (
            <MenuItem className={classes.menuItem} key={getKey(statusValueName, index)} value={statusValueName}>
              <ListItemText primary={statusValueName} primaryTypographyProps={DEFAULT_LISTITEM_PROPS} />
            </MenuItem>
          )
        )}
      </TextField>
    </div>
  );
};

EnumSelect.propTypes = {
  context: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
};

export default EnumSelect;
