import gql from 'graphql-tag';
import FragmentDocument from './FragmentDocument';

const FragmentFormElementDocuments = gql`
  ${FragmentDocument}

  fragment FormElementDocuments on FormElementDocuments {
    description
    displayedIfDefect
    documents {
      ...Document
    }
    id
    mandatory
    name
    options
    seqNumber
    typename
  }
`;

export default FragmentFormElementDocuments;
