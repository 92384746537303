import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { Provider as DatabaseProvider } from 'rxdb-hooks';
import CloseIcon from '@mui/icons-material/Close';
import { Trigger } from '@geomagic/core';
import Geonam from '@components/Geonam';
import initializeDatabase from '@database/initialize';
import ServiceWorkerProvider from '@serviceWorker/ServiceWorkerProvider';
import databaseCollections from '@synchronization/databaseCollections';
import themeOverrides from '@theme/overrides';
import { dark, light } from '@theme/palette';
import { TeleporterProvider } from '@utils/useTeleport';
import App from './App';
import { STATE, STATE_SAVE_KEYS } from './state';
import './index.css';
import initDayJS from './nam-react-core/utils/initDayJS';

initDayJS();

const CloseButton = ({ snackbarKey }) => {
  const { closeSnackbar } = useSnackbar();

  return snackbarKey !== '_loading_' ? (
    <Trigger
      color="inherit"
      icon={<CloseIcon />}
      onClick={() => closeSnackbar(snackbarKey)}
      size="small"
      variant="icon"
    />
  ) : null;
};

CloseButton.propTypes = {
  snackbarKey: PropTypes.string,
};

const Root = () => {
  const databaseRef = useRef();
  const [isInitialized, setInitialized] = useState(false);

  const theme = {
    palette: { ...(useMediaQuery('(prefers-color-scheme: dark)') ? dark : light) },
    components: themeOverrides,
    breakpoints: {
      values: {
        mobile: 0,
        tablet: 1024,
        desktop: 1280,
      },
    },
  };

  /**
   *  EFFECTS
   */

  useEffect(() => {
    const initialize = async () => {
      const _database = await initializeDatabase(databaseCollections);
      databaseRef.current = _database;
      setInitialized(true);
    };

    initialize();
  }, []);

  if (!isInitialized) {
    return null;
  }

  return (
    <ServiceWorkerProvider>
      <DatabaseProvider db={databaseRef.current}>
        <TeleporterProvider>
          <SnackbarProvider
            action={(snackbarKey) => <CloseButton snackbarKey={snackbarKey} />}
            anchorOrigin={{
              horizontal: 'center',
              vertical: 'bottom',
            }}
            sx={{
              '& .SnackbarItem-message': {
                flex: 1,
              },
            }}
            dense
            hideIconVariant
            maxSnack={10}
          >
            <Geonam
              defaultGlobalState={{ ...STATE, vectorTileServerUrl: window.VT_SERVER_URL }}
              keysToSave={STATE_SAVE_KEYS}
              isSaveState
              theme={theme}
            >
              <App />
            </Geonam>
          </SnackbarProvider>
        </TeleporterProvider>
      </DatabaseProvider>
    </ServiceWorkerProvider>
  );
};

ReactDOM.render(<Root />, document.getElementById('root'));
