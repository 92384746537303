import gql from 'graphql-tag';

import FragmentDispatch from '../fragments/FragmentDispatch';
import FragmentEntity from '../fragments/FragmentEntity';
import FragmentForms from '../fragments/FragmentForms';
import FragmentProcessInstances from '../fragments/FragmentProcessInstances';

const QueryAssignments = gql`
  ${FragmentDispatch}
  ${FragmentEntity}
  ${FragmentForms}
  ${FragmentProcessInstances}

  query QueryEntities($filter: FilterInput!, $srid: Int, $withHistory: Boolean = false, $worldId: NID) {
    entities(filter: $filter) {
      values {
        ...Entity
        ...Forms
        ...ProcessInstances
        relevantDispatches {
          ...Dispatch
        }
      }
      totalValueCount
    }
  }
`;

export default QueryAssignments;
