import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import InputAdornment from '@mui/material/InputAdornment';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import NotHappyIcon from '@mui/icons-material/SentimentDissatisfiedOutlined';

import { Trigger } from '@geomagic/core';
import { submitForm } from '@geomagic/forms';
import { i18n } from '@geomagic/i18n';
import { getEntityClass, getEntityType, getReference } from '@geomagic/geonam';
import { EntityForm } from '@geomagic/nam-react-core/components/Entity';
import StackedDialog from '@geomagic/nam-react-core/components/StackedDialog';

import Placeholder from '@components/Placeholder';
import { DEFAULT_TEXT_FIELD_PROPS, PRIMARY_TRIGGER_PROPS, SECONDARY_TRIGGER_PROPS } from '@consts';
import { CLASSNAME_DOCUMENT } from '@graphql/consts';

const getSelectOptions = (documentTypes = [], typeRestrictions) => {
  const filterRestrictedTypes = (type) =>
    !typeRestrictions || typeRestrictions.some((typeRestriction) => typeRestriction.id === type.id);
  return documentTypes.filter((type) => !!type.creatable).filter(filterRestrictedTypes);
};

const DocumentFormDialog = (props) => {
  const {
    doc,
    entityClasses,
    fileProps,
    isMobile,
    hasDocumentTypeSelection = true,
    onClose,
    onSubmit,
    open,
    setFileProps,
    withAttributesForm,
  } = props;

  const documentClass = getEntityClass(entityClasses, CLASSNAME_DOCUMENT);
  const entity = doc.getPatchedEntity();
  const entityType = getEntityType(entityClasses, entity?.className, entity?.entityType?.id);
  const documentTypes = hasDocumentTypeSelection
    ? getSelectOptions(documentClass?.entityTypes, entityType?.documentTypes)
    : documentClass?.entityTypes;

  const hasSelectOptions = documentTypes.length > 0;

  const formId = 'documentForm';

  const { name: fileName = '' } = fileProps || {};
  const fileExtension = '.' + fileName.split('.').pop();
  const fileNameWithoutExtension = fileName.slice(0, -fileExtension.length);

  const formContextRef = useRef();
  const [documentTypeId, setDocumentTypeId] = useState(documentTypes[0]?.id);

  /**
   *  EVENT HANDLER
   */

  const handleChangeSelect = (event) => {
    const id = Number(event.target.value);

    setDocumentTypeId(id);
    formContextRef.current.replaceValues({});
  };

  const handleSubmit = (attributeValues) => {
    const foundEntityType = documentTypes.find((type) => type.id === documentTypeId);
    const entityTypeReference = getReference(foundEntityType);

    onSubmit(attributeValues, entityTypeReference);
  };

  const handleSubmitForm = () => {
    submitForm(formId);
  };

  /**
   *  COMPONENTS
   */

  const Actions = (
    <>
      <Trigger {...SECONDARY_TRIGGER_PROPS} onClick={onClose}>
        {i18n.t('button.cancel')}
      </Trigger>
      <Trigger
        disabled={!hasSelectOptions}
        onClick={withAttributesForm ? handleSubmitForm : () => handleSubmit({})}
        {...PRIMARY_TRIGGER_PROPS}
      >
        {i18n.t('button.add')}
      </Trigger>
    </>
  );

  const DialogContent = hasSelectOptions ? (
    <>
      {hasDocumentTypeSelection && (
        <Select
          {...DEFAULT_TEXT_FIELD_PROPS}
          autoWidth
          fullWidth
          margin="dense"
          native
          onChange={handleChangeSelect}
          sx={{ mb: 2 }}
          value={documentTypeId}
        >
          {documentTypes.map(({ id, name }) => (
            <option key={id} value={id}>
              {name}
            </option>
          ))}
        </Select>
      )}
      <TextField
        fullWidth
        InputProps={{
          endAdornment: <InputAdornment position="end">{fileExtension}</InputAdornment>,
        }}
        label={i18n.t('label.fileName')}
        onChange={(event) => {
          setFileProps({ ...fileProps, name: event.target.value + fileExtension });
        }}
        required
        value={fileNameWithoutExtension}
        {...DEFAULT_TEXT_FIELD_PROPS}
      />
      {withAttributesForm && (
        <EntityForm
          entityClass={documentClass}
          entityClasses={entityClasses}
          entityClassName={CLASSNAME_DOCUMENT}
          entityTypeId={documentTypeId}
          formId={formId}
          hideReadOnlyFields={true}
          isMobile={isMobile}
          isRequiredFieldsOnly
          isSubmitOnEnter={false}
          key={documentTypeId}
          onSubmit={handleSubmit}
        >
          {(fields, formContext) => {
            formContextRef.current = formContext;
            return fields;
          }}
        </EntityForm>
      )}
    </>
  ) : (
    <Placeholder
      icon={<NotHappyIcon />}
      subtitle={i18n.t('placeholder.noDocumentType.subtitle')}
      title={i18n.t('placeholder.noDocumentType.title')}
    />
  );

  return (
    <StackedDialog
      isFullScreen={isMobile}
      open={open}
      actions={Actions}
      handleClose={onClose}
      content={DialogContent}
      title={i18n.t('dialog.addDocument.title')}
      withPadding
    />
  );
};

DocumentFormDialog.propTypes = {
  doc: PropTypes.object.isRequired,
  entityClasses: PropTypes.array.isRequired,
  fileProps: PropTypes.object,
  isFullScreen: PropTypes.bool,
  isMobile: PropTypes.bool,
  hasDocumentTypeSelection: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool,
  setFileProps: PropTypes.func.isRequired,
  withAttributesForm: PropTypes.bool,
};

export default DocumentFormDialog;
