/**
 * An object to display a formatted fraction.
 */

import React from 'react';
import PropTypes from 'prop-types';

import getFractionFromFloat from '../utils/getFractionFromFloat';

const FractionFormat = props => {
  const { prefix = '', suffix = '', value } = props;
  return <span>{prefix + getFractionFromFloat(value) + suffix}</span>;
};

FractionFormat.propTypes = {
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default FractionFormat;
