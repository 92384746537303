import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AssignmentIcon from '@mui/icons-material/AssignmentOutlined';
import { i18n } from '@geomagic/i18n';
import useAppBar from '@components/AppBar/useAppBar';
import List from '@components/List';
import Placeholder from '@components/Placeholder';
import useFeatures from '@components/Map/utils/useFeatures';
import ActionsComponent from './AssignmentListActions';
import AvatarComponent from './AssignmentProgress';
import TextComponent from './AssignmentListText';
import getFeatures from './getFeatures';

const AssignmentList = (props) => {
  const {
    assignments,
    className,
    client,
    entityClasses,
    getFeatureStyle,
    handleCheckForOpenDrafts,
    isLoading,
    isMobile,
    isOnline,
    isTeamAssignment,
    mapProps,
    onClaimAssignment,
    onClick,
    onCloseSwipeableArea,
    onDeleteAssignment,
    onOpenTasks,
    onSyncAssignment,
    onUpdateAssignment,
    user,
    vectorTileServerUrl,
  } = props;

  const { mapRef, maxExtentZoomLevel, selectColor } = mapProps;

  const { setTitle } = useAppBar();
  const [activeItemId, setActiveItemId] = useState();
  const [features, setFeatures] = useState([]);

  const hasAssignments = assignments.length > 0;

  /**
   *  EVENT HANDLER
   */

  const handleSelectFeature = useCallback((event, feature) => {
    const entity = feature?.get('entity');
    setActiveItemId((prev) => (entity ? entity?.id : null));
  }, []);

  const handleShowOnMap = (mapFeatures, entityId) => {
    selectFeatures(mapFeatures);
    setActiveItemId(entityId);
  };

  /**
   *  MAP
   */

  const { animateFeatures, selectFeatures } = useFeatures(
    { mapRef, features, maxExtentZoomLevel, selectColor, style: getFeatureStyle },
    { onSelect: handleSelectFeature }
  );

  /**
   *  EFFECTS
   */

  useEffect(() => {
    setTitle(i18n.t('type.assignments'));
  }, [setTitle]);

  useEffect(() => {
    if (!isLoading) {
      const _features = getFeatures(assignments);
      setFeatures(_features);
    }
  }, [assignments, isLoading]);

  useEffect(() => {
    animateFeatures();
  }, [animateFeatures, features]);

  /**
   *  COMPONENTS
   */

  const PlaceholderComponent = (
    <Placeholder
      icon={<AssignmentIcon />}
      title={
        isTeamAssignment
          ? i18n.t('assignment.placeholder.noTeamAssignments.title')
          : i18n.t('assignment.placeholder.noAssignments.title')
      }
      subtitle={
        isTeamAssignment
          ? i18n.t('assignment.placeholder.noTeamAssignments.subtitle')
          : i18n.t('assignment.placeholder.noAssignments.subtitle')
      }
    />
  );

  const additionalContextProps = {
    client,
    entityClasses,
    isMobile,
    isOnline,
    isTeamAssignment,
    handleCheckForOpenDrafts,
    mapProps,
    onClaimAssignment,
    onCloseSwipeableArea,
    onDeleteAssignment,
    onOpenTasks,
    onShowOnMap: handleShowOnMap,
    onSyncAssignment,
    onUpdateAssignment,
    user,
    vectorTileServerUrl,
  };

  const ListComponent = (
    <List
      ActionsComponent={ActionsComponent}
      activeId={activeItemId}
      additionalProps={additionalContextProps}
      AvatarComponent={isTeamAssignment ? null : AvatarComponent}
      className={className}
      idKey="entity.id"
      items={assignments}
      loading={isLoading}
      onClick={onClick}
      TextComponent={TextComponent}
    />
  );

  return !hasAssignments && !isLoading ? PlaceholderComponent : ListComponent;
};

AssignmentList.propTypes = {
  assignments: PropTypes.array.isRequired,
  className: PropTypes.string,
  client: PropTypes.object.isRequired,
  entityClasses: PropTypes.array.isRequired,
  getFeatureStyle: PropTypes.func.isRequired,
  handleCheckForOpenDrafts: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isMobile: PropTypes.bool,
  isOnline: PropTypes.bool,
  isTeamAssignment: PropTypes.bool,
  mapProps: PropTypes.object.isRequired,
  onClaimAssignment: PropTypes.func,
  onClick: PropTypes.func,
  onCloseSwipeableArea: PropTypes.func.isRequired,
  onDeleteAssignment: PropTypes.func.isRequired,
  onOpenTasks: PropTypes.func.isRequired,
  onSyncAssignment: PropTypes.func.isRequired,
  onUpdateAssignment: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  vectorTileServerUrl: PropTypes.string,
};

export default AssignmentList;
