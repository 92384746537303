import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@geomagic/core';

const useStyles = makeStyles()(({ palette }) => ({
  root: {},
  title: {},
  value: {
    whiteSpace: 'pre-wrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  noValue: {
    color: palette.text.secondary,
  },
}));

const N_DASH = '–';

const ReadOnlyField = (props) => {
  const { classes } = useStyles(props);
  const { className, formControlProps, title, titleProps, value, valueProps } = props;

  return (
    <FormControl className={classNames(classes.root, className)} fullWidth margin="none" {...formControlProps}>
      <Typography className={classes.title} color="textSecondary" noWrap variant="body2" {...titleProps}>
        {title ? title : ''}
      </Typography>
      <Typography
        className={classNames(classes.value, { [classes.noValue]: false })}
        color={value ? 'textPrimary' : 'textSecondary'}
        variant="body2"
        noWrap
        {...valueProps}
      >
        {value ? value : N_DASH}
      </Typography>
    </FormControl>
  );
};

ReadOnlyField.propTypes = {
  className: PropTypes.string,
  formControlProps: PropTypes.object,
  title: PropTypes.node,
  titleProps: PropTypes.object,
  value: PropTypes.node,
  valueProps: PropTypes.object,
};

export default ReadOnlyField;
