import dayjs from 'dayjs';

const LAST_LOGIN = 30;
const TIME_TO_FETCH = 10;
const UNIT = 'seconds';

/**
 *
 * Find out whether it is time to fetch for data updates or not.
 *   Returns true if:
 *   - Data fetch has never been made
 *   - Freshly logged in
 *   - lastFetch occurred more than TIME_TO_FETCH seconds ago (throttling)
 * @param userId
 * @param lastFetch
 * @param lastLogin
 * @returns {boolean}
 */

const shouldPrefetch = (userId, lastFetch, lastLogin) => {
  const lastFetchUser = lastFetch && lastFetch[userId];
  const lastLoginUser = lastLogin && lastLogin[userId];

  const now = dayjs();
  const lastFetchSeconds = lastFetchUser && now.diff(dayjs(lastFetchUser), UNIT);
  const lastLoginSeconds = lastLoginUser && now.diff(dayjs(lastLoginUser), UNIT);

  const neverFetched = typeof lastFetchSeconds !== 'number';
  const justLoggedIn = lastLoginSeconds <= LAST_LOGIN;
  const timeToFetch = lastFetchSeconds >= TIME_TO_FETCH;

  return neverFetched || justLoggedIn || timeToFetch;
};

export default shouldPrefetch;
