import fetchAndStoreAttachments from '@database/fetchAndStoreAttachments';

const getAfter = async ({ added = [], updated = [] }) => {
  const affected = [...added, ...updated];

  for (let i = 0; i < affected.length; i++) {
    const databaseDoc = affected[i];
    const documents = databaseDoc?.entity?.documents;
    await databaseDoc.atomicUpdate(oldData => {
      oldData.isWorkbench = true;
      return oldData;
    });

    await fetchAndStoreAttachments(documents, databaseDoc);
  }
};

export default getAfter;
