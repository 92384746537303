import gql from 'graphql-tag';

import FragmentChecklistItem from './FragmentCheckListItem';

const FragmentChecklistItems = gql`
  ${FragmentChecklistItem}
  fragment ChecklistItems on Entity {
    checklistItems {
      ...ChecklistItem
    }
  }
`;

export default FragmentChecklistItems;
