import gql from 'graphql-tag';

import FragmentTeam from '../fragments/FragmentTeam';

const QueryTeamsWithAccess = gql`
  ${FragmentTeam}

  query QueryTeamsWithAccess($entityReference: EntityReferenceInput!) {
    teamsWithAccess(entityReference: $entityReference) {
      ...Team
    }
  }
`;

export default QueryTeamsWithAccess;
