import sortBy from 'lodash/sortBy';
import { findByReference } from '@geomagic/geonam';

/**
 * Get geometry types by style id
 * @param {object} entityType - GeoNAM entity type
 * @param {object} entityClass - GeoNAM entity class
 * @returns {object}
 */

const getGeometryTypesByStyleId = (entityType, entityClass) => {
  const stylesByShapeType = [];
  const typesByStyleId = [];
  const { entityTypes } = entityClass;
  const { geometryTypes } = findByReference(entityTypes, entityType);

  geometryTypes.forEach((geometryType) => {
    geometryType.geometryStyles.forEach((geometryStyle) => {
      const { id: styleId } = geometryStyle;
      stylesByShapeType.push({ ...geometryStyle, _geometryType: geometryType });
      typesByStyleId.push({ styleId, ...geometryType });
    });
  });

  return { stylesByShapeType: sortBy(stylesByShapeType, ({ type }) => type), typesByStyleId };
};

export default getGeometryTypesByStyleId;
