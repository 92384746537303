import React, { Fragment } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import { toggleSidebar } from '@geomagic/core';
import { i18n } from '@geomagic/i18n';

/**
 * Provides an custom show modal function
 * @param {object} params
 * @param {*} params.content - modal content
 * @param {*} params.title - modal title
 * @param {Function} params.onOk - modal ok function
 * @returns {void}
 */

const useToggleActionDrawer = () => {
  const listItemTextProps = {
    noWrap: true,
    variant: 'body1',
  };

  const handleClick = (onClick) => (event) => {
    onClick && onClick(event);
    handleClose();
  };

  const handleClose = () => {
    toggleSidebar({ open: false });
  };

  const handleToogleSidebar = (params) => {
    const { items, title } = params;
    let currentGroup;

    toggleSidebar({
      anchor: 'bottom',
      isWithAppBar: false,
      PaperProps: {
        sx: {
          borderTopLeftRadius: (theme) => 1 * theme.shape.borderRadius,
          borderTopRightRadius: (theme) => 1 * theme.shape.borderRadius,
          height: 'auto !important',
          top: 'auto !important',
          width: '100% !important',
          '& > div': {
            width: '100% !important',
          },
        },
      },
      sx: { zIndex: 1300 },
      content: (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {title && (
            <Typography noWrap sx={{ fontWeight: 'bold', padding: 1, textAlign: 'center' }} variant="body2">
              {title}
            </Typography>
          )}
          <List>
            {items.map(({ color, disabled = false, group, id, label, onClick }) => {
              const isNewGroup = group && group !== currentGroup;
              if (isNewGroup) {
                currentGroup = group;
              }

              return (
                <Fragment key={id}>
                  {isNewGroup && (
                    <>
                      <ListSubheader>{group}</ListSubheader>
                      <Divider />
                    </>
                  )}
                  <ListItem
                    divider
                    sx={{
                      minHeight: 50,
                      ...(!!group && { paddingLeft: 3 }),
                    }}
                  >
                    <ListItemButton disabled={disabled} disableGutters onClick={handleClick(onClick)}>
                      <ListItemText primary={label} primaryTypographyProps={{ color, ...listItemTextProps }} />
                    </ListItemButton>
                  </ListItem>
                </Fragment>
              );
            })}
            <ListItem>
              <ListItemButton disableGutters onClick={handleClose}>
                <ListItemText primary={i18n.t('button.cancel')} primaryTypographyProps={listItemTextProps} />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      ),
    });
  };

  return handleToogleSidebar;
};

export default useToggleActionDrawer;
