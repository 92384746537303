import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Trigger } from '@geomagic/core';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckIcon from '@mui/icons-material/Check';

const TRIGGER_SIZE = 56;

const useStyles = makeStyles()(({ palette }) => ({
  root: {
    color: palette.primary.main,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  trigger: {
    width: TRIGGER_SIZE,
    height: TRIGGER_SIZE,
  },
  left: {
    flex: 1,
    justifyContent: 'flex-start',
  },
}));

const ModalActionBar = (props) => {
  const { onAdd, onBack, onConfirm } = props;
  const { classes } = useStyles(props);

  const triggerProps = {
    className: classes.trigger,
    color: 'inherit',
    size: 'large',
    variant: 'icon',
  };

  return (
    <div className={classes.root}>
      {onBack ? (
        <div className={classes.left}>
          <Trigger {...triggerProps} icon={<ArrowBackIcon fontSize="large" />} onClick={onBack} />
        </div>
      ) : null}
      {onAdd ? <Trigger {...triggerProps} icon={<AddIcon fontSize="large" />} onClick={onAdd} /> : null}
      {onConfirm ? <Trigger {...triggerProps} icon={<CheckIcon fontSize="large" />} onClick={onConfirm} /> : null}
    </div>
  );
};

ModalActionBar.propTypes = {
  onAdd: PropTypes.func,
  onBack: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default ModalActionBar;
