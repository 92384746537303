import { i18n } from '@geomagic/i18n';

/**
 * Get style and title from entity processing status
 * @param {object} parmas
 * @param {string} parmas.criticalStatusMessage - Entity criticalStatusMessage
 * @param {Array.<string>} parmas.ignoreStatus - Entity criticalStatusMessage
 * @param {string} params.status - Entity processing status
 * @param {object} params.theme - MaterialUI theme with status color palette
 * @returns {object}
 */

const getEntityStateByProcessingStatus = params => {
  const { criticalStatusMessage, ignoreStatus = ['NONE'], status, theme = {} } = params;
  const { palette } = theme;

  return !ignoreStatus.includes(status)
    ? {
        title: status === 'CRITICAL' ? criticalStatusMessage : i18n.t(`status.${status}`),
        ...(palette?.status[status] && {
          style: {
            backgroundColor: palette?.status[status],
            color: palette?.getContrastText(palette?.status[status]),
          },
        }),
      }
    : {};
};

export default getEntityStateByProcessingStatus;
