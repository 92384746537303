import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import ListItemText from '@mui/material/ListItemText';
import { i18n } from '@geomagic/i18n';
import { DATETIME_DISPLAY_FORMAT } from '@consts';

const ConflictResolutionText = (props) => {
  const { data } = props;
  const formattedDate = dayjs(data?.modifiedOn).format(DATETIME_DISPLAY_FORMAT);
  const title = data?.entity?.displayName;
  const subtitle = formattedDate
    ? i18n.t('synchronization.label.modifiedOn', { variables: { modifiedOn: formattedDate } })
    : '';

  return (
    <ListItemText
      primary={title}
      secondary={subtitle}
      primaryTypographyProps={{ noWrap: true, variant: 'body1' }}
      secondaryTypographyProps={{ noWrap: true, variant: 'body2' }}
    />
  );
};

ConflictResolutionText.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ConflictResolutionText;
