const getAdditionalCollectionEntitiesByConfig = (config, initialValue = []) => {
  const additionalCollectionEntities = {};

  config.forEach(({ collectionName }) => {
    additionalCollectionEntities[collectionName] = [];
  });

  return additionalCollectionEntities;
};

export default getAdditionalCollectionEntitiesByConfig;
