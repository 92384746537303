import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import SvgIcon from '@mui/material/SvgIcon';
import DispatchIcon from '@geomagic/nam-react-core/icons/Dispatch';
import getDispatchesWithDefectStatus from './utils/getDispatchesWithDefectStatus';
import getProgress from './utils/getProgress';

const AVATAR_ICON_SIZE = 20;
const DEFAULT_SIZE = 40;
const VARIANT_CIRCULAR_THICKNESS = 4;
const VARIANT_ICON_SIZE = 16;
const VARIANT_ICON_THICKNESS = 2;
const VARIANT_LINEAR_SIZE = 4;
const TOTAL_VALUE = 100;

const getBackgroungGrey = ({ palette }) => palette.grey[palette.mode === 'light' ? 300 : 500];

const styles = {
  avatar: {
    backgroundColor: 'background.paper',
    border: '1px solid',
    borderColor: 'background.paper',
    borderRadius: '50%',
    position: 'absolute',
    height: `${AVATAR_ICON_SIZE}px`,
    width: `${AVATAR_ICON_SIZE}px`,
  },
  avatarCircular: {
    bottom: (theme) => theme.spacing(-0.5),
    right: (theme) => theme.spacing(-0.5),
  },
  avatarIcon: {
    bottom: (theme) => theme.spacing(-1.5),
    right: (theme) => theme.spacing(0.5),
  },
  bar: {
    backgroundColor: getBackgroungGrey,
    flex: 1,
  },
  boxRoot: {
    alignItems: 'center',
    display: 'flex',
    position: 'relative',
    height: '100%',
  },
  boxRootCircular: {
    minHeight: DEFAULT_SIZE,
    marginRight: 2,
    textAlign: 'center',
  },
  iconComponent: {
    borderRadius: '50%',
    borderStyle: 'solid',
    borderWidth: VARIANT_ICON_THICKNESS,
    minHeight: VARIANT_ICON_SIZE,
    minWidth: VARIANT_ICON_SIZE,
    marginRight: 2,
  },
  iconSuccess: {
    backgroundColor: 'success.main',
    borderColor: 'success.dark',
  },
  iconWarning: {
    backgroundColor: 'warning.main',
    borderColor: 'warning.dark',
  },
  progressCircular: {
    color: getBackgroungGrey,
    position: 'absolute',
  },
  progressLinear: {
    height: VARIANT_LINEAR_SIZE,
    width: '100%',
  },
  typographyLabel: {
    color: 'inherit',
    fontWeight: 600,
    letterSpacing: 0,
    marginTop: '1px',
    width: DEFAULT_SIZE,
  },
};

const Progress = (props) => {
  const { forms = [], variant } = props;

  const hasDispatches = variant !== 'linear' && getDispatchesWithDefectStatus(forms).length > 0;
  const { progress, total } = getProgress(forms);

  const normalizedValue = total === 0 ? 0 : ((progress - 0) * TOTAL_VALUE) / (total - 0);

  if (total < 1) return null;

  const label = total;
  const isSuccess = normalizedValue === TOTAL_VALUE;
  const isWarning = normalizedValue >= 0 && normalizedValue < TOTAL_VALUE;

  let Component = null;

  if (variant === 'icon') {
    Component = (
      <Box
        sx={{
          ...styles.iconComponent,
          ...(isSuccess && styles.iconSuccess),
          ...(isWarning && styles.iconWarning),
        }}
      />
    );
  }

  if (variant === 'circular') {
    const circularProps = {
      size: DEFAULT_SIZE,
      thickness: VARIANT_CIRCULAR_THICKNESS,
      variant: 'determinate',
    };

    Component = (
      <>
        <CircularProgress sx={styles.progressCircular} value={TOTAL_VALUE} {...circularProps} />
        <CircularProgress
          sx={{
            ...styles.progressCircular,
            '& .MuiCircularProgress-circle': {
              color: isSuccess ? 'success.main' : 'warning.main',
            },
          }}
          value={normalizedValue}
          {...circularProps}
        />
        <Typography sx={styles.typographyLabel} variant="caption">
          {label}
        </Typography>
      </>
    );
  }

  if (variant === 'linear') {
    Component = (
      <LinearProgress
        sx={{
          ...styles.bar,
          '& .MuiLinearProgress-bar': {
            backgroundColor: isSuccess ? 'success.main' : 'warning.main',
          },
        }}
        value={normalizedValue}
        variant="determinate"
      />
    );
  }

  return (
    <Box sx={{ ...styles.boxRoot, ...(variant === 'circular' && styles.boxRootCircular) }}>
      {Component}
      {hasDispatches && (
        <SvgIcon
          color="primary"
          sx={{
            ...styles.avatar,
            ...(variant === 'circular' && styles.avatarCircular),
            ...(variant === 'icon' && styles.avatarIcon),
          }}
        >
          <DispatchIcon />
        </SvgIcon>
      )}
    </Box>
  );
};

Progress.propTypes = {
  forms: PropTypes.array,
  variant: PropTypes.oneOf(['circular', 'icon', 'linear']).isRequired,
};

export default Progress;
