const convertImage = async (canvas, options) => {
  const opts = {
    compression: 0.8,
    ...options,
  };

  return new Promise((resolve, reject) => {
    canvas.toBlob(
      blob => {
        resolve(blob);
      },
      opts.format,
      opts.compression
    );
  });
};

export default convertImage;
