import React from 'react';
import { styled } from '@mui/material/styles';
import SvgIcon from '@mui/material/SvgIcon';
import { keyframes } from '@emotion/react';

const spin = keyframes`
  to {
    transform: rotate(-360deg);
  }
`;

const StyledDot = styled((props) => (
  <path
    {...props}
    d="m 167.46627,257.64807 c 0,-48.34335 38.9839,-87.34764 87.30207,-86.79828 48.31811,0 86.75295,39.55364 86.75295,87.34763 0,47.794 -39.53298,86.79829 -87.30208,86.79829 -48.31811,0 -86.75294,-39.00429 -86.75294,-87.34764 z"
  />
))(({ theme }) => ({
  fill: theme.palette.mode === 'light' ? theme.palette.primary[300] : theme.palette.primary[700],
}));

const StyledCircleInner = styled((props) => (
  <path
    {...props}
    d="m 94.43995,259.84549 c 0,-80.75536 59.29957,-149.42489 137.81651,-160.412014 5.49067,-0.549356 10.9814,-1.098712 16.47208,-1.648068 4.39254,-0.549356 6.58886,0.549356 6.0398,5.493562 -0.54913,7.69099 -0.54913,15.38197 0,23.07296 0,4.39485 -1.64737,5.49356 -5.49073,5.49356 -36.78764,1.64807 -66.98647,16.48069 -90.59648,44.49786 -31.29696,37.35622 -38.98396,80.20601 -21.96281,125.80257 17.0212,45.59657 51.0635,73.06438 99.38161,80.20601 70.28092,10.43777 133.42391,-37.35622 143.30718,-107.67382 0.54913,-4.9442 1.09819,-9.88841 1.09819,-14.28326 0,-3.29614 1.09825,-4.39485 4.39254,-4.39485 7.687,0 15.92307,0 23.61002,-0.54941 4.94161,-0.54941 5.49067,1.64796 5.49067,6.04292 -0.54906,31.86266 -9.88321,61.52789 -28.0025,87.89699 -36.23862,53.28756 -102.12695,79.65666 -164.17186,66.47211 C 156.48491,401.57934 107.61768,351.58793 96.087263,286.76389 94.989127,276.87548 94.440005,267.53643 94.440005,259.84544 Z"
  />
))(({ theme }) => ({
  animation: `${spin} 4s linear infinite`,
  WebkitAnimation: `${spin} 4s linear infinite`,
  fill: theme.palette.mode === 'light' ? theme.palette.primary[200] : theme.palette.primary[400],
  transformOrigin: 'center',
}));

const StyledCircleOuter = styled((props) => (
  <path
    {...props}
    d="m 255.86647,511.45064 c 0,-15.93133 0.54912,-31.86266 0,-47.79399 0,-4.9442 1.09819,-6.04292 6.03975,-6.59227 78.51693,-4.39485 137.81645,-41.20172 174.05507,-110.4206 C 498.00615,227.43348 425.52895,81.304721 292.10504,57.133047 191.07623,38.454936 91.145545,99.982833 62.593922,198.86695 57.652314,215.897 54.906976,232.92704 54.357908,250.50644 54.357908,254.90129 52.710596,256 48.867178,256 32.395096,256 16.472082,256 0,256.54936 0,249.95708 0,243.36481 0,236.77253 1.0982449,236.22318 1.0982449,235.12435 1.0982449,234.02575 2.196435,222.48927 3.8435827,211.50215 6.039853,200.51502 26.355463,103.27897 109.26499,22.523605 206.99935,4.944206 216.33355,3.2961373 226.21682,3.2961373 235.55097,0 c 13.17768,0 26.35535,0 39.53303,0 9.3342,3.2961373 19.76649,2.7467811 29.64976,4.944206 74.67346,15.381974 131.77671,56.034335 171.30974,120.858374 29.10069,47.79399 40.08204,99.98283 34.59136,154.91845 -10.9814,114.26609 -97.18534,207.65665 -210.29364,227.43348 -8.78514,1.64812 -18.11934,1.64812 -26.90442,3.84549 -4.94166,-0.54941 -11.53048,-0.54941 -17.57022,-0.54941 z"
  />
))(({ theme }) => ({
  animation: `${spin} 8s linear infinite`,
  WebkitAnimation: `${spin} 8s linear infinite`,
  fill: theme.palette.mode === 'light' ? theme.palette.primary[100] : theme.palette.primary[200],
  transformOrigin: 'center',
}));

const GeonamCircleIcon = () => {
  return (
    <SvgIcon
      color="primary"
      sx={{
        height: 48,
        width: 'auto',
      }}
      viewBox="0 0 512 512"
    >
      <StyledDot />
      <StyledCircleInner />
      <StyledCircleOuter />
    </SvgIcon>
  );
};

GeonamCircleIcon.propTypes = {};

export default GeonamCircleIcon;
