/**
 * A hook to save a state value to the session storage.
 *
 */

import { useCallback, useEffect, useRef, useState } from 'react';
import deepEqual from 'deep-equal';

const PREFERENCES_PREFIX = 'Preferences.';

const getSessionItem = key => {
  try {
    return JSON.parse(window.sessionStorage.getItem(key) ?? 'null') ?? undefined;
  } catch {
    return undefined;
  }
};

const setSessionItem = (key, value) => {
  try {
    return window.sessionStorage.setItem(key, JSON.stringify(value));
  } catch {
    /* NOOP */
  }
};

const useStickySessionState = (key, defaultValue) => {
  const prefixedKey = PREFERENCES_PREFIX + key;
  const [value, setValue] = useState(() => {
    const stickyValue = getSessionItem(prefixedKey);

    return stickyValue !== undefined ? stickyValue : defaultValue;
  });
  const previousValue = useRef();

  /**
   * EFFECTS
   */

  useEffect(() => {
    if (!deepEqual(previousValue.current, value)) {
      setSessionItem(prefixedKey, value);
      window.dispatchEvent(new Event(prefixedKey));
      previousValue.current = value;
    }
  }, [prefixedKey, value]);

  const handleValueChange = useCallback(
    event => {
      const currentValue = getSessionItem(prefixedKey);
      if (!deepEqual(previousValue.current, currentValue)) {
        setValue(currentValue);
        previousValue.current = currentValue;
      }
    },
    [prefixedKey]
  );

  useEffect(() => {
    window.addEventListener(prefixedKey, handleValueChange);

    return () => {
      window.removeEventListener(prefixedKey, handleValueChange);
    };
  }, [handleValueChange, prefixedKey]);

  return [value, setValue];
};

export default useStickySessionState;
