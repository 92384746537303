/**
 * Get the style for document icon
 * @param {string} url - WebDavUrl
 * @param {object} theme - MaterialUI theme
 * @returns {object}
 */

const getDocumentIconStyle = (url, theme = {}) => {
  const { palette } = theme;
  let backgroundColor = palette?.primary?.main;

  if (url) {
    switch (true) {
      case url.startsWith('ms-excel'):
        backgroundColor = palette?.office?.['excel'];
        break;
      case url.startsWith('ms-powerpoint'):
        backgroundColor = palette?.office?.['powerPoint'];
        break;
      case url.startsWith('ms-word'):
        backgroundColor = palette?.office?.['word'];
        break;
      default:
        backgroundColor = palette?.office?.['office'];
    }
  }

  return backgroundColor
    ? {
        backgroundColor,
        color: palette?.getContrastText(backgroundColor),
      }
    : {};
};

export default getDocumentIconStyle;
