import gql from 'graphql-tag';

const FragmentFormElementField = gql`
  fragment FormElementField on FormElementField {
    assignmentAttribTypeId
    assignmentTypeId
    description
    dataType
    displayedIfDefect
    id
    funclocAttribTypeId
    funclocEditable
    funclocTypeId
    mandatory
    name
    oldValue
    options
    seqNumber
    typename
    unit
    value
  }
`;

export default FragmentFormElementField;
