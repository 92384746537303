import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useRxDB } from 'rxdb-hooks';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import DispatchIcon from '@mui/icons-material/SpeakerNotes';
import DescriptionIcon from '@mui/icons-material/Description';
import { buffer } from 'ol/extent';
import Geolocation from 'ol/Geolocation';
import Point from 'ol/geom/Point';
import { transform } from 'ol/proj';
import { makeStyles, Trigger } from '@geomagic/core';
import { i18n } from '@geomagic/i18n';
import { ContentRoot } from '@geomagic/layout';
import { useStickySessionState } from '@geomagic/nam-react-core/utils';
import ConflictResolution from '@components/ConflictResolution';
import { getConflicts } from '@components/ConflictResolution';
import Dispatch from '@components/Dispatch';
import { DEFAULT_GEOLOCATION_SETTINGS, DEFAULT_GEOLOCATION_PROJECTION, POSITION_SETTINGS_KEY } from '@components/Map';
import Placeholder from '@components/Placeholder';
import PullToRefresh from '@components/PullToRefresh';
import pullNearbyDispatches from '@database/pullNearbyDispatches';
import showModal from '@utils/showModal';
import useLoadingSnackbar from '@utils/useLoadingSnackbar';

const useStyles = makeStyles()(({ palette, spacing }) => {
  return {
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      marginTop: spacing(0.25),
      overflow: 'hidden',
    },
    tabs: {
      borderBottom: `1px solid ${palette.divider}`,
      display: 'flex',
      alignItems: 'space-between',
    },
  };
});

const DRAFTS_TAB_VALUE = 0;
const WORKBENCH_TAB_VALUE = 1;
const NEARBY_TAB_VALUE = 2;

const geolocation = new Geolocation(DEFAULT_GEOLOCATION_SETTINGS);

const Dispatches = (props) => {
  const {
    client,
    docRelations,
    entityClasses,
    getPreviousMap,
    isLoading,
    isMobile,
    isOnline,
    LoadingComponent,
    mapProps,
    onFetchData,
    user,
  } = props;

  const [currentTabValue, setCurrentTabValue] = useStickySessionState(
    'Dispatches.currentTabValue',
    WORKBENCH_TAB_VALUE
  );
  const [positionSettings] = useStickySessionState(POSITION_SETTINGS_KEY);
  const { isTracking = false } = positionSettings || {};
  const database = useRxDB();
  const conflictRef = useRef();
  const { mapRef, srid } = mapProps;

  const enqueueLoadingSnackbar = useLoadingSnackbar();
  const { enqueueSnackbar } = useSnackbar();
  const userId = user.id;

  const { classes } = useStyles();

  /**
   *  EVENT HANDLER
   */

  const handleChange = (event, newValue) => {
    setCurrentTabValue(newValue);
  };

  const handleUpdateConflicts = () => {
    const execute = conflictRef.current?.onUpdateConflicts;

    execute && execute();
  };

  const onRefresh =
    currentTabValue === NEARBY_TAB_VALUE
      ? async () => {
          const location = geolocation.getPosition();
          if (isTracking && location) {
            const spatialFilter = getSpatialFilter(location);
            const result = await enqueueLoadingSnackbar({
              loadingText: i18n.t('dispatch.notification.pull.loading'),
              finishedText: i18n.t('dispatch.notification.pull.loadingFinished'),
              func: () => pullNearbyDispatches({ database, client, mapProps, userId, spatialFilter }),
            });

            const conflicts = getConflicts(result);

            if (conflicts.length > 0) {
              showModal({
                title: i18n.t('synchronization.label.conflicts'),
                content: (
                  <ConflictResolution
                    config={[
                      {
                        collectionName: 'dispatches',
                      },
                    ]}
                    conflictRef={conflictRef}
                    conflicts={conflicts}
                    mapProps={mapProps}
                  />
                ),
                isFullScreen: true,
                okAction: (
                  <Trigger color="inherit" onClick={handleUpdateConflicts} size="medium" variant="text">
                    {i18n.t('button.refresh')}
                  </Trigger>
                ),
              });
            }
          } else {
            enqueueSnackbar(i18n.t('dispatch.notification.noLocation'), {
              key: 'noLocation',
              preventDuplicate: true,
              variant: 'error',
            });
          }
        }
      : onFetchData;

  const getSpatialFilter = useCallback(
    (location) => {
      const map = mapRef.current.map;
      const pointExtent = new Point(
        transform(location, DEFAULT_GEOLOCATION_PROJECTION, map.getView().getProjection().getCode())
      ).getExtent();

      const bufferedExtent = new buffer(pointExtent, 2000);
      const bufferedCoordinates = [
        [
          [bufferedExtent[0], bufferedExtent[1]],
          [bufferedExtent[0], bufferedExtent[3]],
          [bufferedExtent[2], bufferedExtent[3]],
          [bufferedExtent[2], bufferedExtent[1]],
          [bufferedExtent[0], bufferedExtent[1]],
        ],
      ];
      const geometries = [{ coordinates: bufferedCoordinates, type: 'Polygon', srid }];

      return {
        extraFilters: [
          {
            discriminator: 'spatial',
            spatialFilter: {
              geometries,
            },
          },
        ],
      };
    },
    [mapRef, srid]
  );

  /**
   *  EFFECTS
   */

  useEffect(() => {
    if (isTracking) {
      geolocation.setTracking(true);
    } else {
      geolocation.setTracking(false);
    }
  }, [isTracking]);

  return (
    <div className={classes.root}>
      <Tabs
        className={classes.tabs}
        indicatorColor="primary"
        onChange={handleChange}
        textColor="primary"
        value={currentTabValue}
        variant="fullWidth"
      >
        <Tab label={i18n.t('dispatch.label.drafts')} value={DRAFTS_TAB_VALUE} />
        <Tab label={i18n.t('dispatch.label.workbench')} value={WORKBENCH_TAB_VALUE} />
        <Tab label={i18n.t('dispatch.label.nearby')} value={NEARBY_TAB_VALUE} />
      </Tabs>
      <ContentRoot className={classes.root} scrollable={false} withPadding={false}>
        <PullToRefresh
          isPullable={!!(!isLoading && isOnline && currentTabValue !== DRAFTS_TAB_VALUE)}
          LoadingComponent={LoadingComponent}
          onRefresh={onRefresh}
        >
          {currentTabValue === DRAFTS_TAB_VALUE && (
            <Dispatch
              areMapActionsDisabled={false}
              client={client}
              docRelations={docRelations}
              entityClasses={entityClasses}
              getPreviousMap={getPreviousMap}
              isDocumentsHidden={false}
              isGrouped={false}
              isLoading={isLoading}
              isMobile={isMobile}
              isOnline={isOnline}
              listPlaceholderComponent={
                <Placeholder
                  icon={<DescriptionIcon />}
                  title={i18n.t('dispatch.placeholder.noDrafts.title')}
                  subtitle={i18n.t('dispatch.placeholder.noDrafts.subtitle')}
                />
              }
              mapProps={mapProps}
              queryProps={{
                draft: {
                  $ne: null,
                },
              }}
              user={user}
            />
          )}
          {currentTabValue === WORKBENCH_TAB_VALUE && (
            <Dispatch
              areMapActionsDisabled={false}
              client={client}
              docRelations={docRelations}
              entityClasses={entityClasses}
              getPreviousMap={getPreviousMap}
              isDocumentsHidden={false}
              isGrouped={false}
              isLoading={isLoading}
              isMobile={isMobile}
              isOnline={isOnline}
              isReadOnly={true}
              mapProps={mapProps}
              queryProps={{
                draft: {
                  $eq: null,
                },
                isWorkbench: {
                  $eq: true,
                },
              }}
              user={user}
            />
          )}
          {currentTabValue === NEARBY_TAB_VALUE && (
            <Dispatch
              areMapActionsDisabled={false}
              client={client}
              collectionName={'dispatches'}
              docRelations={docRelations}
              entityClasses={entityClasses}
              getPreviousMap={getPreviousMap}
              isDocumentsHidden={true}
              isGrouped={false}
              isLoading={isLoading}
              isMobile={isMobile}
              isOnline={isOnline}
              isReadOnly={true}
              listPlaceholderComponent={
                <Placeholder
                  icon={<DispatchIcon />}
                  title={i18n.t('dispatch.placeholder.noDispatches.title')}
                  subtitle={
                    isTracking
                      ? i18n.t('dispatch.placeholder.noDispatchesNearby.subtitle')
                      : i18n.t('dispatch.placeholder.positionNotActive.subtitle')
                  }
                />
              }
              mapProps={mapProps}
              queryProps={{
                isNearby: {
                  $eq: true,
                },
                isWorkbench: {
                  $eq: false,
                },
              }}
              user={user}
            />
          )}
        </PullToRefresh>
      </ContentRoot>
    </div>
  );
};

Dispatches.propTypes = {
  client: PropTypes.object.isRequired,
  docRelations: PropTypes.array,
  entityClasses: PropTypes.array.isRequired,
  getPreviousMap: PropTypes.func,
  isLoading: PropTypes.bool,
  isMobile: PropTypes.bool,
  isOnline: PropTypes.bool,
  LoadingComponent: PropTypes.func,
  mapProps: PropTypes.object.isRequired,
  onFetchData: PropTypes.func,
  user: PropTypes.object,
};

export default Dispatches;
