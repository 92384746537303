import gql from 'graphql-tag';

import FragmentEntityReference from '../fragments/FragmentEntityReference';
import FragmentFeatureCollections from '../fragments/FragmentFeatureCollections';

const MutationUpdateGeometry = gql`
  ${FragmentEntityReference}
  ${FragmentFeatureCollections}

  mutation MutationUpdateGeometry(
    $entityReference: EntityReferenceInput!
    $featureId: ID!
    $geometryUpdate: GeometryUpdateInput!
    $srid: Int
    $worldId: NID
  ) {
    updateGeometry(entityReference: $entityReference, featureId: $featureId, geometryUpdate: $geometryUpdate) {
      ...EntityReference
      ...FeatureCollections
    }
  }
`;

export default MutationUpdateGeometry;
