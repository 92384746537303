/**
 * Get a specific endpoint for navigation
 * @param {object} config - Config with endpoints for OS specific map applications
 * @param {string} platform - OS
 * @returns {string}
 */

const getUrlScheme = (config, platform) => {
  return config && config[`${platform || 'all'}`];
};

export default getUrlScheme;
