/**
 * An editable field to display a fraction in an autoform.
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import { getFloatFromFraction, getFractionFromFloat } from '../utils';
import { DEFAULT_TEXT_FIELD_PROPS } from '../../consts';

const removePrefixAndSuffix = (value, prefix, suffix) => {
  if (value) {
    value = prefix && value.indexOf(prefix) === 0 ? value.substring(prefix.length, value.length) : value;
    const suffixLastIndex = value.lastIndexOf(suffix);
    value =
      suffix && suffixLastIndex !== -1 && suffixLastIndex === value.length - suffix.length
        ? value.substring(0, suffixLastIndex)
        : value;
  }

  return value;
};

const ARROW_LEFT = 'ArrowLeft';
const ARROW_RIGHT = 'ArrowRight';
const BACKSPACE = 'Backspace';

const FractionInput = (props) => {
  const { format, isDisabled, error, isRequired, label, prefix, suffix, onChange, value } = props;
  const [formattedValue, setFormattedValue] = useState(
    typeof value === 'number' ? getFractionFromFloat(value) + suffix : ''
  );

  const valueStart = prefix?.length || 0;
  const valueEnd = (formattedValue?.length || 0) - (suffix?.length || 0);

  /**
   * EVENT HANDLER
   */

  const handleChange = (event) => {
    let newStringValue = removePrefixAndSuffix(event.currentTarget.value, prefix, suffix);
    const isNegative = newStringValue[0] === '-';
    newStringValue = newStringValue
      .replace(/[^\d/]/g, '')
      .replace('/0', '/')
      .match(/[0-9]*\/?[0-9]*/)[0];
    newStringValue = (isNegative ? '-' : '') + newStringValue;
    if (newStringValue !== removePrefixAndSuffix(formattedValue, prefix, suffix)) {
      setFormattedValue(newStringValue ? newStringValue + suffix : '');
      onChange(getFloatFromFraction(newStringValue));
    }
  };

  const onKeyDown = (event) => {
    const { key, target } = event;
    const { selectionStart } = target;
    if (key === ARROW_LEFT || key === BACKSPACE) {
      if (selectionStart - 1 < valueStart) {
        target.setSelectionRange(valueStart + 1, valueStart + 1);
      }
    } else if (key === ARROW_RIGHT) {
      if (selectionStart + 1 > valueEnd) {
        target.setSelectionRange(valueEnd - 1, valueEnd - 1);
      }
    }
  };

  const onMouseUp = (event) => {
    const { target } = event;
    const { selectionStart, selectionEnd } = target;
    target.setSelectionRange(Math.max(selectionStart, valueStart), Math.min(selectionEnd, valueEnd));
  };

  return (
    <TextField
      {...DEFAULT_TEXT_FIELD_PROPS}
      disabled={isDisabled}
      error={error}
      format={format}
      fullWidth
      label={label}
      onKeyDown={onKeyDown}
      onMouseUp={onMouseUp}
      onChange={handleChange}
      required={isRequired}
      value={formattedValue}
    />
  );
};

FractionInput.propTypes = {
  format: PropTypes.string,
  isDisabled: PropTypes.bool,
  error: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default FractionInput;
