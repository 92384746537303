import gql from 'graphql-tag';

import FragmentProcessInstance from '../fragments/FragmentProcessInstance';

const MutationDisclaimTask = gql`
  ${FragmentProcessInstance}

  mutation MutationDisclaimTask($taskId: ID!, $withHistory: Boolean = false) {
    disclaimTask(taskId: $taskId) {
      ...ProcessInstance
    }
  }
`;

export default MutationDisclaimTask;
