/**
 * A form to create/edit attributes of a new funcloc.
 */

import React, { useImperativeHandle, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import deepEqual from 'deep-equal';
import pointer from 'json-pointer';
import { useSnackbar } from 'notistack';
import DefaultAppBar from '@mui/material/AppBar';
import Select from '@mui/material/Select';
import Toolbar from '@mui/material/Toolbar';
import { makeStyles, Trigger } from '@geomagic/core';
import { i18n } from '@geomagic/i18n';
import { ContentRoot } from '@geomagic/layout';
import { EntityForm } from '@geomagic/nam-react-core/components/Entity';
import { getAttributeValueByCode } from '@geomagic/nam-react-core/utils';
import { DEFAULT_TEXT_FIELD_PROPS, PRIMARY_TRIGGER_PROPS } from '@consts';
import getEntityTemplate from '@database/getEntityTemplate';
import { CLASSNAME_FUNCLOC } from '@graphql/consts';
import getFormFromTemplate from './getFormFromTemplate';

const useStyles = makeStyles()(({ palette, spacing }) => ({
  bottomToolbar: {
    background: palette.background.default,
    borderTop: `1px solid ${palette.divider}`,
    display: 'flex',
    justifyContent: 'flex-end',
    paddingBottom: spacing(),
    paddingTop: spacing(),
  },
}));

const FunclocForm = (props) => {
  const {
    assignmentDoc,
    entityClass,
    entityClasses,
    entityTypes,
    funclocRef,
    isMobile,
    isReadOnly,
    onChange,
    step,
    triggerProps,
    wizardData,
  } = props;

  const { parentPath, template, path } = wizardData;
  const assignment = assignmentDoc?.getPatchedEntity() || {};
  const hasFormElement = pointer.has(assignment, path);
  const formElement = hasFormElement ? pointer.get(assignment, path) : null;
  const funcloc = hasFormElement ? pointer.get(assignment, path)?.newFuncloc : null;

  const entityTypeId = funcloc?.entityType?.id || template?.funclocTypeId;
  const entityType = entityTypes.find((type) => type.id === entityTypeId);

  const formId = step?.id;

  const formContextRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const [expandedGroups, setExpandedGroups] = useState({});

  const { classes } = useStyles();

  /**
   *  EVENT HANDLER
   */

  const handleChange = (attributeValues) => {
    const newFuncloc = {
      ...getEntityTemplate(entityClass, entityTypeId),
      attributeValues,
    };

    const funclocName = getAttributeValueByCode(entityClasses, newFuncloc, 'property.name')?.formattedValue;

    const templateName = hasFormElement ? formElement.templateName : template.name;

    const name = templateName ? `${templateName} ${funclocName}` : funclocName;

    const newPatch = hasFormElement
      ? {
          addId: formElement.id,
          value: { name, newFuncloc: { ...funcloc, attributeValues } },
        }
      : {
          op: 'add',
          path: parentPath + '/-',
          value: getFormFromTemplate(template, name, newFuncloc),
        };

    return onChange(newPatch).then(() => {
      enqueueSnackbar(
        i18n.t(hasFormElement ? 'funcloc.notification.savedFuncloc' : 'funcloc.notification.createdFuncloc'),
        {
          key: 'savedValues',
          preventDuplicate: true,
          variant: 'success',
        }
      );
    });
  };

  const handleValidate = (callback) => {
    return new Promise((resolve, reject) => {
      const { submit, validate, previousValues } = formContextRef.current;
      const isValid = validate();

      if (isValid) {
        submit()
          .then((values) => {
            const areValuesEqual = deepEqual(previousValues, values);

            return areValuesEqual ? callback && callback() : handleChange(values);
          })
          .then(() => resolve());
      } else {
        reject();
      }
    });
  };

  const notifyValuesEqual = () => {
    enqueueSnackbar(i18n.t('notification.valuesEqual'), {
      key: 'valuesEqual',
      preventDuplicate: true,
      variant: 'info',
    });
  };

  /**
   *  FORM CONTEXT
   */

  useImperativeHandle(funclocRef, () => ({ onValidateForm: handleValidate }));

  return (
    <>
      <ContentRoot scrollable withCustomScrollbar={!isMobile}>
        <EntityForm
          entity={funcloc}
          entityClass={entityClass}
          entityClasses={entityClasses}
          entityClassName={CLASSNAME_FUNCLOC}
          entityTypeId={entityTypeId}
          expandedGroups={expandedGroups}
          formId={formId}
          hideReadOnlyFields
          isMobile={isMobile}
          isReadOnly={isReadOnly}
          isSubmitOnEnter={false}
          setExpandedGroups={setExpandedGroups}
        >
          {(fields, formContext) => {
            formContextRef.current = formContext;
            return (
              <>
                <Select
                  {...DEFAULT_TEXT_FIELD_PROPS}
                  autoWidth
                  disabled
                  fullWidth
                  margin="dense"
                  native
                  sx={{ mb: 2 }}
                  value={entityTypeId}
                >
                  <option key={entityType.id} value={entityType.id}>
                    {entityType.name}
                  </option>
                </Select>
                {fields}
              </>
            );
          }}
        </EntityForm>
      </ContentRoot>

      {!isReadOnly && (
        <DefaultAppBar position="static" color="inherit">
          <Toolbar className={classes.bottomToolbar}>
            <Trigger {...PRIMARY_TRIGGER_PROPS} {...triggerProps} onClick={() => handleValidate(notifyValuesEqual)}>
              {hasFormElement ? i18n.t('button.save') : i18n.t('button.create')}
            </Trigger>
          </Toolbar>
        </DefaultAppBar>
      )}
    </>
  );
};

FunclocForm.propTypes = {
  assignmentDoc: PropTypes.object.isRequired,
  entityClass: PropTypes.object.isRequired,
  entityClasses: PropTypes.array.isRequired,
  entityTypes: PropTypes.array.isRequired,
  funclocRef: PropTypes.object.isRequired,
  isMobile: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  step: PropTypes.object.isRequired,
  triggerProps: PropTypes.object,
  wizardData: PropTypes.object.isRequired,
};

export default FunclocForm;
