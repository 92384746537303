import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import register from './register';
import ServiceWorkerContext from './ServiceWorkerContext';

const ServiceWorkerProvider = ({ children }) => {
  const [waitingServiceWorker, setWaitingServiceWorker] = useState(null);
  const [isUpdateAvailable, setUpdateAvailable] = useState(false);

  useEffect(() => {
    register({
      onUpdate: registration => {
        setWaitingServiceWorker(registration.waiting);
        setUpdateAvailable(true);
      },
      onWaiting: waiting => {
        setWaitingServiceWorker(waiting);
        setUpdateAvailable(true);
      },
    });
  }, []);

  useEffect(() => {
    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', event => {
        if (event.target.state === 'activated') {
          window.location.reload();
        }
      });
    }
  }, [waitingServiceWorker]);

  const value = useMemo(
    () => ({
      isUpdateAvailable,
      updateAssets: () => {
        waitingServiceWorker && waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
      },
      reloadServiceWorker: () => {
        navigator.serviceWorker.getRegistration().then(reg => {
          if (reg) {
            reg.unregister().then(() => {
              window.location.reload();
            });
          } else {
            window.location.reload();
          }
        });
      },
    }),
    [isUpdateAvailable, waitingServiceWorker]
  );

  return <ServiceWorkerContext.Provider value={value}>{children}</ServiceWorkerContext.Provider>;
};

ServiceWorkerProvider.propTypes = {
  children: PropTypes.node,
};

export default ServiceWorkerProvider;
