import pointer from 'json-pointer';

import getDiffs from '../getDiffs';

import MutationCreateChecklistItem from '@graphql/mutations/MutationCreateChecklistItem';
import MutationUpdateChecklistItem from '@graphql/mutations/MutationUpdateChecklistItem';
import MutationDeleteChecklistItem from '@graphql/mutations/MutationDeleteChecklistItem';

const updateChecklist = async ({ client, entity, entityReference, patchedEntity, path }) => {
  const checklistItems = pointer.get(entity, path);
  const patchedChecklistItems = pointer.get(patchedEntity, path);

  const { added, removed, updated } = getDiffs(checklistItems, patchedChecklistItems);

  if (added.length > 0) {
    for (let i = 0; i < added.length; i++) {
      const { assignmentTypeCatId, checked, name } = added[i];

      const { data: createdChecklistItem } = await client.mutate({
        mutation: MutationCreateChecklistItem,
        variables: {
          item: {
            name,
          },
          owner: entityReference,
        },
      });

      if (assignmentTypeCatId || checked) {
        await client.mutate({
          mutation: MutationUpdateChecklistItem,
          variables: {
            item: {
              checked,
              id: createdChecklistItem?.createChecklistItem?.id,
              name,
              assignmentTypeCatId,
            },
          },
        });
      }
    }
  }

  if (updated.length > 0) {
    for (let i = 0; i < updated.length; i++) {
      const { checked, id, name, assignmentTypeCatId } = updated[i];

      await client.mutate({
        mutation: MutationUpdateChecklistItem,
        variables: {
          item: {
            checked,
            id,
            name,
            assignmentTypeCatId,
          },
        },
      });
    }
  }

  if (removed.length > 0) {
    for (let i = 0; i < removed.length; i++) {
      const { id } = removed[i];

      await client.mutate({
        mutation: MutationDeleteChecklistItem,
        variables: {
          itemId: id,
        },
      });
    }
  }
};

export default updateChecklist;
