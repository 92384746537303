import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import UploadIcon from '@mui/icons-material/CloudUpload';
import { makeStyles } from '@geomagic/core';
import { i18n } from '@geomagic/i18n';
import showModal from '@utils/showModal';
import DocumentListImageViewer from '@components/DocumentListImageViewer';
import DocumentUpload from '@components/DocumentUpload';
import { DEFAULT_DOCUMENT_ACCEPT_FORMATS } from '@consts';
import { FORM_COMPONENT_MIN_HEIGHT } from '../consts';

const useStyles = makeStyles()(({ breakpoints, palette, shape, spacing }) => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    margin: spacing(1, 0.5),
    minHeight: FORM_COMPONENT_MIN_HEIGHT,
    [breakpoints.down('md')]: {
      marginBottom: spacing(1),
    },
  },
  button: {
    background: palette.primary.light,
    color: palette.getContrastText(palette.primary.main),
  },
  buttonFileUpload: {
    background: palette.primary.light,
    border: 'none !important',
    borderBottomRightRadius: `${shape.borderRadius}px !important`,
    borderTopRightRadius: `${shape.borderRadius}px !important`,
    color: palette.getContrastText(palette.primary.main),
    '@media (hover: none)': {
      background: `${palette.primary.light} !important`,
      color: `${palette.getContrastText(palette.primary.main)} !important`,
    },
  },
  isMandatory: {
    border: `2px solid ${palette.warning.main}`,
    borderRadius: 4,
  },
}));

const FormElementDocuments = (props) => {
  const {
    accept = DEFAULT_DOCUMENT_ACCEPT_FORMATS,
    context,
    data,
    doc,
    documentLabel = i18n.t('assignment.button.documents'),
    parentName,
    path,
    PlaceholderIconComponent,
    placeholderSubtitle,
    placeholderTitle,
    UploadIconComponent = UploadIcon,
  } = props;
  const { entityClasses, isMobile, isReadOnly, onUpdate } = context;
  const { documents = [], mandatory } = data;
  const label = documentLabel.concat(!!documents.length ? ` (${documents.length})` : '');

  const { classes } = useStyles(props);

  const isValid = documents.length > 0;

  /**
   *  EVENT HANDLER
   */

  const handleChange = async (newDocuments) => {
    onUpdate({ documents: newDocuments }, path, data);
  };

  const handleShowDocuments = () => {
    showModal({
      title: `${documentLabel} ${parentName}`,
      content: (
        <DocumentListImageViewer
          doc={doc}
          documents={documents}
          PlaceholderIconComponent={PlaceholderIconComponent}
          placeholderSubtitle={placeholderSubtitle}
          placeholderTitle={placeholderTitle}
          onDelete={handleChange}
        />
      ),
      isFullScreen: true,
    });
  };

  return (
    <div
      className={classNames(classes.root, {
        [classes.isMandatory]: mandatory && !isValid,
      })}
    >
      <ButtonGroup color="primary" disableElevation size="medium" variant="contained">
        <Button className={classes.button} disabled={!isValid} onClick={handleShowDocuments}>
          {label}
        </Button>
        {!isReadOnly && (
          <DocumentUpload
            accept={accept}
            className={classes.buttonFileUpload}
            doc={doc}
            documents={documents}
            entityClasses={entityClasses}
            isMobile={isMobile}
            icon={<UploadIconComponent />}
            hasDocumentTypeSelection={false}
            onChange={handleChange}
          />
        )}
      </ButtonGroup>
    </div>
  );
};

FormElementDocuments.propTypes = {
  accept: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  context: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  doc: PropTypes.object.isRequired,
  documentLabel: PropTypes.string,
  path: PropTypes.string.isRequired,
  parentName: PropTypes.string,
  PlaceholderIconComponent: PropTypes.object,
  placeholderSubtitle: PropTypes.string,
  placeholderTitle: PropTypes.string,
  UploadIconComponent: PropTypes.object,
};

export default FormElementDocuments;
