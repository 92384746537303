import getDefaultFeatureStyle from '@components/Map/utils/getFeatureStyle';
import getEntityStateByProcessingStatus from '@geomagic/nam-react-core/utils/getEntityStateByProcessingStatus';

const getFeatureStyle = (primaryColor, theme, overrides = {}) => feature => {
  let backgroundColor = primaryColor || theme?.palette?.primary[200];
  const { criticalStatusMessage, processingStatus } = feature?.get('entity') || {};
  const entityState = getEntityStateByProcessingStatus({
    criticalStatusMessage,
    status: processingStatus?.[0],
    theme,
  });

  if (entityState?.style?.backgroundColor) {
    backgroundColor = entityState?.style?.backgroundColor;
  }

  const style = getDefaultFeatureStyle({ feature, backgroundColor, overrides });

  return style;
};

export default getFeatureStyle;
