import { VIEW_CODE } from '../consts';

/**
 * Get a view by code
 * @param {Array.<object>} worlds
 * @param {string|undefined} code
 * @returns {object|undefined}
 */

const getViewByCode = (views = [], code) => {
  return views.find((view) => view?.code === (code || VIEW_CODE));
};

export default getViewByCode;
