import gql from 'graphql-tag';

export const QuerySystemInformation = gql`
  query QuerySystemInformation($dark: Boolean = false) {
    systemInformation {
      version
      servicePhoneNumber
      serviceTimes
      serviceMail
      spatial {
        geometryColors(dark: $dark) {
          primary
          secondary
          selection
          marking
        }
      }
    }
  }
`;

export default QuerySystemInformation;
