import getDownloadUrl from './getDownloadUrl';
import getFileByDownloadUrl from './getFileByDownloadUrl';
import getFilePropsFromDocumentEntity from './getFilePropsFromDocumentEntity';

const fetchAndStoreAttachments = async (documents = [], databaseDoc) => {
  for (let i = 0; i < documents.length; i++) {
    const doc = documents[i];
    const { downloadPath, hash, name, type } = getFilePropsFromDocumentEntity(doc);

    const url = getDownloadUrl(downloadPath);
    const file = await getFileByDownloadUrl({ url, name, options: { type } });

    if (file && hash) {
      await databaseDoc.putAttachment({ id: hash, data: file, type });
      console.log(name, 'inserted');
    }
  }
};

export default fetchAndStoreAttachments;
