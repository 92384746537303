import { RELEVANT_DISPATCH_PATH } from '@graphql/consts';

const getDatabaseDocRelations = (assignmentDoc, block, path) => {
  const assignment = assignmentDoc.getPatchedEntity();

  const relations = [
    {
      id: assignment.id,
      name: assignment.displayName,
      path: RELEVANT_DISPATCH_PATH,
      parentId: null,
      type: assignment?.className,
    },
    {
      id: block.id,
      name: block.name,
      path: `${path}${RELEVANT_DISPATCH_PATH}`,
      parentId: assignment.id,
      type: block.typename,
    },
  ];

  return relations;
};

export default getDatabaseDocRelations;
