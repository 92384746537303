import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRxDB } from 'rxdb-hooks';
import { useRxData } from 'rxdb-hooks';
import { useParams } from 'react-router-dom';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Geolocation from 'ol/Geolocation';
import { makeStyles } from '@geomagic/core';
import { i18n } from '@geomagic/i18n';
import { useStickySessionState } from '@geomagic/nam-react-core/utils';
import Assignment from '@components/Assignment';
import { DEFAULT_GEOLOCATION_SETTINGS, POSITION_SETTINGS_KEY } from '@components/Map';

const useStyles = makeStyles()(({ palette, spacing }) => {
  return {
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      marginTop: spacing(0.25),
      overflow: 'hidden',
    },
    tabs: {
      borderBottom: `1px solid ${palette.divider}`,
      display: 'flex',
      alignItems: 'space-between',
    },
  };
});

const MY_TASKS_TAB_VALUE = 0;
const TEAM_TASKS_TAB_VALUE = 1;
const PROCESS_INSTANCES_KEY = 'entity.processInstances';
const ASSIGNMENTS_COLLECTION = 'assignments';

const geolocation = new Geolocation(DEFAULT_GEOLOCATION_SETTINGS);

const Assignments = (props) => {
  const {
    basePath,
    client,
    entityClasses,
    getPreviousMap,
    history,
    isLoading,
    isMobile,
    isOnline,
    LoadingComponent,
    mapProps,
    onCloseSwipeableArea,
    onFetchData,
    stateRef,
    user,
    vectorTileServerUrl,
  } = props;

  const [currentTabValue, setCurrentTabValue] = useStickySessionState(
    'Assignments.currentTabValue',
    MY_TASKS_TAB_VALUE
  );
  const [positionSettings] = useStickySessionState(POSITION_SETTINGS_KEY);
  const { isTracking = false } = positionSettings || {};
  const database = useRxDB();

  const queryConstructorMyAssignments = useCallback(
    (collection) => {
      return collection.find({
        selector: {
          userId: user.id,
          [PROCESS_INSTANCES_KEY]: { $elemMatch: { tasks: { $elemMatch: { assignee: user.loginName } } } },
        },
        sort: [{ modifiedOn: 'asc' }],
      });
    },
    [user]
  );

  const { result: myAssignments, isFetching: isFetchingMyAssignments } = useRxData(
    ASSIGNMENTS_COLLECTION,
    queryConstructorMyAssignments
  );

  const queryConstructorTeamAssignments = useCallback(
    (collection) => {
      return collection.find({
        selector: {
          userId: user.id,
          [PROCESS_INSTANCES_KEY]: {
            $elemMatch: {
              tasks: {
                $elemMatch: {
                  assignee: {
                    $eq: null,
                  },
                  assignedTeam: { $exists: true, $nin: [null] },
                },
              },
            },
          },
        },
        sort: [{ modifiedOn: 'asc' }],
      });
    },
    [user]
  );

  const { result: teamAssignments, isFetching: isFetchingTeamAssignments } = useRxData(
    ASSIGNMENTS_COLLECTION,
    queryConstructorTeamAssignments
  );

  const { id: paramsId } = useParams();

  const isDetail = !!paramsId;

  const { classes } = useStyles();

  /**
   *  EVENT HANDLER
   */

  const handleChange = (event, newValue) => {
    setCurrentTabValue(newValue);
  };

  const handleClaimAssignment = () => {
    setCurrentTabValue(MY_TASKS_TAB_VALUE);
  };

  /**
   *  EFFECTS
   */

  useEffect(() => {
    if (isTracking) {
      geolocation.setTracking(true);
    } else {
      geolocation.setTracking(false);
    }
  }, [isTracking]);

  /**
   *  COMPONENTS
   */

  const AssignmentComponent = (
    <>
      {currentTabValue === MY_TASKS_TAB_VALUE && (
        <Assignment
          assignments={myAssignments}
          basePath={basePath}
          client={client}
          database={database}
          entityClasses={entityClasses}
          getPreviousMap={getPreviousMap}
          history={history}
          isLoading={isLoading || isFetchingMyAssignments}
          isMobile={isMobile}
          isOnline={isOnline}
          isTeamAssignment={false}
          LoadingComponent={LoadingComponent}
          mapProps={mapProps}
          onCloseSwipeableArea={onCloseSwipeableArea}
          onFetchData={onFetchData}
          stateRef={stateRef}
          user={user}
          vectorTileServerUrl={vectorTileServerUrl}
        />
      )}
      {currentTabValue === TEAM_TASKS_TAB_VALUE && (
        <Assignment
          assignments={teamAssignments}
          basePath={basePath}
          client={client}
          database={database}
          entityClasses={entityClasses}
          getPreviousMap={getPreviousMap}
          history={history}
          isLoading={isLoading || isFetchingTeamAssignments}
          isMobile={isMobile}
          isOnline={isOnline}
          isTeamAssignment={true}
          LoadingComponent={LoadingComponent}
          mapProps={mapProps}
          onClaimAssignment={handleClaimAssignment}
          onCloseSwipeableArea={onCloseSwipeableArea}
          onFetchData={onFetchData}
          stateRef={stateRef}
          user={user}
          vectorTileServerUrl={vectorTileServerUrl}
        />
      )}
    </>
  );

  return (
    <div className={classes.root}>
      {!isDetail && (
        <Tabs
          className={classes.tabs}
          indicatorColor="primary"
          onChange={handleChange}
          textColor="primary"
          value={currentTabValue}
          variant="fullWidth"
        >
          <Tab
            label={i18n.t('assignment.label.myAssignments', {
              variables: {
                count: String(myAssignments?.length),
              },
            })}
            value={MY_TASKS_TAB_VALUE}
          />

          <Tab
            label={i18n.t('assignment.label.teamAssignments', {
              variables: {
                count: String(teamAssignments?.length),
              },
            })}
            value={TEAM_TASKS_TAB_VALUE}
          />
        </Tabs>
      )}
      {AssignmentComponent}
    </div>
  );
};

Assignments.propTypes = {
  basePath: PropTypes.string.isRequired,
  client: PropTypes.object.isRequired,
  entityClasses: PropTypes.array.isRequired,
  getPreviousMap: PropTypes.func,
  history: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  isMobile: PropTypes.bool,
  isOnline: PropTypes.bool,
  LoadingComponent: PropTypes.func,
  mapProps: PropTypes.object.isRequired,
  onCloseSwipeableArea: PropTypes.func,
  onFetchData: PropTypes.func,
  stateRef: PropTypes.object.isRequired,
  user: PropTypes.object,
  vectorTileServerUrl: PropTypes.string,
};

export default Assignments;
