import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { ContentRoot } from '@geomagic/layout';

const ListboxComponent = forwardRef((props, ref) => {
  const { children, ListBoxTranstition, ...rest } = props;

  return (
    <ContentRoot withPadding={false} withCustomScrollbar scrollbarProps={{ containerRef: ref, ...rest }}>
      {ListBoxTranstition ? React.cloneElement(ListBoxTranstition, { children: <div>{children}</div> }) : children}
    </ContentRoot>
  );
});

ListboxComponent.propTypes = {
  children: PropTypes.node.isRequired,
  ListBoxTranstition: PropTypes.node,
};

export default ListboxComponent;
