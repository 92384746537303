import React from 'react';
import PropTypes from 'prop-types';
import ListItemText from '@mui/material/ListItemText';
import getPrimaryText from './getPrimaryText';
import getSecondaryText from './getSecondaryText';

const LocationRecordListText = (props) => {
  const { data } = props;

  const listItemTypographyProps = {
    noWrap: true,
  };

  return (
    <ListItemText
      primary={getPrimaryText(data)}
      primaryTypographyProps={{ ...listItemTypographyProps, variant: 'body1' }}
      secondary={getSecondaryText(data)}
      secondaryTypographyProps={listItemTypographyProps}
    />
  );
};

LocationRecordListText.propTypes = {
  data: PropTypes.object.isRequired,
};

export default LocationRecordListText;
