import dayjs from 'dayjs';

import { DATE_DISPLAY_FORMAT } from '@consts';
import { i18n } from '@geomagic/i18n';
import { TIMESTAMP_DISPLAY_FORMAT } from '@geomagic/geonam';

import getFractionFromFloat from './getFractionFromFloat';
import getNumberFormatObject from './getNumberFormatObject';
import getRawAttributeValue from './getRawAttributeValue';
import getTypedAttributeValue from './getTypedAttributeValue';

const convertDateFormat = (format) => format.replace(/d/g, 'D').replace(/y/g, 'Y');

/**
 * Get a readable string from an attribute value.
 * @param {object} attributeType - GeoNAM attribute type
 * @param {string} value
 * @returns {string}
 */
const getStringifiedAttributeValue = (attributeType, value) => {
  let formatObject, locale;
  const { allowedValues, options, unit } = attributeType;
  const getValueString = () => {
    switch (attributeType.dataType) {
      case 'BOOLEAN':
        const { trueLabel, falseLabel } = options;
        return value === 'true' || (typeof value === 'boolean' && value === true)
          ? trueLabel
            ? trueLabel
            : i18n.t('default.yes')
          : value === 'false' || (typeof value === 'boolean' && value === false)
          ? falseLabel
            ? falseLabel
            : i18n.t('default.no')
          : '';
      case 'FRACTION':
        return value ? getFractionFromFloat(value) + (unit ? ' ' + unit : '') : null;
      case 'INT':
      case 'FLOAT':
      case 'CURRENCY':
        formatObject = getNumberFormatObject(attributeType);
        locale = dayjs.locale();
        return typeof value !== 'undefined'
          ? formatObject.format
            ? formatObject.format(value)
            : Number(value).toLocaleString() + (unit ? ' ' + unit : '')
          : null;
      case 'DATE':
        locale = dayjs.locale();
        formatObject =
          options && options.pattern ? convertDateFormat(options.pattern) : locale ? 'L' : DATE_DISPLAY_FORMAT;
        return value ? dayjs(value).format(formatObject) : null;
      case 'TIMESTAMP':
        locale = dayjs.locale();
        formatObject =
          options && options.pattern ? convertDateFormat(options.pattern) : locale ? 'LLL' : TIMESTAMP_DISPLAY_FORMAT;
        return value ? dayjs(value).format(formatObject) : null;
      case 'LINK':
      case 'EMAIL':
      case 'STRING':
        return getRawAttributeValue(attributeType, value);
      default:
        return getRawAttributeValue(attributeType, value);
    }
  };

  const isEnumeration = allowedValues && !!allowedValues.length;
  if (isEnumeration && typeof value !== 'undefined') {
    const selectedAllowedValue = allowedValues.find(
      (allowedValue) =>
        getTypedAttributeValue(attributeType, allowedValue.value) === getTypedAttributeValue(attributeType, value)
    );
    if (selectedAllowedValue && selectedAllowedValue.name) {
      return selectedAllowedValue.name;
    }
  }
  return getValueString();
};

export default getStringifiedAttributeValue;
