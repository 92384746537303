/**
 * Preconfigured full stack to start from, put in your components in as children (e.g. Routes, Query/Mutation if graphql, StateConsumer and all of @material-ui)
 */

import React from 'react';
import PropTypes from 'prop-types';

import { Core } from '@geomagic/core';
import { Forms } from '@geomagic/forms';
import { GraphQL } from '@geomagic/graphql';

const Geonam = ({ children, getGraphQLClient, defaultEnvironment, onGraphQLError, ...passThroughProps }) => {
  const content = getGraphQLClient ? (
    <GraphQL defaultEnvironment={defaultEnvironment} getClient={getGraphQLClient} onError={onGraphQLError}>
      {children}
    </GraphQL>
  ) : (
    <React.Fragment>{children}</React.Fragment>
  );

  return (
    <Core defaultEnvironment={defaultEnvironment} {...passThroughProps}>
      {(coreContent) => {
        return (
          <Forms>
            {coreContent}
            {content}
          </Forms>
        );
      }}
    </Core>
  );
};

Geonam.propTypes = {
  /**
   * wrap your app components to leverage theming, routing, graphql, i18n, global state, ...
   */
  children: PropTypes.node,
  /**
   * will be called with a errorHandler({ graphQLErrors, networkError }) and to returns an ApolloClient
   */
  getGraphQLClient: PropTypes.func,
  /**
   * will be called when a error in graphql communication occurs
   */
  onGraphQLError: PropTypes.func,
  /**
   * your custom material-ui theme, if none is provided we create a default theme for you
   */
  theme: PropTypes.object,
  /**
   * used for global state initializing
   */
  defaultGlobalState: PropTypes.object,
  /**
   * used for global state overwriting, e.g. make component props globalState
   */
  globalStateOverwrite: PropTypes.object,
  /**
   * define some defaults for variables used for connection setup etc. (at runtime they may be overwritten by providing as process.env[VAR_NAME]) currently available: BACKEND_SERVER, GRAPHQL_WEBSOCKET_PATH, GRAPHQL_PATH, BASE_PATH, APP_TITLE, DEVELOPMENT_SERVER_PORT
   */
  defaultEnvironment: PropTypes.object,
  /**
   * removes the react-router-dom to allow testing with e.g. a MemoryRouter in environments without a browser
   */
  isTesting: PropTypes.bool,
  /**
   * more logging like state changes and graphql operations
   */
  isDebug: PropTypes.bool,
  /**
   * all global state is saved to localStorage, useful for serverless development (closely watch the size of your data, maybe a performance hit)
   */
  isSaveState: PropTypes.bool,
  /**
   * custom key under which the state is saved if isSaveState (defaults to GlobalState)
   */
  stateKey: PropTypes.string,
  /**
   * limit the part of your state which is saved if isSaveState (array of toplevel keys in state object)
   */
  keysToSave: PropTypes.array,
};

export default Geonam;
