import gql from 'graphql-tag';

const FragmentJournal = gql`
  fragment Journal on Entity {
    journal {
      id
      type
      priority
      source
      businessObject {
        className
        id
        name
      }
      date
      order
      user {
        fullName
        id
      }
      action
      message
    }
  }
`;

export default FragmentJournal;
