import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SingleSelect from '@geomagic/nam-react-core/components/SingleSelect';
import { MINIMUM_CONFIG, getLayerStoreInstance } from '@geomagic/map';
import { setBackground } from '@geomagic/map/src/utils';

const SelectBackgroundLayer = (props) => {
  const { layers = [], map } = props;
  const layerSelectionStore = getLayerStoreInstance(MINIMUM_CONFIG.mapId);
  const backgroundLayerOptions = layers.filter((layer) => layer.isBackground);

  const [selectedBackgroundLayer, setSelectedBackgroundLayer] = useState(
    layerSelectionStore.getLayerSelection().backgroundLayerId
  );

  const backgroundLayerValue = backgroundLayerOptions.find(({ name }) => name === selectedBackgroundLayer);

  /**
   * EVENT HANDLER
   */

  const handleChange = (_, newValue) => {
    const layerId = newValue.name;
    if (map && layerId) {
      setBackground(layerId, map, true);
      layerSelectionStore.saveLayerSelection({ backgroundLayerId: layerId });
      setSelectedBackgroundLayer(layerId);
    }
  };

  /**
   * COMPONENT
   */

  return (
    <SingleSelect
      isAutocomplete={false}
      getOptionLabel={(layer) => layer.name}
      onChange={handleChange}
      options={backgroundLayerOptions}
      value={backgroundLayerValue}
    />
  );
};

SelectBackgroundLayer.propTypes = {
  layers: PropTypes.array.isRequired,
  map: PropTypes.object,
};

export default SelectBackgroundLayer;
