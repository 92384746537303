/**
 * A form to create and update an entity.
 */

import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import isFunction from 'lodash/isFunction';
import Grid from '@mui/material/Grid';

import { AutoForm } from '@geomagic/forms';
import { compareByReference, getAttributeTypesByClassAndType, getReference } from '@geomagic/geonam';

import DataGroupItem from '../DataGroupItem/DataGroupItem';
import useResizeObserver from '@utils/useResizeObserver';
import {
  formValidationHandler,
  getDataGroupsFromFields,
  getRawAttributeValue,
  getTypedAttributeValue,
} from '../../utils';
import filterAttributeType from './utils/filterAttributeType';
import createFormSchemaAndUI from './utils/createFormSchemaAndUI';

const ADDITIONAL_WIDTH = 40;

const getAttributeValues = (attributeTypes, values) =>
  attributeTypes.map((attributeType, index) => ({
    attributeType: getReference(attributeType),
    value: getRawAttributeValue(attributeType, values[String(index)]),
  }));

const getDefaultValuesFromEntity = (attributeTypes, entity) =>
  attributeTypes.reduce((prev, attributeType, index) => {
    const attributeValue = entity?.attributeValues?.find((aV) => compareByReference(attributeType, aV.attributeType));
    const typedValue = getTypedAttributeValue(attributeType, attributeValue?.value);

    if (typedValue !== null && typedValue !== undefined) {
      prev[index] = typedValue;
    }

    return prev;
  }, {});

const getDefaultValuesFromPrefilled = (attributeTypes, prefilledAttributeValues, isIgnoreAttributeTypeDefaultValues) =>
  attributeTypes.reduce((prev, attributeType, index) => {
    const presetDefault = prefilledAttributeValues.find((pAV) => pAV.attributeTypeId === attributeType.id);
    const defaultValue =
      (presetDefault && presetDefault.value) ||
      (isIgnoreAttributeTypeDefaultValues ? undefined : attributeType.defaultValue);
    const typedValue = getTypedAttributeValue(attributeType, defaultValue);

    if (typedValue !== null && typedValue !== undefined) {
      prev[index] = typedValue;
    }

    return prev;
  }, {});

const EntityForm = (props) => {
  const {
    children,
    defaultValues: initialValues,
    entity,
    entityClass,
    entityClasses,
    entityClassName,
    entityTypeCode,
    entityTypeId,
    expandedGroups = {},
    formId,
    hideReadOnlyFields = false,
    hiddenAttributeTypeIds = [],
    isReadOnly,
    isRequiredFieldsOnly,
    isMobile,
    onCancel,
    prefilledAttributeValues = [],
    setExpandedGroups = () => {},
    ...autoFormProps
  } = props;
  const entityId = entity?.id;

  const [ref, { width }] = useResizeObserver();

  const entityType = entityClass.entityTypes.find(
    ({ id, code }) => (entityTypeCode && code === entityTypeCode) || (entityTypeId && id === entityTypeId)
  );

  if (!entityType) {
    throw new Error('noEntityType');
  }

  const [attributeValues, setAttributeValues] = useState(entity ? entity.attributeValues : prefilledAttributeValues);
  const attributeTypes = useMemo(() => {
    return getAttributeTypesByClassAndType(entityClasses, entityClassName, entityTypeId);
  }, [entityClassName, entityClasses, entityTypeId]);

  const filteredAttributeTypes = useMemo(() => {
    return attributeTypes.filter((attributeType) =>
      filterAttributeType(attributeType, { attributeTypes, attributeValues, entityTypeId })
    );
  }, [attributeTypes, attributeValues, entityTypeId]);

  const [schemaUI, setSchemaUI] = useState(() =>
    createFormSchemaAndUI(filteredAttributeTypes, entity, attributeValues, hideReadOnlyFields, isReadOnly, isMobile)
  );
  const { schema, ui } = schemaUI;

  const defaultValues = entity
    ? getDefaultValuesFromEntity(filteredAttributeTypes, entity)
    : getDefaultValuesFromPrefilled(filteredAttributeTypes, prefilledAttributeValues, false);

  const isCustomChildren = isFunction(children);

  /**
   *  EFFECTS
   */
  useEffect(() => {
    setSchemaUI(
      createFormSchemaAndUI(filteredAttributeTypes, entity, attributeValues, hideReadOnlyFields, isReadOnly, isMobile)
    );
  }, [attributeValues, entityTypeId, entity, filteredAttributeTypes, hideReadOnlyFields, isReadOnly, isMobile]);

  /**
   *  EVENT HANDLER
   */

  const updateAttributeValues = (newAttributeValues) => {
    if (!isEqual(attributeValues, newAttributeValues)) {
      setAttributeValues(newAttributeValues);
    }
  };

  const handleChange = (values, formContext) => {
    const newAttributeValues = getAttributeValues(filteredAttributeTypes, values);
    updateAttributeValues(newAttributeValues);
    autoFormProps.onChange && autoFormProps.onChange(newAttributeValues, formContext);
  };

  const handleSubmit = (values, formContext) => {
    const newAttributeValues = getAttributeValues(filteredAttributeTypes, values);
    updateAttributeValues(newAttributeValues);
    autoFormProps.onSubmit && autoFormProps.onSubmit(newAttributeValues, formContext);
  };

  return (
    <div ref={ref}>
      <AutoForm
        id={formId ? formId : 'entityForm_' + entityId}
        schema={schema}
        ui={ui}
        defaultValues={defaultValues}
        onError={formValidationHandler}
        {...autoFormProps}
        onSubmit={handleSubmit}
        onChange={handleChange}
      >
        {(fields, formContext) => {
          const attributeFieldsSorted = [];

          filteredAttributeTypes.forEach((attributeType) => {
            const fieldComponent = fields.find((field) => field?.props?.fieldUI?.identifier === attributeType.id);

            if (fieldComponent) {
              if (isRequiredFieldsOnly) {
                if (fieldComponent.props?.definition?._isRequired) {
                  attributeFieldsSorted.push(fieldComponent);
                }
              } else {
                attributeFieldsSorted.push(fieldComponent);
              }
            }
          });

          const dataGroups =
            expandedGroups && setExpandedGroups
              ? getDataGroupsFromFields(attributeFieldsSorted)
              : attributeFieldsSorted;

          const extendedFields = (
            <Grid container>
              {dataGroups.map((dataGroup) => (
                <DataGroupItem
                  dataGroup={dataGroup}
                  depth={0}
                  elements={fields}
                  expandedGroups={expandedGroups}
                  getId={(element) => element.key}
                  hiddenAttributeTypeIds={hiddenAttributeTypeIds}
                  key={dataGroup.key}
                  setExpandedGroups={setExpandedGroups}
                  width={width + ADDITIONAL_WIDTH}
                />
              ))}
            </Grid>
          );

          return isCustomChildren
            ? children(
                extendedFields,
                {
                  ...formContext,
                  submit: async () => {
                    const values = await formContext.submit();
                    return getAttributeValues(filteredAttributeTypes, values);
                  },
                  attributeTypes: filteredAttributeTypes,
                  previousValues: getAttributeValues(filteredAttributeTypes, defaultValues),
                },
                fields
              )
            : extendedFields;
        }}
      </AutoForm>
    </div>
  );
};

EntityForm.propTypes = {
  attributeFilter: PropTypes.array,
  children: PropTypes.func,
  defaultValues: PropTypes.array,
  entityClass: PropTypes.object.isRequired,
  entityClasses: PropTypes.array.isRequired,
  entityClassName: PropTypes.string.isRequired,
  entityTypeCode: PropTypes.string,
  entityTypeId: PropTypes.number.isRequired,
  entity: PropTypes.object,
  expandedGroups: PropTypes.object,
  formId: PropTypes.string,
  getFieldAction: PropTypes.func,
  hiddenAttributeTypeIds: PropTypes.array,
  hideReadOnlyFields: PropTypes.bool,
  isMobile: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  isIgnoreAttributeTypeDefaultValues: PropTypes.bool,
  isRequiredFieldsOnly: PropTypes.bool,
  onCancel: PropTypes.func,
  prefilledAttributeValues: PropTypes.array,
  relations: PropTypes.array,
  setExpandedGroups: PropTypes.func,
};

export default EntityForm;
