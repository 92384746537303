import { CLASSNAME_ASSIGNMENT } from '@graphql/consts';
import getEntityFilter from '@graphql/getEntityFilter';
import QueryAssignments from '@graphql/queries/QueryAssignments';

const getQueryConfig = mapVariables => {
  return {
    query: QueryAssignments,
    variables: { filter: getEntityFilter([CLASSNAME_ASSIGNMENT], true, true), ...mapVariables },
  };
};

export default getQueryConfig;
