import gql from 'graphql-tag';

import FragmentAttributeType from './FragmentAttributeType';

export const FragmentEntityType = gql`
  ${FragmentAttributeType}

  fragment EntityType on EntityType {
    attributeTypes {
      ...AttributeType
    }
    childTypes {
      id
      className
    }
    className
    code
    creatable
    deprecated
    documentTypes {
      id
      className
    }
    id
    geometryTypes {
      id
      className
      name
      description
      minimumScale
      maximumScale
      geometryStyles {
        id
        className
        name
        description
        type
        searchable
        editable
      }
    }
    name
    namePlural
    searchable
    shortName
  }
`;

export default FragmentEntityType;
